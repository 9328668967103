import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getDashboardData } from '../../store/Dashboard/actionCreator';
import HorizontalChartLoader from './../Common/SkeletonLoaders/horizontalChartLoader';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
// import Chart from 'chart.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    float: 'left',
    marginTop: 10,
    width: '100%'
  },
  horizontalChartWrapper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    float: 'left',
    height: '340px',
    width: '100%'
  },
  horizontalChartWrapperMob: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    float: 'left',
    height: '100%',
    width: '98%',
  },
  horizontalChart: {
    position: 'relative',
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '-13px 0px 0px 17px'
  }
}));

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]
function CoursesByTeamChart(props) {
  const classes = useStyles();
  const [selectedRange, setSelectedRange] = useState('YTD');
  const [inProgress, setInProgress] = useState(false);
  const mobileView = useMediaQuery('(max-width:600px)');
  const [chartData, setChartData] = useState([])
  const [chartDataLength, setDataLength] = useState(0);

  const renderHorizontalChart = (coursesByTeam) => {
    const horizontalChart = document.getElementById('courseSignedupChart')
    let dataPoints = []
    let labels = []
    let inProgress = []
    let completed = []
    let approved = []
    for (let i in coursesByTeam) {
      labels.push(coursesByTeam[i].label)
      dataPoints.push(coursesByTeam[i].count)
      inProgress.push(coursesByTeam[i].inprogress_count)
      completed.push(coursesByTeam[i].completed_count)
      approved.push(coursesByTeam[i].approved_count)
    }

    let courseChartData = {
      labels: labels,
      datasets: [
        {
          label: 'Approved',
          backgroundColor: '#caf270',
          data: approved,
          maxBarThickness: 40,
          barThickness: 'flex',
        },
        {
          label: 'In Progress',
          backgroundColor: "#45c490",
          data: inProgress,
          maxBarThickness: 40,
          barThickness: 'flex',
        },
        {
          label: 'Completed',
          backgroundColor: "#008d93",
          data: completed,
          maxBarThickness: 40,
          barThickness: 'flex',
        },
      ],
    };

    const Chart = props.Chart
    new Chart(horizontalChart, {
      plugins: [{
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            chart.legend.options.labels.padding = 20;
            this.height = this.height + 10;
          };
        },
        afterDatasetDraw: function (chart, easing) {
          let height = (labels.length) * 50
          if (labels.length <= 4) {
            document.getElementById("courseSignedupChart").style.height = '250px'
          } else if (labels.length <= 8) {
            document.getElementById("courseSignedupChart").style.height = '400px'
          } else {
            document.getElementById("courseSignedupChart").style.height = `${height}px`
          }
        }
      }],
      type: 'horizontalBar',
      data: courseChartData,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            position: 'bottom',
            display: true,
            gridLines: {
              display: true
            },
            stacked: true,
            ticks: {
              beginAtZero: true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize: 11,
              userCallback: function (label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
            scaleLabel: {
              display: false
            },
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: true,
              callback: function (label) {
                if (/\s/.test(label)) {
                  var splited = []
                  splited = label.split(" ")
                  var newLable = []
                  if (splited && splited.length >= 3) {
                    newLable = label.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,1}\b/g)
                  } else {
                    newLable = label.split(" ")
                  }
                  return newLable
                } else {
                  return label;
                }
              },
            },
            stacked: true
          }]
        },
        legend: {
          position: 'top',
          display: true,
          labels: {
            boxWidth: 30,
            fontSize: 11,
            padding: 10
          }
        },
        gridLines: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'single',
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  useEffect(() => {
    if (props.coursesByTeam && props.coursesByTeam.length) {
      setChartData(props.coursesByTeam)
      setDataLength(props.coursesByTeam && props.coursesByTeam.length)
      renderHorizontalChart(props.coursesByTeam)
    }
  }, [props.coursesByTeam])

  //FOR DATE RANGE SELECTION CALLBACKS
  const successCallBack = res => {
    setInProgress(false);
    setChartData(res && res.results && res.results[0] && res.results[0].courseCountsOfTeam && res.results[0].courseCountsOfTeam);
    setDataLength(res && res.results && res.results[0] && res.results[0].courseCountsOfTeam && res.results[0].courseCountsOfTeam.length)
    renderHorizontalChart(res && res.results && res.results[0] && res.results[0].courseCountsOfTeam && res.results[0].courseCountsOfTeam);
  }
  const failureCallBack = err => {
    setChartData([]);
    setDataLength(0)
    setInProgress(false);
    props.showSnackBar({
      state: true,
      message: 'Something went wrong while fetching Course Breakup by Team data!',
      type: 'error'
    })
  }

  const handleChangeOption = (event) => {
    setInProgress(true);
    setSelectedRange(event.target.value);
    let payload = ''
    if (event.target.value && event.target.value === 'YTD') {
      payload = {
        countbox_range: event.target.value,
        value: new Date().getFullYear(),
      }
    } else {
      payload = {
        countbox_range: event.target.value,
        value: (new Date().getMonth() + 1).toString().padStart(2, "0"),
      }
    }
    getDashboardData(payload, successCallBack, failureCallBack)
  }

  return (
    <div className={classes.root}>
      <Paper className={mobileView ? classes.horizontalChartWrapperMob : classes.horizontalChartWrapper}>
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.horizontalChart}>
              <div style={{ display: 'flex' }}>
                <Typography variant="h6" style={{ width: '100%', textAlign: 'left' }}>
                  Course Breakup by Team
                </Typography>
                <div className={classes.mtdCtr}>
                  <TextField
                    className={classes.mtd}
                    id="progress"
                    select
                    classes={{ outlined: "padding:1px" }}
                    margin="dense"
                    value={selectedRange}
                    onChange={handleChangeOption}
                    name="Progress"
                    variant="outlined"
                    disabled={props.inProgress || inProgress}
                  >
                    {listOfSelection && listOfSelection.map((option) => (
                      <MenuItem style={{ padding: '1px', marginLeft: '5px' }} key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {props.inProgress || inProgress ? (
                <HorizontalChartLoader />
              ) : (
                <React.Fragment>
                  <div class="dashBoardchartWrapper">
                    <div class="dashBoardchartAreaWrapper">
                      <div class="dashBoardchartAreaWrapper2">
                        <canvas id="courseSignedupChart" aria-label="Courses Signedup By Teams Chart" role="img" height={chartDataLength ? "600" : "50"} width="200"></canvas>
                        {!chartDataLength && <p style={{ fontSize: '18px' }}>No data found!</p>}
                      </div>
                    </div>
                  </div>
                  <div id="main" tabIndex="-1" className="accessibility">
                    <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                      {chartData && chartData.length && chartData.map((row, key) => (
                        <li key={key} style={{ marginRight: 10 }}>
                          <span>{`Total ${row && row.count && row.count} Members Signedup in ${row && row.label && row.label}`}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div >
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CoursesByTeamChart);
