import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { checkUserAuth } from '../../store/Auth/actionCreator';
import { generateJwtToken, getDomainInfo, getActiveStepComponent, getUSerDetails, redirectTo } from '../../Helpers/basic';
import { find } from 'lodash';
import { getDepartmentMenus } from '../AppLayout/menuList'
import InvalidLogin from './invalidLogin'
import { fetchStepperComponents } from '../../store/UserProfile/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { getTenant } from '../../Configs/tenantConfig';
import Loader from '../Common/SkeletonLoaders/LazyLoaders/skillMapLoader';
import history from '../../history';

const useStyles = theme => ({
});
class ETAAuth extends React.Component {
  state = {
    inProgress: false,
    error: {
      status: false,
      errorMessage: ''
    },
    userMenu: ''
  }
  componentDidMount() {
    this.onLogin()
  }
  onLogin = () => {

    this.setState({
      inProgress: true,
      error: {
        status: false,
        errorMessage: ''
      }
    })
    const etaToken = this.props.match.params.etaToken
    const payload =
    {
      etaToken: etaToken.split('eta-')[1]
    }
    this.props.checkUserAuth(payload, this.successCallBack, this.failureCallBack)
  }

  //FOR FETCHING THE WELCOME PAGE DATA CALLBACKS
  componentsSuccessCB = res => {
    const { userMenu } = this.state
    let stepperList = res && res.results && res.results.component && res.results.component
    let stepsArrayList = []
    Object.entries(stepperList).forEach(([key, value]) => {
      stepsArrayList.push(value && value.key)
    });
    let filledData = res && res.results && res.results.user_data && res.results.user_data
    const defaultStep = getActiveStepComponent(stepsArrayList, filledData)
    if (defaultStep === 'allFilled' || defaultStep === 'finish') {
      //FOR CHECKING THE AUTOMATED MENUS CONFIG
      if (getTenant().showAutomatedActiveMenus) {
        //FOR CHECKING THE ANY REDIRCTION IS THERE AFTER MAIL REDIRECT LOGIN SUCCESS
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(getUSerDetails().defaultLandingPage)
        }
      } else {
        //FOR CHECKING THE ANY REDIRCTION IS THERE AFTER MAIL REDIRECT LOGIN SUCCESS
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(userMenu.defaultLandingPage)
        }
      }
    }
    else {
      history.push('/welcome')
    }
  }
  componentsFailureCB = error => {
    //FOR CECKING THE AUTOMATED MENUS CONFIG
    if (getTenant().showAutomatedActiveMenus) {
      history.push(getUSerDetails().defaultLandingPage)
    } else {
      history.push(this.state.userMenu.defaultLandingPage)
    }
  }

  //LOG IN SUCCESSCALLBACKS
  successCallBack = res => {
    const domainDet = getDomainInfo();
    this.setState({
      error: {
        status: false,
        errorMessage: 'success'
      },
      inProgress: false
    })
    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);

    //FOR SETTING UP THE USER_ACTIVE_MENUS
    if (res && res.active_menus && res.active_menus.length) {
      const encodeMenus = encodeURI(JSON.stringify(res.active_menus && res.active_menus))
      localStorage.setItem('user_active_menus', encodeMenus);
    } else {
      localStorage.setItem('user_active_menus', [])
    }

    document.cookie = domainDet.domainTokenLabel + "=" + res.token + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    const user_token = generateJwtToken(res.user)
    myDate.setMonth(myDate.getMonth() + 1);
    document.cookie = domainDet.domainUserLabel + "=" + user_token + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    const userDepartment = res.user.department
    const departmentMenus = getDepartmentMenus()
    const userMenu = find(departmentMenus, { 'departmentId': userDepartment.id })
    this.setState({
      userMenu: userMenu
    })
    if (getTenant() && getTenant().show_welcomepage) {
      const payload = {
        user_id: getUSerDetails() && getUSerDetails().id && getUSerDetails().id
      }
      if (localStorage.getItem('onLoginSuccess')) {
        const openUrl = localStorage.getItem('onLoginSuccess')
        redirectTo(localStorage.getItem('onLoginSuccess'), true)
        localStorage.removeItem('onLoginSuccess')
      }

      if (getTenant().tenant === 'caseys' && userDepartment.id === 2) {
        if (getTenant().showAutomatedActiveMenus) {
          this.props.history.push(res && res.user && res.user.defaultLandingPage)
        } else {
          this.props.history.push('/mycourses')
        }
      } else {
        fetchStepperComponents(payload, this.componentsSuccessCB, this.componentsFailureCB)
      }
    }
    else {
      if (localStorage.getItem('onLoginSuccess')) {
        const openUrl = localStorage.getItem('onLoginSuccess')
        localStorage.removeItem('onLoginSuccess')
        redirectTo(openUrl, true)
      }

      //FOR AUTOMATED MENU CONFIG CHECK
      if (getTenant().showAutomatedActiveMenus) {
        //FOR CHECKING THE ANY REDIRCTION IS THERE AFTER MAIL REDIRECT LOGIN SUCCESS
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(res && res.user && res.user.defaultLandingPage)
        }
      } else {
        //FOR CHECKING THE ANY REDIRCTION IS THERE AFTER MAIL REDIRECT LOGIN SUCCESS
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(userMenu.defaultLandingPage)
        }
      }
    }
    window.FreshworksWidget('hide')
  }

  failureCallBack = error => {
    this.setState({
      error: {
        status: true,
        errorMessage: 'Invalid credentials'
      },
      inProgress: false
    })
  }
  render() {
    const { inProgress, error } = this.state
    const message = 'You alomost done. But, few informations are yet to filled. Please contact your administrator for further details.'
    return (
      <Container component="main" maxWidth="lg">
        <div>
          {inProgress ? <Loader /> : null}
          {error.status ? <InvalidLogin message={message} /> : null}
        </div>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, { checkUserAuth, showSnackBar }
)(withStyles(useStyles)(ETAAuth));
