import React from 'react';
import { Router } from "react-router-dom";
import history from './history';
import 'typeface-roboto';
import Layout from './Layouts'
import './App.css';
import axios from 'axios';
import { getDomainInfo, isAdminModeActive } from './Helpers/basic';

axios.interceptors.request.use(
  config => {
    const domainDet = getDomainInfo();
    let token = '';
    if (domainDet.domainTokenLabel !== undefined) {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (domainDet.domainTokenLabel === cookiePair[0].trim()) {
          token = decodeURIComponent(cookiePair[1]);
        }
      }
    }
    if (token !== '') {
      config.headers.Authorization = `Token ${token}`;
      /*
      if(isAdminModeActive()) {
        config.headers.AdminModeActive = `true`;
      }
      */
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (axios.isCancel(error)) {
    return new Promise(() => { });
  } else if (428 === error.response.status) {
    history.push('/welcome?from=cHJvZmlsZW1hbmRhdGU=')
    return new Promise(() => { });
  } else {
    return Promise.reject(error);
  }
});

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Layout />
      </div>
    </Router>
  );
}
export default App;