import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const data = {
  results: [
    //1 external and mandate false and status visited should go to visited
    //2 external and mandate true and status null should go to mandate
    //3 external and mandate true and status visited go to mandate
    {
      "id": 322,
      "course_location": "external",
      "is_mandate": false,
      "due_date_mandate": null,
      "course_id": 126319,
      "name": "Python Programming Certification Course",
      "duration": "24 Hours",
      "img": "https://d1jnx9ba8s6j9r.cloudfront.net/imgver.1578404100/img/m/co_img_786_1544180740.svg",
      "ratings": 5.0,
      "reviews": "",
      "type": "",
      "price": "$349",
      "url": "https://click.linksynergy.com/link?id=R1sOYsaeUzM&offerid=596027.14166327108&type=2&murl=https://www.edureka.co/python-programming-certification-training?utm_source=rakuten&utm_medium=datafeed&utm_campaign=pythonprogramming_certification_training&LSNSUBSITE=LSNSUBSITE",
      "course": [
          ""
      ],
      "provider": "Edureka",
      "status": "visited",
      "review": null,
      "rating": 0.0,
      "currency": null,
      "amount": 0.0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-02-06T08:15:04.514887Z",
      "rejected_by": "",
      "desc": "Learn Python programming from scratch through Edureka’s Python programming certification course. This Python Course will help you master important Python programming concepts such as data &amp; file operations in Python, object-oriented concepts in Python &amp; various Python libraries such as Pandas, Numpy, Matplotlib, and so on. This Python certification course is also a gateway towards your Data Science career. The course is curated by industry experts which includes real-time case studies."
    },
    {
      "id": 323,
      "course_location": "external",
      "is_mandate": true,
      "due_date_mandate": "2020-07-28T05:22:43.493070Z",
      "course_id": 211922,
      "name": "Learn Python from Scratch",
      "duration": "",
      "img": "https://www.educative.io/api/collection/10370001/5473789393502208/image/6718478230749184.png",
      "ratings": 0.0,
      "reviews": "",
      "type": "Beginner",
      "price": "$79",
      "url": "https://www.educative.io/courses/learn-python-from-scratch?aff=K7pq",
      "course": [
          "Educative"
      ],
      "provider": "educative",
      "status": null,
      "review": null,
      "rating": 0.0,
      "currency": null,
      "amount": 0.0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-08-05T06:35:07.758034Z",
      "rejected_by": "",
      "desc": "Python is the most popular programming language today, and its developers are constantly in high demand. As it continues to increase in popularity, it&#39;s become the closest thing to a must-know language for every programmer.The good news is that this course is the perfect place to start. Join us on an interactive journey through the syntax and functionality of Python. The course begins by exploring the basic building blocks before moving on to higher-level concepts such as functions and loops. Fun quizzes and coding challenges will accompany you along the way to help enforce all the concepts covered in the course.By the end of the course, you&#39;ll be familiar with the concept of data structures and functional programming in Python."
    },
    {
      "id": 324,
      "course_location": "external",
      "is_mandate": true,
      "due_date_mandate": "2020-07-28T05:22:43.493070Z",
      "course_id": 211922,
      "name": "Machine Learning",
      "duration": "",
      "img": "https://cedb-c.s3.amazonaws.com/media/hosting/banner/ad050520/20200729134224187920_machineLearning.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=zIN%2BGgbWTC6zNVKq0dpfrjim%2BnY%3D&Expires=1596559979",
      "ratings": 0.0,
      "reviews": "",
      "type": "Beginner",
      "price": "$79",
      "url": "https://www.educative.io/courses/learn-python-from-scratch?aff=K7pq",
      "course": [
          "Educative"
      ],
      "provider": "Udemy",
      "status": 'visited',
      "review": null,
      "rating": 0.0,
      "currency": null,
      "amount": 0.0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-08-05T06:35:07.758034Z",
      "rejected_by": "",
      "desc": "Python is the most popular programming language today, and its developers are constantly in high demand. As it continues to increase in popularity, it&#39;s become the closest thing to a must-know language for every programmer.The good news is that this course is the perfect place to start. Join us on an interactive journey through the syntax and functionality of Python. The course begins by exploring the basic building blocks before moving on to higher-level concepts such as functions and loops. Fun quizzes and coding challenges will accompany you along the way to help enforce all the concepts covered in the course.By the end of the course, you&#39;ll be familiar with the concept of data structures and functional programming in Python."
    },
    {
      "id": 27,
      "status": "yet-to-start",
      "title": "Javascript",
      "level": 3,
      "category": 4,
      "skill_tags": ['javascript'],
      "published": true,
      "provider": 'Continual Engine',
      "desc": "Javascript is an open source scripting language used to enhance user interfaces and dynamic websites. Learn how to tweak websites by enhancing the back end of HTML and CSS to allow for greater control, shortcuts and formatting. Dive deeper with this 5 hour Course and customize your websites like a pro!",
      "course_location": "internal",
      "is_mandate": true,
      "due_date_mandate": '2020-08-05T06:35:07.758034Z',
      "tenant": "mdb-None",
      "banner_url": "https://cedb-c.s3.amazonaws.com/https%3A//s3.us-east-1.amazonaws.com/cedb-c/media/hosting/banner/20200305113922691484_appleteacher.001.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=EvfIxf4%2B9bDxTos6Rzz6f9LkdTw%3D&Expires=1596622513",
      "modules_count": 0,
      "lesson_count": 0,
      "course_duration": 5
    },
    {
      "id": 30,
      "status": "yet-to-start",
      "title": "Java",
      "level": 3,
      "category": 4,
      "skill_tags": ["Java"],
      "published": true,
      "provider": "Continual Engine",
      desc: `<p><strong>Lorem Ipsum</strong><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);"> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></p>`,
      "course_location": "internal",
      "is_mandate": true,
      "due_date_mandate": '2020-10-05T06:35:07.758034Z',
      "tenant": "mdb-None",
      "banner_url": "https://cedb-c.s3.amazonaws.com/https%3A//s3.us-east-1.amazonaws.com/cedb-c/media/hosting/banner/20200305113922691484_appleteacher.001.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=EvfIxf4%2B9bDxTos6Rzz6f9LkdTw%3D&Expires=1596622513",
      "modules_count": 0,
      "lesson_count": 0,
      "course_duration": 3
    },
    {
      banner_url: "https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200512142753606619_javascript_beginer_tutorial.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=oV66qbfhmS8ETbNTTSMMmkUY%2Bnk%3D&Expires=1596559979",
      category: 2,
      course_duration: 5,
      desc: `<p><strong>Lorem Ipsum</strong><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);"> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></p>`,
      id: 7,
      lesson_count: 3,
      level: 2,
      modules_count: 0,
      provider: "Continual Engine",
      published: true,
      skill_tags: ["javascript"],
      status: "in-Progress",
      title: "Javascript",
      is_mandate: true,
      due_date_mandate: "2020-05-19T13:37:48.029261Z",
      course_location: 'internal',
    },
    {
      "id": 20,
      "status": "in-Progress",
      "title": "Test 2",
      "level": 3,
      "category": 4,
      "skill_tags": ["java"],
      "published": true,
      "provider": "Continual Engine",
      desc: `<p><strong>Lorem Ipsum</strong><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);"> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></p>`,
      "course_location": "internal",
      "is_mandate": false,
      "due_date_mandate": null,
      "tenant": "mdb-None",
      "banner_url": "https://cedb-c.s3.amazonaws.com/https%3A//s3.us-east-1.amazonaws.com/cedb-c/media/hosting/banner/20200305113922691484_appleteacher.001.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=EvfIxf4%2B9bDxTos6Rzz6f9LkdTw%3D&Expires=1596622513",
      "modules_count": 0,
      "lesson_count": 0,
      "course_duration": 3
    },
    {
      "id": 20,
      "status": "completed",
      "title": "Test course",
      "level": 3,
      "category": 4,
      "skill_tags": ["java"],
      "published": true,
      "provider": "Continual Engine",
      "desc": "test hosting",
      "course_location": "internal",
      "is_mandate": true,
      "due_date_mandate": null,
      "tenant": "mdb-None",
      "banner_url": "https://cedb-c.s3.amazonaws.com/https%3A//s3.us-east-1.amazonaws.com/cedb-c/media/hosting/banner/20200305113922691484_appleteacher.001.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=EvfIxf4%2B9bDxTos6Rzz6f9LkdTw%3D&Expires=1596622513",
      "modules_count": 0,
      "lesson_count": 0,
      "course_duration": 3
    },
    {
      "id": 303,
      "course_location": "external",
      "is_mandate": true,
      "due_date_mandate": null,
      "course_id": 123155,
      "name": "Multiplatform Mobile App Development with React Native",
      "duration": "Approx. 27 hours to complete",
      "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/18/5858b0658c11e88b82776afdbb6c5b/800px-React_Native_Logo.png?auto=format%2Ccompress&dpr=1",
      "ratings": 4.7,
      "reviews": "",
      "type": "Intermediate Level",
      "price": "₹3,503 per month",
      "url": "https://www.coursera.org/learn/react-native",
      "course": [
        "The Hong Kong University of Science and Technology"
      ],
      "provider": "Coursera",
      "status": "ongoing",
      "review": null,
      "rating": 0,
      "currency": null,
      "amount": 0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-02-05T07:34:34.381601Z",
      "rejected_by": "",
      "desc": "This course focuses on developing truly cross-platform, native iOS and Android apps using React Native (Ver 0."
    },
    {
      "id": 303,
      "course_location": "external",
      "is_mandate": false,
      "due_date_mandate": null,
      "course_id": 123155,
      "name": "Multiplatform Mobile App Development with React Native",
      "duration": "Approx. 27 hours to complete",
      "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/18/5858b0658c11e88b82776afdbb6c5b/800px-React_Native_Logo.png?auto=format%2Ccompress&dpr=1",
      "ratings": 4.7,
      "reviews": "",
      "type": "Intermediate Level",
      "price": "₹3,503 per month",
      "url": "https://www.coursera.org/learn/react-native",
      "course": [
        "The Hong Kong University of Science and Technology"
      ],
      "provider": "Coursera",
      "status": "pending-approval",
      "review": null,
      "rating": 0,
      "currency": null,
      "amount": 0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-02-05T07:34:34.381601Z",
      "rejected_by": "",
      "desc": "This course focuses on developing truly cross-platform, native iOS and Android apps using React Native (Ver 0."
    },
    {
      "id": 276,
      "course_location": "external",
      "is_mandate": true,
      "due_date_mandate": "2020-02-05T07:34:34.381601Z",
      "course_id": 123643,
      "name": "Python Basics",
      "duration": "Approx. 22 hours to complete",
      "img": "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-course-photos.s3.amazonaws.com/77/17ca30e9cf11e8931fddf70eb768e1/pythonfluency_1x1_course_1.png?auto=format%2Ccompress&dpr=1",
      "ratings": 4.8,
      "reviews": "",
      "type": "Beginner Level",
      "price": "₹3,503 per month",
      "url": "https://www.coursera.org/learn/python-basics",
      "course": [
        "University of Michigan"
      ],
      "provider": "Coursera",
      "status": "completed",
      "review": "Nice",
      "rating": 4,
      "currency": "₹",
      "amount": 1000,
      "certificate": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/certificates/20200116094902077143_20200113101917628842_Screenshot%20%281%29.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=T8tam%2FCX%2FUmmB09HE8NUkqp2izQ%3D&Expires=1596619516",
      "receipt": "https://cedb-c.s3.amazonaws.com/media/files/goniyo/receipts/20200116094903886236_20200113101917628842_Screenshot%20%281%29.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=z48Lo1LSUQpWKdS%2F3bTtRYDa2w0%3D&Expires=1596619516",
      "reason": null,
      "last_modified": "2020-02-06T04:46:42.471015Z",
      "rejected_by": "",
      "desc": "This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures."
    },
    {
      "id": 259,
      "course_location": "external",
      "is_mandate": false,
      "due_date_mandate": null,
      "course_id": 154096,
      "name": "Hands-On Statistical Predictive Modeling",
      "duration": "3 hours",
      "img": "https://i.udemycdn.com/course/480x270/1945886_0ec7_2.jpg",
      "ratings": 4.2,
      "reviews": "",
      "type": "Intermediate Level",
      "price": "360.0",
      "url": "https://www.udemy.com/course/hands-on-statistical-predictive-modeling/",
      "course": [
        ""
      ],
      "provider": "Udemy",
      "status": "ongoing",
      "review": null,
      "rating": 0,
      "currency": null,
      "amount": 0,
      "certificate": null,
      "receipt": null,
      "reason": null,
      "last_modified": "2020-02-05T07:34:33.200310Z",
      "rejected_by": "",
      "desc": "Predicting future trends can be the difference between profit and loss for competitive enterprises. Most businesses state that poor data quality leads to bad decision-making. Further, the predictive analytics market is expected to grow by 22% by 2020. As this technology hits the mainstream, now is the time to consider which predictive modeling techniques will produce the best results for your organization.Hands-On Statistical Predictive Modeling gives you everything you need to bring the power of statistical predictive models into your statistical or data mining work. However, without the right predictive modeling techniques, analytics projects are unlikely to provide actionable insights. This course will show you how these core algorithms underpin the accuracy and relevance of statistical results and drive competitive differentiation. You will be able to anticipate customer behavior, take steps to cultivate customer loyalty, and capture a greater share of the market. You will be aware of the data science forces shaping your future economy and will have mastered how best to use and seize these coming opportunities.By the end of this course, you will be able to elevate your company's analytics know-how to enhance its decision-making skills, cost efficiency, and profitability. You will also be able to put these skills to use in your upcoming statistical and data mining projects.About the AuthorJesus Salcedo has a Ph.D. in Psychometrics from Fordham University. He is an independent statistical and data-mining consultant and has been using SPSS products for over 20 years. He is a former SPSS Curriculum Team Lead and Senior Education Specialist; he has written numerous SPSS training courses and trained thousands of users."
    },
  ]
}

export const myCourses = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.myCourses.mycourses}`
  axios.get(url,{
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(data)
    })
}
export const completeCourse = (formData, courseId, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.myCourses.mycourses}${courseId}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const getRecommendedCourseCount = (successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.search.recommendTracking}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const requestToExtendDueDate = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.myCourses.requestToExtendDueDate}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const internalCoursesTrack = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.internalCourses}`
  axios.get(url,{
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
 }
 
 export const externalCoursesTrack = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.externalCourses}`
  axios.get(url,{
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
 }
 
 export const trainingsTracking = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.tranings}`
  axios.get(url,{
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
 }

//FOR EXTERNAL ASSESSMENTS

const mockAssessmentDataTrue = {
  count: 20,
  results: []
}
const mockAssessmentDatafalse = {
  results: [
    {
      "id": 1, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-1",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 2, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-2",
      "desc": "Testing",
      "percent": 80,
      "test_type": "mandatory",
      "ques_count": 2,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 80,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 3, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-3",
      "desc": "Testing",
      "percent": 50,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 30,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 4, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-4",
      "desc": "Testing",
      "percent": 80,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 60,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 5, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-5",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 2,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 6, //ASSESSMENT ID IN RENDERING
      "title": "Test Assessment-6",
      "desc": "Testing",
      "percent": 90,
      "test_type": "mandatory",
      "ques_count": 4,
      "due_date": "2021-01-10T18:30:00Z",
      "completed_date": "2021-01-09T18:30:00Z",
      "my_score": 0,
      "tenant": "mdb-ab050520"
    }
  ]
}
const mockAssignmentDataTrue = {
  count: 30,
  results:[]
}
const mockAssignmentDatafalse = {
  results:[
    {
      "id": 1, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-1",
      "desc": `<p>Testing</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 100,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 2, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-2",
      "desc": `<p>Desc</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 3, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-3",
      "desc": `<p>This is desc for assignment 3</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 4, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-4",
      "desc": `<p>Desc</p>`,
      "points": 100,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 5, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-5",
      "desc": `<p>Desc</p>`,
      "points": 90,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 90,
      "tenant": "mdb-ab050520"
    },
    {
      "id": 6, //ASSIGNMENT ID IN RENDERING
      "title": "Test assignment-6",
      "desc": `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">This course introduces the basics of Python 3, including conditional execution and iteration as control structures, and strings and lists as data structures. You'll program an on-screen Turtle to draw pretty pictures.</span></p>`,
      "points": 25,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "my_score": 0,
      "tenant": "mdb-ab050520"
    }
  ]
}

export const fetchExternalAssessments = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.fetchExternalAssessments}`
  axios.get(url,{
    params: payload
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    if(payload.count){
      successCallBack(mockAssessmentDataTrue)
    }else{
      successCallBack(mockAssessmentDatafalse)
    }
  })
}

export const fetchExternalAssignments = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userCoursesTrack.fetchExternalAssignments}`
  axios.get(url,{
    params: payload
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    if(payload.count){
      successCallBack(mockAssignmentDataTrue)
    }else{
      successCallBack(mockAssignmentDatafalse)
    }
  })
}
