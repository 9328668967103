import { apiConfig } from '../Configs/apiConfig';
import { logActivity } from '../store/ActivityLog/actionCreator'
import ceLogo from '../static/ce.png'
import prepLogo from '../static/prep.svg'
import goniyoLogo from '../static/goniyo_logo.png'
import zopsmartLogo from '../static/zopsmart_logo.png'
import falabellaLogo from '../static/zopsmart_logo.png'
import ansrsourceLogo from '../static/ansrsourcelogo.png'
import caseysLogo from '../static/caseys.svg'
import freoLogo from '../static/caseys.svg'
import { getDepartmentMenus } from '../Components/AppLayout/menuList';
import { sample } from 'lodash'
import couseEraIcon from '../static/providers/icons/coursera.png';
import dataCampIcon from '../static/providers/icons/datacamp.png';
import educativeIcon from '../static/providers/icons/educative.png';
import edurekaIcon from '../static/providers/icons/edureka.png';
import nptelIcon from '../static/providers/icons/nptel.png';
import udacityIcon from '../static/providers/icons/udacity.png';
import udemyIcon from '../static/providers/icons/udemy.png';
import linkedinIcon from '../static/providers/icons/linkedin.png';
import linuxFoundation from '../static/providers/icons/linuxfoundation.svg';
import { getTenant } from '../Configs/tenantConfig';

const jwt = require('jsonwebtoken');

export const getDomainInfo = () => {
  switch (window.location.origin) {
    case 'http://testce.localhost:3000':
      return {
        domainName: 'testce.localhost',
        hostName: 'http://testce.localhost:3000',
        hostingURL: 'http://testce.hosting.localhost:3001',
        renderingURL: 'http://testce.rendering.localhost:3001',
        domainTokenLabel: `testce_localhost_token`,
        domainUserLabel: `testce_localhost_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true,
      }
    case 'http://ansrsourcegems.localhost:3000':
      return {
        domainName: 'ansrsourcegems.localhost',
        hostName: 'http://ansrsourcegems.localhost:3000',
        hostingURL: 'http://ansrsourcegems.hosting.localhost:3001',
        renderingURL: 'http://ansrsourcegems.rendering.localhost:3001',
        domainTokenLabel: `ansrsourcegems_localhost_token`,
        domainUserLabel: `ansrsourcegems_localhost_user`,
        type: 'b2b',
        name: 'ansrsourcegems',
        logo: ansrsourceLogo,
        client: 'ansrsourcegems',
        sso: false
      }
    case 'http://sandbox.localhost:3000':
      return {
        domainName: 'sandbox.localhost',
        hostName: 'http://sandbox.localhost:3000',
        hostingURL: 'http://sandbox.hosting.localhost:3001',
        renderingURL: 'http://sandbox.rendering.localhost:3001',
        domainTokenLabel: `sandbox_localhost_token`,
        domainUserLabel: `sandbox_localhost_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'sandbox',
        sso: false
      }
    case 'http://zopsmart.localhost:3000':
    case 'https://zopsmart.localhost:3000':
      return {
        domainName: 'zopsmart.localhost',
        hostName: 'https://zopsmart.localhost:3000',
        hostingURL: 'https://zopsmart.hosting.localhost:3001',
        renderingURL: 'https://zopsmart.rendering.localhost:3001',
        domainTokenLabel: `zopsmart_localhost_token`,
        domainUserLabel: `zopsmart_localhost_user`,
        type: 'b2b',
        name: 'ZopSmart',
        logo: zopsmartLogo,
        client: 'zopsmart',
        sso: false,
      }
    case 'http://ansrsource.localhost:3000':
      return {
        domainName: 'ansrsource.localhost',
        hostName: 'https://ansrsource.localhost:3000',
        hostingURL: 'https://ansrsource.hosting.localhost:3001',
        renderingURL: 'https://ansrsource.rendering.localhost:3001',
        domainTokenLabel: `ansrsource_localhost_token`,
        domainUserLabel: `ansrsource_localhost_user`,
        type: 'b2b',
        name: 'ansrsource',
        logo: ansrsourceLogo,
        client: 'ansrsource',
        sso: false,
      }
    case 'http://falabella.localhost:3000':
      return {
        domainName: 'falabella.localhost',
        hostName: 'https://falabella.localhost:3000',
        hostingURL: 'https://falabella.hosting.localhost:3001',
        renderingURL: 'https://falabella.rendering.localhost:3001',
        domainTokenLabel: `falabella_localhost_token`,
        domainUserLabel: `falabella_localhost_user`,
        type: 'b2b',
        name: 'falabella',
        logo: falabellaLogo,
        client: 'falabella',
        sso: false,
      }
    case 'http://prep.localhost:3000':
      return {
        domainName: 'prep.localhost',
        hostName: 'http://prep.localhost:3000',
        hostingURL: 'http://prep.hosting.localhost:3001',
        renderingURL: 'http://prep.rendering.localhost:3001',
        domainTokenLabel: `prep_localhost_token`,
        domainUserLabel: `prep_localhost_user`,
        type: 'b2b',
        name: 'PREP',
        logo: prepLogo,
        sso: true
      }
    case 'http://continualengine.localhost:3000':
      return {
        domainName: 'continualengine.localhost',
        hostName: 'http://continualengine.localhost:3000',
        hostingURL: 'http://continualengine.hosting.localhost:3001',
        renderingURL: 'http://continualengine.rendering.localhost:3001',
        domainTokenLabel: `continualengine_localhost_token`,
        domainUserLabel: `continualengine_localhost_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true
      }
    case 'http://caseys.localhost:3000':
      return {
        domainName: 'caseys.localhost',
        hostName: 'http://caseys.localhost:3000',
        hostingURL: 'http://caseys.hosting.localhost:3001',
        renderingURL: 'http://caseys.rendering.localhost:3001',
        domainTokenLabel: `caseys_localhost_token`,
        domainUserLabel: `caseys_localhost_user`,
        type: 'b2b',
        name: 'Caseys',
        logo: caseysLogo,
        client: 'caseys',
        sso: true,
      }
    case 'https://caseys.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://caseys.moocpedia.com',
        hostingURL: 'https://caseys.hosting.moocpedia.com',
        renderingURL: 'https://caseys.rendering.moocpedia.com',
        domainTokenLabel: `caseys_moocpedia_token`,
        domainUserLabel: `caseys_moocpedia_user`,
        type: 'b2b',
        name: 'Caseys',
        logo: caseysLogo,
        client: 'caseys',
        sso: true
      }
    case 'http://freo.localhost:3000':
      return {
        domainName: 'freo.localhost',
        hostName: 'http://freo.localhost:3000',
        hostingURL: 'http://freo.hosting.localhost:3001',
        renderingURL: 'http://freo.rendering.localhost:3001',
        domainTokenLabel: `freo_localhost_token`,
        domainUserLabel: `freo_localhost_user`,
        type: 'b2b',
        name: 'freo',
        logo: freoLogo,
        client: 'freo',
        sso: false
      }
    case 'https://freo.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://freo.moocpedia.com',
        hostingURL: 'https://freo.hosting.moocpedia.com',
        renderingURL: 'https://freo.rendering.moocpedia.com',
        domainTokenLabel: `freo_moocpedia_token`,
        domainUserLabel: `freo_moocpedia_user`,
        type: 'b2b',
        name: 'freo',
        logo: freoLogo,
        client: 'freo',
        sso: false
      }
    case 'https://freo.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://freo.myathina.com',
        hostingURL: 'https://freo.hosting.myathina.com',
        renderingURL: 'https://freo.rendering.myathina.com',
        domainTokenLabel: `freo_myathina_token`,
        domainUserLabel: `freo_myathina_user`,
        type: 'b2b',
        name: 'freo',
        logo: freoLogo,
        client: 'freo',
        sso: false
      }
    case 'http://demo.localhost:3000':
      return {
        domainName: 'demo.localhost',
        hostName: 'http://demo.localhost:3000',
        hostingURL: 'http://demo.hosting.localhost:3001',
        renderingURL: 'http://demo.rendering.localhost:3001',
        domainTokenLabel: `demo_localhost_token`,
        domainUserLabel: `demo_localhost_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true
      }
    case 'http://testgoniyo.localhost:3000':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testgoniyo.localhost:3000',
        hostingURL: 'https://testgoniyo.hosting.localhost:3001',
        renderingURL: 'https://testgoniyo.rendering.localhost:3001',
        domainTokenLabel: `goniyo_localhost_token`,
        domainUserLabel: `goniyo_localhost_user`,
        type: 'b2b',
        name: 'Niyo',
        logo: goniyoLogo,
        client: 'goniyo',
        sso: true
      }
    case 'https://demo.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://demo.moocpedia.com',
        hostingURL: 'https://demo.hosting.moocpedia.com',
        renderingURL: 'https://demo.rendering.moocpedia.com',
        domainTokenLabel: `demo_moocpedia_token`,
        domainUserLabel: `demo_moocpedia_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true
      }
    case 'https://demo.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://demo.myathina.com',
        hostingURL: 'https://demo.hosting.myathina.com',
        renderingURL: 'https://demo.rendering.myathina.com',
        domainTokenLabel: `demo_myathina_token`,
        domainUserLabel: `demo_myathina_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true
      }
    case 'https://prep.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://prep.myathina.com',
        hostingURL: 'https://prep.hosting.myathina.com',
        renderingURL: 'https://prep.rendering.myathina.com',
        domainTokenLabel: `prep_myathina_token`,
        domainUserLabel: `prep_myathina_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: prepLogo,
        sso: true
      }
    case 'https://zopsmart.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://zopsmart.myathina.com',
        hostingURL: 'https://zopsmart.hosting.myathina.com',
        renderingURL: 'https://zopsmart.rendering.myathina.com',
        domainTokenLabel: `zopsmart_myathina_token`,
        domainUserLabel: `zopsmart_myathina_user`,
        type: 'b2b',
        name: 'ZopSmart',
        logo: zopsmartLogo,
        client: 'zopsmart',
        sso: false
      }
    case 'https://ansrsource.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://ansrsource.myathina.com',
        hostingURL: 'https://ansrsource.hosting.myathina.com',
        renderingURL: 'https://ansrsource.rendering.myathina.com',
        domainTokenLabel: `ansrsource_myathina_token`,
        domainUserLabel: `ansrsource_myathina_user`,
        type: 'b2b',
        name: 'ansrsource',
        logo: ansrsourceLogo,
        client: 'ansrsource',
        sso: false
      }
    case 'https://falabella.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://falabella.myathina.com',
        hostingURL: 'https://falabella.hosting.myathina.com',
        renderingURL: 'https://falabella.rendering.myathina.com',
        domainTokenLabel: `falabella_myathina_token`,
        domainUserLabel: `falabella_myathina_user`,
        type: 'b2b',
        name: 'falabella',
        logo: falabellaLogo,
        client: 'falabella',
        sso: false
      }
    case 'https://continualengine.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://continualengine.myathina.com',
        hostingURL: 'https://continualengine.hosting.myathina.com',
        renderingURL: 'https://continualengine.rendering.myathina.com',
        domainTokenLabel: `continualengine_myathina_token`,
        domainUserLabel: `continualengine_myathina_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'ce',
        sso: true
      }
    case 'https://ansrsourcegems.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://ansrsourcegems.myathina.com',
        hostingURL: 'https://ansrsourcegems.hosting.myathina.com',
        renderingURL: 'https://ansrsourcegems.rendering.myathina.com',
        domainTokenLabel: `ansrsourcegems_myathina_token`,
        domainUserLabel: `ansrsourcegems_myathina_user`,
        type: 'b2b',
        name: 'ansrsourcegems',
        logo: ansrsourceLogo,
        client: 'ansrsourcegems',
        sso: false
      }
    case 'https://sandbox.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://sandbox.myathina.com',
        hostingURL: 'https://sandbox.hosting.myathina.com',
        renderingURL: 'https://sandbox.rendering.myathina.com',
        domainTokenLabel: `sandbox_myathina_token`,
        domainUserLabel: `sandbox_myathina_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'sandbox',
        sso: false
      }
    case 'https://goniyo.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://goniyo.myathina.com',
        hostingURL: 'https://goniyo.hosting.myathina.com',
        renderingURL: 'https://goniyo.rendering.myathina.com',
        domainTokenLabel: `goniyo_myathina_token`,
        domainUserLabel: `goniyo_myathina_user`,
        type: 'b2b',
        name: 'Niyo',
        logo: goniyoLogo,
        client: 'goniyo',
        sso: true
      }
    case 'https://testgoniyo.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testgoniyo.moocpedia.com',
        hostingURL: 'https://testgoniyo.hosting.moocpedia.com',
        renderingURL: 'https://testgoniyo.rendering.moocpedia.com',
        domainTokenLabel: `goniyo_moocpedia_token`,
        domainUserLabel: `goniyo_moocpedia_user`,
        type: 'b2b',
        name: 'Niyo',
        logo: goniyoLogo,
        client: 'goniyo',
        sso: true
      }
    case 'https://prep.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://prep.moocpedia.com',
        hostingURL: 'https://prep.hosting.moocpedia.com',
        renderingURL: 'https://prep.rendering.moocpedia.com',
        domainTokenLabel: `prep_moocpedia_token`,
        domainUserLabel: `prep_moocpedia_user`,
        type: 'b2b',
        name: 'PREP',
        logo: prepLogo,
        sso: true
      }
    case 'https://ansrsourcegems.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://ansrsourcegems.moocpedia.com',
        hostingURL: 'https://ansrsourcegems.hosting.moocpedia.com',
        renderingURL: 'https://ansrsourcegems.rendering.moocpedia.com',
        domainTokenLabel: `ansrsourcegems_moocpedia_token`,
        domainUserLabel: `ansrsourcegems_moocpedia_user`,
        type: 'b2b',
        name: 'ansrsourcegems',
        logo: ansrsourceLogo,
        client: 'ansrsourcegems',
        sso: false
      }
    case 'https://sandbox.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://sandbox.moocpedia.com',
        hostingURL: 'https://sandbox.hosting.moocpedia.com',
        renderingURL: 'https://sandbox.rendering.moocpedia.com',
        domainTokenLabel: `sandbox_moocpedia_token`,
        domainUserLabel: `sandbox_moocpedia_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'sandbox',
        sso: false
      }
    case 'https://testce.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testce.moocpedia.com',
        hostingURL: 'https://testce.hosting.moocpedia.com',
        renderingURL: 'https://testce.rendering.moocpedia.com',
        domainTokenLabel: `testce_moocpedia_token`,
        domainUserLabel: `testce_moocpedia_user`,
        type: 'b2b',
        name: 'Continual Engine',
        logo: ceLogo,
        client: 'goniyo',
        sso: true
      }
    case 'https://zopsmart.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://zopsmart.moocpedia.com',
        hostingURL: 'https://zopsmart.hosting.moocpedia.com',
        renderingURL: 'https://zopsmart.rendering.moocpedia.com',
        domainTokenLabel: `zopsmart_moocpedia_token`,
        domainUserLabel: `zopsmart_moocpedia_user`,
        type: 'b2b',
        name: 'ZopSmart',
        logo: zopsmartLogo,
        client: 'zopsmart',
        sso: false
      }
    case 'https://ansrsource.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://ansrsource.moocpedia.com',
        hostingURL: 'https://ansrsource.hosting.moocpedia.com',
        renderingURL: 'https://ansrsource.rendering.moocpedia.com',
        domainTokenLabel: `ansrsource_moocpedia_token`,
        domainUserLabel: `ansrsource_moocpedia_user`,
        type: 'b2b',
        name: 'ansrsource',
        logo: ansrsourceLogo,
        client: 'ansrsource',
        sso: true
      }
    case 'https://falabella.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://falabella.moocpedia.com',
        hostingURL: 'https://falabella.hosting.moocpedia.com',
        renderingURL: 'https://falabella.rendering.moocpedia.com',
        domainTokenLabel: `falabella_moocpedia_token`,
        domainUserLabel: `falabella_moocpedia_user`,
        type: 'b2b',
        name: 'falabella',
        logo: falabellaLogo,
        client: 'falabella',
        sso: false
      }
    default:
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testce.moocpedia.com',
        hostingURL: 'https://testce.hosting.moocpedia.com',
        renderingURL: 'https://testce.rendering.moocpedia.com',
        domainTokenLabel: `testce_moocpedia_token`,
        domainUserLabel: `testce_moocpedia_user`,
        type: 'b2b',
        sso: true
      }
  }
}
export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}
export const getUSerDetails = () => {
  const domainDet = getDomainInfo();
  const userToken = getCookie(domainDet.domainUserLabel);
  if (userToken) {
    return parseJwt(userToken)
  } else {
    return {
      roles: []
    }
  }
}

export const getEnv = () => {
  switch (window.location.origin) {
    case 'http://testgoniyo.localhost:3000':
    case 'https://testgoniyo.moocpedia.com':
      return 'goniyo_dev'
    case 'http://testce.localhost:3000':
    case 'http://testce.myathina.localhost:3000':
    case 'https://testce.moocpedia.com':
      return 'ce_dev'
    case 'http://caseys.localhost:3000':
    case 'http://caseys.localhost:3000':
    case 'https://caseys.moocpedia.com':
      return 'caseys_dev'
    case 'http://ansrsourcegems.localhost:3000':
    case 'http://ansrsourcegems.myathina.localhost:3000':
    case 'https://ansrsourcegems.moocpedia.com':
      return 'ansrsourcegems_dev'
    case 'http://sandbox.localhost:3000':
    case 'http://sandbox.myathina.localhost:3000':
    case 'https://sandbox.moocpedia.com':
      return 'sandbox_dev'
    case 'http://zopsmart.localhost:3000':
    case 'https://zopsmart.localhost:3000':
    case 'https://zopsmart.moocpedia.com':
      return 'zopsmart_dev'
    case 'http://ansrsource.localhost:3000':
    case 'https://ansrsource.localhost:3000':
    case 'https://ansrsource.moocpedia.com':
      return 'ansrsource_dev'
    case 'http://falabella.localhost:3000':
    case 'https://falabella.localhost:3000':
    case 'https://falabella.moocpedia.com':
      return 'falabella_dev'
    case 'http://freo.localhost:3000':
    case 'https://freo.localhost:3000':
    case 'https://freo.moocpedia.com':
      return 'freo_dev'
    case 'http://demo.localhost:3000':
    case 'https://demo.localhost:3000':
    case 'https://demo.moocpedia.com':
      return 'demo_dev'
    case 'http://prep.localhost:3000':
    case 'https://prep.moocpedia.com':
      return 'prep_dev'
    case 'http://testansr.localhost:3000':
      return 'ansr_dev'
    case 'http://localhost:3000':
      return 'dev'
    case 'http://myathina-b2b.s3-website.ap-south-1.amazonaws.com':
      return 'test'
    case 'http://myathina-b2b-clone.s3-website.ap-south-1.amazonaws.com':
      return 'clone'
    case 'https://goniyo.myathina.com':
    case 'https://continualengine.myathina.com':
    case 'https://prep.myathina.com':
    case 'https://zopsmart.myathina.com':
    case 'https://sandbox.myathina.com':
    case 'https://falabella.myathina.com':
    case 'https://ansrsource.myathina.com':
    case 'https://demo.myathina.com':
    case 'https://freo.myathina.com':
    case 'https://caseys.myathina.com':
    case 'https://ansrsourcegems.myathina.com':
      return 'prod'
    default:
      return 'dev'
  }
}
export const validateEmail = email => {
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email)
}
export const getUrlParams = (url, param) => {
  let hashes = url.slice(url.indexOf('?') + 1).split('&')
  let params = {}
  hashes.map(hash => {
    let [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params[param]
}
export const isRecommendedCourse = courseDetails => {
  const enableSuggestedRibbon = false
  if (enableSuggestedRibbon) {
    const courseUrl = courseDetails.url ? courseDetails.url : ''
    const provider = courseDetails.provider ? courseDetails.provider : ''
    // const id = getUrlParams(courseUrl, 'id')
    return courseDetails.affiliate || courseUrl.indexOf('click.linksynergy.com') > -1 || apiConfig.affiliateProviders.indexOf(provider) > -1
  } else {
    return false
  }
}
export const openInNewTab = (url = '/') => {
  const win = window.open(url, '_blank');
  win.focus();
}
export const redirectTo = (url = '/', newTab = false) => {
  if (newTab) {
    openInNewTab(url)
  } else {
    window.location = url
  }
}
export const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
export const convertUTCTimeZone = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit" }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}
export const convertUTCDatewithTime = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit", hour: '2-digit', minute: '2-digit' }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}
export const getLoggedInStatus = () => {
  const domainInfo = getDomainInfo();
  const token = getCookie(domainInfo.domainTokenLabel);
  if (token) {
    return true
  } else {
    return false
  }
}
export const isAdminModeActive = () => {
  return getUSerType() === 'admin'

  // Commenting till Backend Changes to complete
  /*
  const domainInfo = getDomainInfo();
  const token = getCookie(domainInfo.domainUserLabel + '_isadmin');
  if (token && token === 'true') {
    return true
  } else {
    return false
  }
  */
}


export const getUSerType = () => {
  if (getUSerDetails().roles.indexOf('Admin') >= 0) {
    return 'admin'
  }
  else if (getUSerDetails().members > 0) {
    return 'manager'
  }
  else if (getUSerDetails().members === 0) {
    return 'employee'
  }
}
export const generateJwtToken = obj => {
  return jwt.sign(obj, 'shhhhh')
}
export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
export const openFreshworksWidget = (type) => {
  if (window.FreshworksWidget) {
    if (type === 'help') {
      const userDetails = getUSerDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open')
    } else {
      const userDetails = getUSerDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open', 'ticketForm')
    }
  }
}

export const logUserActivity = log => {
  logActivity(
    log,
    () => { },
    () => { }
  )
}
export const getCookieDomain = () => {
  const host = window.location.hostname
  const hostArr = host.split('.')
  const domain = hostArr[hostArr.length - 1] === 'com' ? hostArr[hostArr.length - 2] + '.com' : hostArr[hostArr.length - 1]
  return domain || ''
}
export const setCookie = (cookieName, cookieValue, days) => {
  const cookieDomain = getCookieDomain()
  var date, expires;
  date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  expires = "; expires=" + date.toUTCString();
  // document.cookie = cookieName + '=' + cookieValue + expires + ';domain=.firebaseapp.com; path=/';
  document.cookie = `${cookieName}=${cookieValue}${expires};domain=${cookieDomain}; path=/`;
}

export const getActiveStepComponent = (stepsArray, filledData) => {
  for (let i in stepsArray) {
    if (i !== stepsArray[stepsArray.length - 1] && filledData[stepsArray[i]] === null || filledData[stepsArray[i]] && filledData[stepsArray[i]].exp_start === null) {
      return stepsArray[i]
    }
  }
  return 'allFilled'
}

export const hasSearchAccess = () => {
  const tenantInfo = getTenant();
  const userActiveMenus = getUserActiveMenus();
  const deptMenus = getDepartmentMenus();
  const userData = getUSerDetails()
  let searchAccess = ''
  if (getUSerType() === 'admin') {
    searchAccess = true
    return searchAccess
  } else {
    //FOR CHECKING WITH AUTOMATED MENUS
    if (tenantInfo.showAutomatedActiveMenus) {
      let searchMenu = userActiveMenus.find((menu) => (menu.name === 'Search'))
      if (searchMenu && Object.keys(searchMenu) && Object.keys(searchMenu).length) {
        searchAccess = true
      } else {
        searchAccess = false
      }
    }
    //FOR CHECKING WITH UI HARD CODED MENUS
    else {
      deptMenus && deptMenus.length > 0 && deptMenus.map((dept) => {
        if (userData && userData.department && userData.department.id && userData.department.id === dept.departmentId) {
          searchAccess = dept.activeMenus && dept.activeMenus.length && dept.activeMenus.includes(2)
        }
      })
    }
  }
  return searchAccess
}

export const getCurrentYear = () => {
  let year = new Date().getFullYear()
  return year
}

export const cryptCourseId = (data) => {
  let cipherText = btoa(data);
  return cipherText;
}

export const getProviderImage = (providerName) => {
  switch (providerName) {
    case 'Coursera':
      return {
        img: couseEraIcon,

      };
    case 'Edureka':
      return {
        img: edurekaIcon,

      };
    case 'Datacamp':
      return {
        img: dataCampIcon,

      }
    case 'Educative':
      return {
        img: educativeIcon,

      }
    case 'NPTEL':
      return {
        img: nptelIcon,

      }
    case 'Udemy':
      return {
        img: udemyIcon,

      }
    case 'Udacity':
      return {
        img: udacityIcon,

      }
    case 'Caseys':
    case 'caseys':
      return {
        img: caseysLogo,

      }
    case 'freo':
      return {
        img: freoLogo,

      }
    case 'Linkedin':
    case 'Linkedin Learning':
      return {
        img: linkedinIcon,
      }
    case 'Linuxfoundation':
      return {
        img: linuxFoundation
      }
    default:
      return {
        img: '',
      }
  }
}

export const getRandomImage = (array) => {
  if (array && array.length) {
    return (
      sample(array)
    )
  }
}

//FOR GETTING THE USER ACTIVE MENUS
export const getUserActiveMenus = () => {
  const decodedMenus = decodeURI(localStorage.getItem('user_active_menus'));
  if (decodedMenus && decodedMenus.length) {
    const menus = JSON.parse(decodedMenus);
    return menus
  } else {
    return []
  }
}
