import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import { colorStyles } from '../../../styles/style';
const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
    },
    txtWrapper: {
        height: '40px',
    },
    loader: {
        margin: '2rem'
    }
}))

export default function NumbersLoader(props) {
    const classes = useStyles();
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Skeleton count={1} widthRandomness={0} width="100%" height="60px" color={colorStyles.skeletonLoaderBg} borderRadius="5px" className={classes.loader} />
                </Grid>
            </Grid>
        </div>
    )
}