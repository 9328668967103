import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestForgotPassword } from '../../store/Auth/actionCreator';
import myAthinaLogo from '../../static/myAthinaLogo.png';
import AlertMessages from '../../Components/Common/AlertMessages';
import { getTenant } from '../../Configs/tenantConfig';

const useStyles = theme => ({
  paper: {
    marginTop: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancel: {
    margin: theme.spacing(3, 1, 2),
  },
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '-4px',
    marginLeft: 10
  },
  logoImg: {
      width: '43px'
  },
   buttonProgress: {
    color: '#2e8eec'
  },
  label: {
    width: '100%',
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
    color: '#777'
  }
});
class ForgotPassword extends React.Component {
    state = {
        password: '',
        inProgress: false,
        error: {
            status: false,
            errorMessage: ''
        },
        reqForgotPassword: false,
      }
    componentDidMount() {
      window.FreshworksWidget('hide')
    }
    successCallBack = res => {
      this.setState({
        error: {
            status: false,
            errorMessage: ''
        },
        inProgress: false,
        reqForgotPassword: true
      })
    }
    failureCallBack = error => {
      this.setState({
          error: {
              status: true,
              errorMessage: 'Invalid credentials'
          },
          inProgress: false
      })
      document.getElementById('userName').value = ''
    }
    onForgotPassword = evnt => {
        evnt.preventDefault()
        this.setState(
            {
                error: {
                    status: false,
                    errorMessage: ''
                },
                inProgress: true
            }
        )
        const userName = document.getElementById('userName').value
        if(userName === '') {
          this.setState({
              error: {
                  status: true,
                  errorMessage: 'Please enter email.'
              },
              inProgress: false
          })
        } else {
          requestForgotPassword(
            {
              email: userName
            },
            this.successCallBack,
            this.failureCallBack
          )
        }
      }
      goToLogin = () => {
        this.props.history.push('/login')
      }
      getTenantLogo = () => {
        const tenant = getTenant()
        switch (tenant.shortName) {
          case 'ZS':
              return (<img src={tenant.logo} style={{ maxWidth: 150, backgroundColor: '#000' }} alt={tenant.tenant}/>)
          case 'FB':
              return (<img src={tenant.logo} style={{ maxWidth: 164 }} alt={tenant.tenant}/>)
          default:
            return (<img src={tenant.logo} style={{ maxWidth: 79 }} alt={tenant.tenant}/>)
        }
      }
      render() {
        const { classes } = this.props
        const { inProgress, error, reqForgotPassword } = this.state
        const tenant = getTenant()
        return (
            <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {this.getTenantLogo()}
            {tenant.shortName === 'FB' ? (
              <img
                src={myAthinaLogo}
                className={classes.myAthinaLogo}
                alt="myathina"
                style={{ width: 146, marginTop: 25 }}
              />
            ) : (
              <img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina"/>
            )}
          </Typography>

            {error.status ? (
                <div style={{marginTop: '20px',width: '90%'}}>
                    <AlertMessages status={error.status ? 'error' : 'success'} message={error.errorMessage} />
                </div>
            ) : null}
            {reqForgotPassword ? (
                <div style={{marginTop: '20px',width: '90%'}}>
                    <AlertMessages status="success" message="We have sent change password link to your email. Please click that link and change your password." />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.goToLogin}
                        disabled={inProgress}
                    >
                        Go to login
                    </Button>
                </div>
            ) : (
              <React.Fragment>
                <Typography component="h2" variant="h6" className={classes.label}>Forgot / Reset password</Typography>
                <form className={classes.form} noValidate onSubmit={this.onForgotPassword} autoComplete="off">
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="userName"
                    label="Email Address"
                    name="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.onForgotPassword}
                    disabled={inProgress}
                >
                    {inProgress && (
                    <CircularProgress size={25} className={classes.buttonProgress} />
                )}
                    Request to change password
                </Button>
                <Button
                    variant="contained"
                    color="default"
                    className={classes.cancel}
                    onClick={this.goToLogin}
                    disabled={inProgress}
                    style={{ marginLeft: 10 }}
                >
                    Cancel
                </Button>
                </form>
              </React.Fragment>
            )}

        </div>
        </Container>
    );
    }
}
const mapStateToProps = state => {
    return {
      authDetails: state.authReducer.authDetails,
    }
  }
  export default connect(
    mapStateToProps, {}
  )(withStyles(useStyles)(ForgotPassword));
