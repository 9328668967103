import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { closeSnackBar } from '../../../store/AlertMessages/actionCreator';
import './errorSnack.css';
import { openFreshworksWidget } from '../../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: '#fff',
    borderLeft: '10px solid green',
    color: 'green'
  },
  error: {
    backgroundColor: '#fff',
    color: 'red'
  },
  warning: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #FF9800',
    color: '#FF9800',
    fontSize: '15px'
  },
  info: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #2196F3',
    color: '#2196F3'
  },
}));
function SnackBarmessages(props) {
  const classes = useStyles();
  const state = {
    open: props.state,
    vertical: 'top',
    horizontal: 'center',
  }
  const { vertical, horizontal } = state;
  const handleClose = () => {
    props.closeSnackBar()
  };
  const openFeedBack = () => {
    openFreshworksWidget()
  }
  const errorContent = () => {
    return (
      <div className="alert">
        <div className="alert-icon">
          <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
            <path fill="var(--color)" d="M10 3H14V14H10V3M10 21V17H14V21H10Z" />
          </svg>
        </div>

        <p className="alert-message">
          {props.message}
        </p>
        <div className="spacer"></div>
        <div className="alert-dismiss">
          <Button onClick={openFeedBack}>Report</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={props.state}
        onClose={handleClose}
        autoHideDuration={props.type !== 'error' ? 2000 : null}

        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes[props.type]
          }
        }}
        message={props.type === 'error' ? errorContent() : <span id="message-id">{props.message}</span>}
      />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    alertReducer: state.alertReducer
  }
}
export default connect(
  mapStateToProps, { closeSnackBar }
)(SnackBarmessages);
