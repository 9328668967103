import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    float: 'left',
    width: '100%',
  },
  pendingApprovalWrapper: {
    padding: '1rem',
    backgroundColor: '#fff',
    paddingTop: '8px',
    position: 'relative',
    height: 340,
    color: theme.palette.text.secondary,
  },
  pendingApprovalWrapperMob: {
    padding: '1rem',
    backgroundColor: '#fff',
    paddingTop: '8px',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  table: {
    //height: 270,
  },
  courseChartDataWrapper: {
    display: 'inline-block',
    textAlign: 'left',
    marginTop: '10px',
    backgroundColor: '#4172a2',
    width: '100%',
    overflowY: 'auto',
    height: '270px'
  },
  tableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'left',
    color: '#fff',
    maxWidth: '133px',
    wordWrap: 'break-word'
  },
  viewAll: {
    color: '#fff',
    fontSize: '1rem',
    margin: 15,
    letterSpacing: 2
  }
}));

function PendingApprovals(props) {

  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:600px)');
  const [pendingApprovals, setPendingApprovals] = useState((props.pendingApprovals && props.pendingApprovals.data) || [])
  const [pendingApprovalCnt, setPendingApprovalCnt] = useState((props.pendingApprovals && props.pendingApprovals.totalCount) || 0)

  useEffect(() => {
    if (props.pendingApprovals) {

      setPendingApprovals(props.pendingApprovals.data)
      setPendingApprovalCnt(props.pendingApprovals.totalCount)
    }
  }, [props.pendingApprovals])

  const goToApprovalRequests = evnt => {
    evnt.preventDefault()
    props.history.push('/approvalrequests')
  }
  return (
    <div className={classes.root}>
      <Paper className={mobileView ? classes.pendingApprovalWrapperMob : classes.pendingApprovalWrapper}>
        <div >
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant="h6" gutterBottom>
              External Courses Pending Approval
            </Typography>
            {pendingApprovalCnt ? (
              <Link component="button" style={{ fontSize: '0.9rem', marginTop: '-4px' }} onClick={goToApprovalRequests}>
                View all {pendingApprovalCnt} requests
              </Link>
            ) : null}
          </div>
          <div className={classes.courseChartDataWrapper}>
            <Table className={classes.table} aria-label="Courses Pending Approval Requests">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Team Member Name</TableCell>
                  <TableCell align="center" className={classes.tableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Course</TableCell>
                  <TableCell align="center" className={classes.tableCell} style={{ fontweight: '700', fontSize: '1rem' }}>Requested Date</TableCell>
                  <TableCell align="center" className={classes.tableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Approver's Name</TableCell>
                  <TableCell align="center" className={classes.tableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.inProgress ? (
                  <Typography style={{ color: '#fff', marginTop: '14px', fontSize: '16px', fontWeight: 500, textAlign: 'left' }}>Loading...</Typography>
                ) : (
                  <React.Fragment>
                    {pendingApprovals && pendingApprovals.length > 0 && pendingApprovals.slice(0, 8).map((row, key) => (
                      <TableRow key={key} style={{ verticalAlign: 'top' }}>
                        <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                          {row && row.name && row.name.length && row.name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                          {row && row.course && row.course.length && row.course}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                          {row && row.requestDate && row.requestDate}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                          {row && row.reporting_to && row.reporting_to.length && row.reporting_to}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                          <Link component="button" style={{ color: '#fff' }} onClick={(event) => { goToApprovalRequests(event) }}>View</Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                )}
              </TableBody>
            </Table>
            {pendingApprovalCnt ? (
              <div style={{ textAlign: 'center' }}>
                <Link component="button" className={classes.viewAll} onClick={goToApprovalRequests}>
                  view all requests
                </Link>
              </div>
            ) : (
              <React.Fragment>
                {!props.inProgress ? (
                  <Typography style={{ color: '#fff', marginTop: '10px', fontSize: '14px', textAlign: 'left' }}>No data found</Typography>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
      </Paper>
    </div >
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(PendingApprovals);
