import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { colorStyles } from '../../styles/style'

const useStyles = makeStyles(theme => ({
  button: {
    flexGrow: 1,
    float: 'right',
    marginBottom: '15px',
    marginRight: '38px',
    backgroundColor: colorStyles.fontColor,
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: colorStyles.fontColor,
    },
  },
  buttonProgress: {
    marginTop: '3px',
    marginRight: '16px',
    color: colorStyles.fontColor
  }
}));

export default function DownloadButton(props) {
  const classes = useStyles();
  const loader = props.reportLoader ? props.reportLoader : false
  const exportCSV = () => {
    props.onClickExport()
  };

  return (
    <Grid container direction="column" style={{ alignItems: 'flex-end'}}>
      <Grid item xs={12}>
        { loader ? (
          <CircularProgress size={25} className={classes.buttonProgress} />
        ): null }
        <Tooltip title="Export as CSV">
          <Button
            variant="contained"
            size="small"
            disabled={loader}
            className={classes.button}
            startIcon={<GetAppIcon/>}
            onClick={() => {exportCSV()}}
          >
            <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
              Report
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
