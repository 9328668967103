import GoNiyoLogo from '../static/goniyo_logo.png';
import CELogo from '../static/ce.png';
// import CELogo from '../static/smartfren-logo.png';
import LowesLogo from '../static/goniyo_logo.png';
import PREPLogo from '../static/prep.svg';
import CaseysLogo from '../static/caseys.svg';
import FreoLogo from '../static/freo.svg';
import ZopsmartLogo from '../static/zopsmart_logo.png';
import FalabellaLogo from '../static/falabella_logo.png';
import AnsrsourceLogo from '../static/ansrsourcelogo.png';

export const getTenant = () => {
  switch (window.location.origin) {
    case 'http://testgoniyo.localhost:3000':
    case 'https://testgoniyo.moocpedia.com':
      return {
        tenant: 'testgoniyo',
        shortName: 'Niyo',
        provider_name: 'Goniyo',
        logo: GoNiyoLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false,
        settings: {
          activate: false,
          languageSettings: true,
          currencySettings: true,
          locationSettings: false
        }
      }
    case 'http://testce.localhost:3000':
    case 'https://testce.moocpedia.com':
      return {
        tenant: 'ce',
        shortName: 'CE',
        provider_name: 'Continual Engine',
        logo: CELogo,
        allocate_budget: true,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND left menus
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false,
        settings: {
          activate: true,
          languageSettings: true,
          currencySettings: true,
          locationSettings: false
        }
      }
    case 'http://ansrsourcegems.localhost:3000':
    case 'https://ansrsourcegems.moocpedia.com':
    case 'https://ansrsourcegems.myathina.com':
      return {
        tenant: 'ansrsourcegems',
        shortName: 'ansrsourcegems',
        provider_name: 'ANSR Source',
        logo: AnsrsourceLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: true,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false,
        clientmanagement: true
      }
    case 'http://sandbox.localhost:3000':
    case 'https://sandbox.moocpedia.com':
    case 'https://sandbox.myathina.com':
      return {
        tenant: 'sandbox',
        shortName: 'sandbox',
        provider_name: 'Continual Engine',
        logo: CELogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: true,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://zopsmart.localhost:3000':
    case 'https://zopsmart.localhost:3000':
      //case 'https://zopsmart.moocpedia.com':
      //case 'https://zopsmart.myathina.com':
      return {
        tenant: 'zopsmart',
        shortName: 'ZS',
        provider_name: 'ZopSmart',
        logo: ZopsmartLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://falabella.localhost:3000':
    case 'https://falabella.localhost:3000':
    case 'https://falabella.moocpedia.com':
      return {
        tenant: 'falabella',
        shortName: 'FB',
        provider_name: 'falabella',
        logo: FalabellaLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: true,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://falabella.myathina.com':
      return {
        tenant: 'falabella',
        shortName: 'FB',
        provider_name: 'falabella',
        logo: FalabellaLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: true,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://prep.localhost:3000':
    case 'https://prep.moocpedia.com':
      return {
        tenant: 'prep',
        shortName: 'PREP',
        provider_name: 'PREP',
        logo: PREPLogo,
        allocate_budget: false,
        show_welcomepage: false,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://testansr.localhost:3000':
      return {
        tenant: 'ansr',
        shortName: 'ANSR',
        provider_name: 'ansr sourse',
        logo: CELogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://testlowes.localhost:3000':
      return {
        tenant: 'lowes',
        shortName: 'Lowes',
        provider_name: 'Lowes',
        logo: LowesLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://localhost:3000':
      return {
        tenant: 'ce',
        shortName: 'CE',
        provider_name: 'Continual Engine',
        logo: CELogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://prep.myathina.com':
      return {
        tenant: 'prep',
        shortName: 'PREP',
        provider_name: 'PREP',
        logo: PREPLogo,
        allocate_budget: false,
        show_welcomepage: false,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://ansrsource.localhost:3000':
      return {
        tenant: 'ansrsource_dev',
        shortName: 'ANSR',
        provider_name: 'ansrsource',
        logo: AnsrsourceLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://ansrsource.moocpedia.com':
      return {
        tenant: 'ansrsource_dev',
        shortName: 'ANSR',
        provider_name: 'ansrsource',
        logo: AnsrsourceLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://ansrsource.myathina.com':
      return {
        tenant: 'ansrsource',
        shortName: 'ANSR',
        provider_name: 'ansrsource',
        logo: AnsrsourceLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: false,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://continualengine.myathina.com':
    case 'https://continualengine.localhost:3000':
      return {
        tenant: 'ceProd',
        shortName: 'CE',
        provider_name: 'Continual Engine',
        logo: CELogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'https://goniyo.myathina.com':
    case 'https://goniyo.localhost:3000':
      return {
        tenant: 'goniyo',
        shortName: 'Niyo',
        provider_name: 'Goniyo',
        logo: GoNiyoLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false
      }
    case 'http://freo.localhost:3000':
    case 'https://freo.localhost:3000':
    case 'https://freo.moocpedia.com':
    case 'https://freo.myathina.com':
      return {
        tenant: 'freo',
        shortName: 'freo',
        provider_name: 'freo',
        logo: FreoLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: false,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false,
        settings: {
          activate: false,
          languageSettings: true,
          currencySettings: true,
          locationSettings: false
        }
      }
    case 'http://demo.localhost:3000':
    case 'https://demo.localhost:3000':
    case 'https://demo.moocpedia.com':
    case 'https://demo.myathina.com':
      return {
        tenant: 'demo',
        shortName: 'DEMO',
        provider_name: 'Continual Engine',
        logo: CELogo,
        allocate_budget: true,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: false,
        settings: {
          activate: true,
          languageSettings: true,
          currencySettings: true,
          locationSettings: false
        }
      }
    case 'http://caseys.localhost:3000':
    case 'https://caseys.moocpedia.com':
      return {
        tenant: 'caseys',
        shortName: 'caseys',
        provider_name: 'Caseys',
        logo: CaseysLogo,
        allocate_budget: false,
        show_welcomepage: true,
        sso: true,
        show_articles: true,
        enableCourseReviews: true,
        showMyathinaRecInSearchPage: true,
        showProfilePicture: true,
        showLeaderView: false,
        showHrView: false,
        showExternalAssessments: false,
        showRedeemMenuRewardsGraph: false,
        showAuthorCoursesMenu: true,
        showOnboardSettingMenu: true,
        showAutomatedActiveMenus: true, //FOR FETCHING THE AUTOMATED MENUS FROM BACKEND
        experience_levels: [
          { id: 1, name: 'Less than 1' },
          { id: 2, name: '1 to 3' },
          { id: 3, name: '4 to 6' },
          { id: 4, name: '7 to 10' },
          { id: 5, name: '11 to 15' },
          { id: 6, name: '15 +' }
        ],
        autoapprove_mandatory_courses: true,
        request_course_duedate_extension: true,
        showSkillmapLastLevelNode: true,
        settings: {
          activate: false,
          languageSettings: true,
          currencySettings: true
        }
      }
    default:
      return 'dev'
  }
}
