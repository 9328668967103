import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
      decimalScale={0}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CurrencyInput(props) {
  const [values, setValues] = React.useState({
    numberformat: '',
  });
  const label = props.label

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
    props.setValue(event.target.value)
  };

  return (
    <TextField
      required
      label={label}
      value={values.numberformat}
      onChange={handleChange('numberformat')}
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={props.helperText ? props.helperText : ''}
      disabled={props.disabled}
    />
  );
}
