import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const fetchStepperComponents = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.fetchComponents}`
  axios.get(url,{
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchRoles = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.fetchRoles}`
  axios.get(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

const mockSkills = {
  results: [
    { id: 1, name: 'java', category: 'Technology' },
    { id: 15, name: 'ReactJs', category: 'Technology' },
    { id: 17, name: 'Python', category: 'Technology' },
    { id: 4, name: 'Product Management', category: 'Management' },
    { id: 5, name: 'Project Management', category: 'Management' },
    { id: 6, name: 'Engineering Management', category: 'Management' },
    { id: 7, name: 'aaa', category: 'Others' },
    { id: 8, name: 'bbb', category: 'Others' },
    { id: 9, name: 'ccc', category: 'Others' },
    { id: 10, name: 'ddd', category: 'Others' }
  ]
}
export const fetchSkills = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.fetchSkiils}`
  axios.get(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
    // successCallBack(mockSkills)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchInterest = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.fetchInterest}`
  axios.get(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
export const fetchWorkAllocations = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.worktimeallocation}`
  axios.get(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const submitDetails = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userProfile.submitDetails}`
  axios.post(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error.response)
  })
}
