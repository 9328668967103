import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Chart from 'chart.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    float: 'left',
    width: '100%'
  },
  rewardsChartWrapper: {
    marginTop: 10,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '340px',
  },
  rewardsCharWrapperMob: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '100%',
    width: '98%',
    minWidth: '80%',

  },
  rewardsChart: {
    position: 'relative',
  },
}));
function RewardsChart(props) {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:600px)');
  const [title, setTitle] = useState('')
  const [rewardsData, setRewardsData] = React.useState([])

  const renderRewardsChart = (rewards) => {
    const rewardsChart = document.getElementById('rewardsChart1')
    const rewardsData = rewards.data
    let dataPoints = []
    let labels = []
    for (let i in rewardsData) {
      labels.push(rewardsData[i].label)
      dataPoints.push(rewardsData[i].value)
    }
    const Chart = props.Chart
    new Chart(rewardsChart, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: dataPoints,
          pointStyle: 'circle',
          radius: 3,
          label: 'Rewards - 2019',

          backgroundColor: ['#f5f5f51a'],
          borderColor: '#7cb5ec',
          borderWidth: 3,
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            ticks: {
              suggestedMin: 0
            }
          }]
        }
      }
    });
  }

  useEffect(() => {
    if (props.rewards) {
      setTitle(props.rewards.title)
      setRewardsData(props && props.rewards && props.rewards.data)
      renderRewardsChart(props.rewards)
    }
  }, [props.rewards])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={mobileView ? classes.rewardsCharWrapperMob : classes.rewardsChartWrapper}>
            <div className={classes.rewardsChart}>
              <Typography variant="h6" gutterBottom>
                {title}
              </Typography>
              <canvas id="rewardsChart1" aria-label="Rewards Chart" role="img" style={{ paddingBottom: 10 }}></canvas>
              <div id="main" tabIndex="-1" className="accessibility">
                <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                  {rewardsData && rewardsData.length && rewardsData.map((row, key) => (
                    <li key={key} style={{ marginRight: 10 }}>
                      <span>{`Total Rewards in ${row && row.label && row.label} Month ${row && row.value && row.value}`}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(RewardsChart);
