import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { allStores } from './stores.js'

const mockUserSettings = {
  location: {
      "Store_ID": "68",
      "Store": "ALTON",
      "Address": "1109 3RD AVE",
      "City": "ALTON",
      "State": "IOWA",
      "Zip": "51003",
      "County": "SIOUX",
      "Store_Latitude": "42.9889506",
      "Store_Longitude": "-96.0090739",
      "Store_Type": "C",
      "Store_Status": "A",
      "Division Director": "DAVE JOHNSON",
      "Region Director": "ADAM NEEMEYER",
      "District Supervisor": "HEIDI MARCYES",
      "Store Manager": "PAM PLATHE"
  },
  currency: {
    name: 'USD',
    value: '$'
  },
  language: 'English'
}

export const updateUserSettings = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userSettings.updateUserSettings}/`
  axios.put(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

//FOR FETCHING User Settings Requests
export const fetchUserSettings = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userSettings.fetchUserSettings}`

  successCallBack(mockUserSettings)
  return;

  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
//FOR FETCHING Stores List
export const fetchAllStores = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userSettings.fetchAllStores}`

  successCallBack(allStores)
  return;

  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
