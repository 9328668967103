import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { fetchMyDocs } from '../../store/Dashboard/actionCreator';
import { convertUTCTimeZone } from '../../Helpers/basic';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    float: 'left',
    width: '100%'
  },
  courseChartWrapper: {
    flexGrow: 1,
    marginTop: 10,
    width: '100%',
    float: 'left',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '293px',
    position: 'relative',
  },
  courseChartWrapperMob: {
    flexGrow: 1,
    marginTop: 20,
    float: 'left',
    padding: '8px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  courseChartDataWrapper: {
    marginTop: '10px',
    display: 'inline-block',
    textAlign: 'left',
    backgroundColor: '#4172a2',
    width: '100%',
    overflowY: 'auto',
    height: '200px'
  },
  courseChartLegendBox: {
    height: 5,
    width: 15,
    display: 'inline-block',
    marginRight: 5
  },
  courseChartTableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'left',
    color: '#fff',
    maxWidth: '133px',
    wordWrap: 'break-word'
  },
  mtd: {
    position: 'absolute',
    top: '1px',
    right: '8px',
    fontSize: '10px'
  },
  status: {
    backgroundColor: '#f5f5f51c',
    padding: 5,
    marginRight: 4
  },
  data: {
    fontSize: '1.5rem',
  },
  table: {
    //height: '200px'
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '-13px 1rem 0 0'
  },
}));

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]

function TrainingChart(props) {
  const classes = useStyles();
  const [selectedRange, setSelectedRange] = useState('YTD');
  const [inProgress, setInProgress] = useState(true);
  const [tableData, setTableData] = useState([]);
  const mobileView = useMediaQuery('(max-width:600px)');

  const successCallBack = res => {
    setTableData(res.results);
    setInProgress(false);
  }
  const failureCallBack = error => {
    setTableData([]);
    setInProgress(false);
    props.showSnackBar({
      state: true,
      message: 'Sorry something went wrong while fetching Trainings data!',
      type: 'error'
    })
  }

  useEffect(() => {
    setInProgress(true);
    fetchMyDocs({
      range: selectedRange,
      value: new Date().getFullYear(),
      offset: 0,
      number: 7
    }, successCallBack, failureCallBack)
  }, [])

  const handleChangeOption = (event) => {
    setInProgress(true);
    setSelectedRange(event.target.value);
    let payload = ''
    if (event.target.value && event.target.value === 'YTD') {
      payload = {
        range: event.target.value,
        value: new Date().getFullYear(),
        offset: 0,
        number: 7
      }
    } else {
      payload = {
        range: event.target.value,
        year_value: new Date().getFullYear(),
        value: (new Date().getMonth() + 1).toString().padStart(2, "0"),
        offset: 0,
        number: 7
      }
    }
    fetchMyDocs(payload, successCallBack, failureCallBack)
  }

  return (
    <div className={classes.root}>
      <Paper className={mobileView ? classes.courseChartWrapperMob : classes.courseChartWrapper}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ marginBottom: '5px', display: 'flex', width: '100%' }}>
            Trainings
          </Typography>
          <div className={classes.mtdCtr}>
            <TextField
              className={classes.mtd}
              id="progress"
              select
              classes={{ outlined: "padding:1px" }}
              margin="dense"
              value={selectedRange}
              onChange={handleChangeOption}
              name="Progress"
              variant="outlined"
              disabled={inProgress}
            >
              {listOfSelection && listOfSelection.map((option) => (
                <MenuItem style={{ padding: '1px', marginLeft: '5px' }} key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.courseChartDataWrapper}>
              <div style={{ textAlign: 'right', backgroundColor: '#fff' }}>
                <div className={classes.legend} style={{ display: 'inline-block', marginRight: 8 }}>
                  <span className={classes.courseChartLegendBox} style={{ backgroundColor: '#008d93' }}></span>
                  <span aria-hidden="true">Completed</span>
                </div>
                <div className={classes.legend} style={{ display: 'inline-block', marginRight: 6 }}>
                  <span className={classes.courseChartLegendBox} style={{ backgroundColor: '#45c490' }}></span>
                  <span aria-hidden="true">In Progress</span>
                </div>
                <div className={classes.legend} style={{ display: 'inline-block' }}>
                  <span className={classes.courseChartLegendBox} style={{ backgroundColor: '#ff8f8f' }}></span>
                  <span aria-hidden="true">Overdue</span>
                </div>
              </div>
              <Table className={classes.table} aria-label="Trainings Completed and Pending">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Training</TableCell>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Created</TableCell>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Due</TableCell>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                {inProgress ? (
                  <div style={{ fontSize: '14px', fontWeight: '600', color: '#ffff', width: '100px' }}>Loading...</div>
                ) : (
                  <TableBody>
                    {tableData && tableData.length ? (
                      <React.Fragment>
                        {tableData.map((row, key) => (
                          <TableRow key={key} style={{ verticalAlign: 'top' }}>
                            <TableCell component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                              {row && row.title && row.title.length && row.title}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                              {convertUTCTimeZone(row.created_date)}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                              {convertUTCTimeZone(row.due_date)}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                              <div id="main" tabIndex="-1" className="accessibility">
                                <span className={classes.status} >{`Completed members ${row.completed_count ? row.completed_count : 0}`}</span>
                                <span className={classes.status} >{`Inprogress members ${row.pending_count ? row.pending_count : 0}`}</span>
                                <span className={classes.status} >{`Overdue members ${row.overdue_count ? row.overdue_count : 0}`}</span>
                              </div>
                              <span className={classes.status} style={{ color: 'lightgreen' }} aria-hidden="true">{row.completed_count ? row.completed_count : 0}</span>
                              <span className={classes.status} style={{ color: '#45c490' }} aria-hidden="true">{row.pending_count ? row.pending_count : 0}</span>
                              <span className={classes.status} style={{ color: '#ff8f8f' }} aria-hidden="true">{row.overdue_count ? row.overdue_count : 0}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ) : (
                      <Typography style={{ color: '#fff', marginTop: '14px', fontSize: '14px' }}>No data found</Typography>
                    )}
                  </TableBody>
                )}
              </Table>
            </div>
          </Grid>
        </Grid>
      </Paper >
    </div >
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(TrainingChart);
