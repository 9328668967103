import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getDashboardData } from '../../store/Dashboard/actionCreator';
import PieChartLoader from '../Common/SkeletonLoaders/SkillmapLoader/doughnutChartLoader';
import { showSnackBar } from "../../store/AlertMessages/actionCreator";
// import Chart from 'chart.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    float: 'left',
  },
  courseChartWrapper: {
    padding: '1rem',
    flexGrow: 1,
    marginTop: 10,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: 340,
    width: '100%',
    position: 'relative',
  },
  courseChartWrapperMob: {
    padding: '1rem',
    flexGrow: 1,
    marginTop: 20,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '100%',
    position: 'relative',
  },
  courseChart: {
    position: 'relative',
    height: '140px',
    width: '281px'
  },
  courseChartDataWrapper: {
    display: 'inline-block',
    textAlign: 'left',
    marginTop: '8px',
    backgroundColor: '#4172a2',
    width: '100%',
    overflowY: 'auto',
    height: '262px'
  },
  courseChartLegendBox: {
    height: 5,
    width: 15,
    display: 'inline-block',
    marginRight: 5
  },
  courseChartTableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'center',
    color: '#fff',
    maxWidth: '157px',
    wordWrap: 'word-break'
  },
  courseChartNumberCell: {
    padding: 6,
    fontSize: '0.8rem',
    paddingRight: '20px',
    color: '#fff',
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '-11px 11px 0 0'
  }
}));

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]

function CoursesChart(props) {
  const classes = useStyles();
  const showPieChart = false;
  const [selectedRange, setSelectedRange] = useState('YTD');
  const [inProgress, setInProgress] = useState(false);
  const mobileView = useMediaQuery('(max-width:600px)');
  const [courseData, setCourseData] = useState((props && props.courses && props.courses.courseData) || [])
  const [tableData, setTableData] = useState((props && props.courses && props.courses.tableData) || [])
  const colorArray = [
    '#008d93',
    '#45c490',
  ];

  const renderCourseChart = (courses) => {
    const courseData = courses && courses.courseData && courses.courseData;
    let courseDataLabels = []
    let courseDataValues = []
    let gotValue = false

    for (let i in courseData) {
      courseDataLabels.push(courseData[i].label)
      courseDataValues.push(courseData[i].value)
      if (!gotValue && courseData[i].value) {
        gotValue = true
      }
    }
    if (!gotValue) {
      courseDataLabels.push('empty')
      courseDataValues.push(100)
    }
    const courseChart = document.getElementById('courseChart1')
    const Chart = props.Chart
    new Chart(courseChart, {
      type: 'doughnut',
      data: {
        labels: courseDataLabels,
        datasets: [{
          label: '# of Votes',
          data: courseDataValues,
          backgroundColor: [
            '#008d93',
            '#45c490',
            '#ccc'
          ],
          borderColor: [
            '#008d93',
            '#45c490',
            '#ccc'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: gotValue
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });

  }

  useEffect(() => {
    if (props.courses) {
      if (showPieChart) {
        renderCourseChart(props.courses)
      }
      if (props.courses.courseData) {
        setCourseData(props.courses.courseData)
      }
      if (props.courses.tableData) {
        setTableData(props.courses.tableData)
      }
    }
  }, [props.courses])

  const courseChartDataSuccessCB = res => {
    setCourseData(res && res.results && res.results[0] && res.results[0].courses && res.results[0].courses.courseData);
    setTableData(res && res.results && res.results[0] && res.results[0].courses && res.results[0].courses.tableData);
    setInProgress(false);
    if (showPieChart) {
      renderCourseChart(res && res.results && res.results[0] && res.results[0].courses);
    }
  }
  const courseChartDataFailureCB = err => {
    setInProgress(false);
    setCourseData([]);
    setTableData([]);
    props.showSnackBar({
      state: true,
      message: 'Sorry something went wrong while fetching Top 5 External Courses!',
      type: 'error'
    })
  }

  const handleChangeOption = (event) => {
    setInProgress(true);
    setSelectedRange(event.target.value)
    let payload = ''
    if (event.target.value && event.target.value === 'YTD') {
      payload = {}
    } else {
      payload = {
        course_range: event.target.value
      }
    }
    getDashboardData(payload, courseChartDataSuccessCB, courseChartDataFailureCB)
  }


  return (
    <Paper className={mobileView ? classes.courseChartWrapperMob : classes.courseChartWrapper}>
      <Grid container>
        {showPieChart && <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className={classes.courseChart}>
            <Typography variant="h6" gutterBottom>
              My External Courses
            </Typography>
            {inProgress ? (
              <PieChartLoader />
            ) : (
              <React.Fragment>
                <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                  {courseData && courseData.map((row, key) => (
                    <li key={key} style={{ marginRight: 10 }}>
                      <div className={classes.legend}>
                        <span className={classes.courseChartLegendBox} style={{ backgroundColor: colorArray[key] }}></span>
                        <span>{row.label} ({row.value})</span>
                      </div>
                    </li>
                  ))}
                </ul>
                <canvas id="courseChart1" aria-label="External Courses Chart" role="img" ></canvas>
              </React.Fragment>
            )}
          </div>
        </Grid>}
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '-6px' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ width: '100%', textAlign: 'left', marginTop: '1px' }}>
              Top 5 External Courses
            </Typography>
            <div className={classes.mtdCtr}>
              <TextField
                className={classes.mtd}
                id="progress"
                select
                classes={{ outlined: "padding:1px" }}
                margin="dense"
                value={selectedRange}
                onChange={handleChangeOption}
                name="Progress"
                variant="outlined"
                disabled={props.inProgress || inProgress}
              >
                {listOfSelection && listOfSelection.map((option) => (
                  <MenuItem style={{ padding: '1px' }} key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <div className={classes.courseChartDataWrapper}>
            <TableContainer >
              <Table className={classes.table} aria-label="Top 5 External Courses">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Course</TableCell>
                    <TableCell align="right" className={classes.courseChartNumberCell} style={{ fontWeight: '700', fontSize: '1rem', maxWidth: '87px' }}>Purchased - #</TableCell>
                  </TableRow>
                </TableHead>
                {props.inProgress || inProgress ? (
                  <Typography style={{ color: '#fff', marginTop: '14px', fontSize: '16px', fontWeight: 500 }}>Loading...</Typography>
                ) : (
                  <TableBody>
                    {tableData.length ? (
                      <React.Fragment>
                        {tableData.map((row, key) => (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                              {row && row.course && row.course.length && row.course}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right" className={classes.courseChartNumberCell}>
                              {row.numOfTimesBought}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </Paper >
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CoursesChart);