import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './numberBoxes.css'
import { getUSerType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  data: {
    fontSize: '1.5rem',
    fontWeight: 500,
    marginTop: '-19px'
  },
  label: {
    fontWeight: 500,
    fontSize: '12px',
    marginTop: '-2px',
    width: '109px',
    marginLeft: '16px'
  }
}));

export default function NumberBoxes(props) {
  const classes = useStyles();
  const numberBoxes = props.numberBoxes ? props.numberBoxes : [];
  const mobileView = useMediaQuery('(max-width:1400px)');
  const userType = getUSerType();
  return (
    <div className={classes.root}>
      {numberBoxes && numberBoxes.length ? (
        <React.Fragment>
          <Grid container spacing={2} style={{ justifyContent: !mobileView ? 'center' : '' }}>
            <ul class="dashBoardNumbers">
              {numberBoxes && numberBoxes.slice(0, 6).map((row, key) => (
                <li style={{ paddingTop: '17px', color: '#fff' }} key={key}>
                  <div className={classes.data} aria-hidden="true">
                    {row && row.value}
                  </div>
                  <div className={classes.label} aria-hidden="true">
                    {row && row.label && row.label}
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
          {userType && userType === 'admin' ? (
            <Grid container spacing={2} style={{ justifyContent: !mobileView ? 'center' : '' }} >
              <ul class="dashBoardNumbers">
                {numberBoxes && numberBoxes.slice(6, numberBoxes.length).map((row, key) => (
                  <li style={{ paddingTop: '17px', color: '#fff' }} key={key}>
                    <div className={classes.data} aria-hidden="true">
                      {row && row.value}
                    </div>
                    <div className={classes.label} aria-hidden="true">
                      {row && row.label && row.label}
                    </div>
                  </li>
                ))}
              </ul>
            </Grid>
          ) : null}
        </React.Fragment>
      ) : (
        <p style={{ fontSize: '18px', color: '#999' }}>No data found</p>
      )}
    </div>
  );
}