import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chart from 'chart.js';
import Paper from '@material-ui/core/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import NumberBoxes from './numberBoxes';
import CoursesChart from './coursesChart';
import TrainingChart from './trainingChart';
import RewardsChart from './rewardsChart';
import PendingApprovals from './pendingApprovals';
import UniqueSearchTerms from './uniqueSearchTerms';
import CoursesByTeamChart from './coursesByTeamChart';
import AvgSpendingTimeChart from './avgSpendingChart';
import PolicyChart from './policyChart';
import { getDashboardData } from '../../store/Dashboard/actionCreator';
import { getUSerType, getUSerDetails, isAdminModeActive } from '../../Helpers/basic';
import MandateCourseChart from './mandateCourseChart';
import { fetchMandateCoursesData, fetchDeptAvgSpentChart } from '../../store/Dashboard/actionCreator';
import { colorStyles } from '../../styles/style';
import { getTenant } from '../../Configs/tenantConfig';
import NumbersLoader from '../Common/SkeletonLoaders/numbersLoader';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { Typography } from '@material-ui/core';

const stDate = new Date(new Date().getFullYear(), 0, 1);


const useStyles = theme => ({
  container: {
    padding: '2rem',
    backgroundColor: colorStyles.background,
  },
  paper: {
    backgroundColor: colorStyles.background,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '10px 0 10px 0 ',
    display: 'inline-block',
    boxShadow: 'none',
    width: '100%'
  },
  paperPro: {
    backgroundColor: colorStyles.background,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '5px 15px 15px 15px',
    display: 'inline-block',
    borderRadius: '8px',
    boxShadow: 'none',
    width: '100%'
  },
  managerData: {
    backgroundColor: colorStyles.background,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '10px',
    display: 'inline-block',
    marginTop: '10px',
    boxShadow: 'none',
    width: '100%'
  },
  employeeData: {
    backgroundColor: colorStyles.background,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '10px',
    display: 'inline-block',
    marginTop: '20px',
    boxShadow: 'none',
    width: '100%'
  },
  datePicker: {
    border: '1px solid #999',
    backgroundColor: colorStyles.background,
    borderRadius: '5px',
    width: 120,
    padding: '0.8rem',
    margin: '0.5rem 0.5rem 0 0'
  },
  managerSecondLayer: {
    marginTop: '20px',
  },
  uniqueChart: {
    marginRight: '200px'
  },
  firstLayerAdmin: {
    marginLeft: '60px'
  },
  thirdLayerAdmin: {
    marginTop: '30px'
  },
  icnBtn: {
    padding: '5px',
    borderRadius: '5px'
  },
  lnk: {
    fontSize: '12px',
    color: colorStyles.appTextColor
  },
  lnkCtr: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.5rem'
  },
  mtd: {
    fontSize: '10px',
    width: '110px',
    marginRight: '5px'
  },
  dateRangeText: {
    color: colorStyles.appTextColor,
    margin: '1rem',
    fontSize: '14px',
    width: '100%',
    textAlign: 'right',
    marginTop: '17px',
  },
  numbersWrapper: {
    padding: '16px 20px 21px 22px'
  },
  mainTitleWrap: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '-11px',
    color: '#2e8eec',
    marginBottom: '12px',
    fontFamily: 'Regular Patch,Regular Bold,Nunito Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'
  }
});

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  },
  {
    id: 3,
    name: 'WTD'
  },
  {
    id: 4,
    name: 'Custom'
  }
]
class Dashboard extends React.Component {
  state = {
    dashboardData: [],
    numberBoxData: [],
    inProgress: false,
    mandateCourseData: {},
    startDate: stDate,
    selectedRange: 'YTD',
    endDate: new Date(),
    totalEmployees: 0,
    totalProfiles: 0,
    numberBoxLoader: false,
    avgChratLoader: false,
    mandateLoader: false,
    //FOR HIDING THE CHART
    showAvgLearningChart: (getTenant().tenant === 'ce' || getTenant().shortName === 'DEMO')
  }

  componentDidMount() {
    this.loadDashBoardData()
    if (isAdminModeActive() || getUSerDetails().members > 0) {
      this.loadMandateCourseData()
      if (this.state.showAvgLearningChart) {
        this.fetchAvgUserSpentHours()
      }
    }
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }
  //MAIN API CALLBACKS
  successCallBack = res => {
    this.setState({
      dashboardData: res.results[0],
      numberBoxData: res && res.results && res.results[0] && res.results[0].numberBoxes && res.results[0].numberBoxes.length && res.results[0].numberBoxes,
      totalProfiles: res.results[0].TotalNoOFprofiles,
      totalEmployees: res.results[0].TotalNoofEmployees,
      inProgress: false,
      numberBoxLoader: false
    })
  }
  failureCallBack = err => {
    this.setState({
      totalProfiles: 0,
      totalEmployees: 0,
      dashboardData: [],
      numberBoxData: [],
      inProgress: false,
      numberBoxLoader: false
    })
    this.props.showSnackBar(
      {
        state: true,
        message: 'Something went wrong!. Please try again later.',
        type: 'error'
      }
    )
  }
  //DASHBORD DATA MAIN API
  loadDashBoardData = () => {
    this.setState({
      inProgress: true,
      //numberBoxLoader: true
    })
    getDashboardData({}, this.successCallBack, this.failureCallBack)
  }

  //FOR MANDATE CHART DATA API CALL
  loadMandateCourseData = () => {
    this.setState({
      mandateLoader: true
    })
    const payload = {
      range: 'YTD',
      value: new Date().getFullYear()
    }
    fetchMandateCoursesData(payload, this.mandateDataSuccessCB, this.mandateDataFailureCB)
  }
  mandateDataSuccessCB = res => {
    this.setState({
      mandateLoader: false,
      mandateCourseData: res && res.results && res.results
    })
  }
  mandateDataFailureCB = err => {
    this.setState({
      mandateLoader: false,
      mandateCourseData: {}
    })
  }

  //DATE RANGE SELECTION GREEN BOXES CALLBACKS
  successCB = res => {
    this.setState({
      inProgress: false,
      numberBoxLoader: false,
      numberBoxData: res && res.results && res.results[0] && res.results[0].numberBoxes && res.results[0].numberBoxes.length && res.results[0].numberBoxes,
    })
  }
  failureCB = err => {
    this.setState({
      inProgress: false,
      numberBoxLoader: false,
      numberBoxData: []
    })
  }
  //FOR DATE RANGE SELECTION API CALL
  loadNumbersByRange = () => {
    this.setState({
      numberBoxLoader: true
    })
    let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    let payload = { start_date: startDate, end_date: endDate, number_range: 'custom' };
    getDashboardData(payload, this.successCB, this.failureCB);
  }

  handleStartDateChange = (startDate) => {
    this.setState({ startDate: startDate }, () => {
      this.loadNumbersByRange();
    })
  }
  handleEndDateChange = (endDate) => {
    this.setState({ endDate: endDate }, () => {
      this.loadNumbersByRange();
    })
  }

  //FOR FETCHING AVERAGE TIME SPENT BY TEAMS CALLBACKS
  successAvgCB = res => {
    this.setState({
      avgChratLoader: false,
      avgUsersSpentData: res && res.results
    })
  }
  failureAvgCB = err => {
    this.setState({
      avgChratLoader: false,
      avgUsersSpentData: {}
    })
  }
  //FOR FETCHING AVG USERS SPENT HOURS
  fetchAvgUserSpentHours = () => {
    this.setState({
      avgChratLoader: true
    })
    const payload = {
      range: 'YTD',
      value: new Date().getFullYear()
    }
    fetchDeptAvgSpentChart(payload, this.successAvgCB, this.failureAvgCB)
  }

  handleChangeOption = (event) => {
    let payload = {};
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    if (event.target.value && event.target.value === 'YTD') {
      var first = new Date(new Date().getFullYear(), 0, 1);
      this.setState({ selectedRange: 'YTD', startDate: new Date(first), endDate: new Date() })
      payload = {}
    } else if (event.target.value && event.target.value === 'Custom') {
      this.setState({ selectedRange: 'Custom' })
    } else if (event.target.value && event.target.value === 'WTD') {
      var curr = new Date;
      var first = curr.getDate() - curr.getDay();
      var firstday = new Date(curr.setDate(first));
      let wkStartDate = moment(firstday).format("YYYY-MM-DD");
      payload = {
        start_date: wkStartDate,
        end_date: endDate,
        number_range: 'custom'
      }
      this.setState({ selectedRange: 'WTD', startDate: new Date(wkStartDate), endDate: new Date() })
    } else {
      let monStartDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
      payload = {
        start_date: monStartDate,
        end_date: endDate,
        number_range: 'custom'
      }
      this.setState({ selectedRange: 'MTD', startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), endDate: new Date() })
    }
    if (event.target.value !== 'Custom') {
      this.setState({
        numberBoxLoader: true
      })
      getDashboardData(payload, this.successCB, this.failureCB);
    }
  }

  render() {
    const { showAvgLearningChart, dashboardData, mandateCourseData, numberBoxData, selectedRange, avgUsersSpentData, inProgress, avgChratLoader, numberBoxLoader, mandateLoader, totalProfiles, totalEmployees } = this.state
    const { classes } = this.props
    const tenant = getTenant();
    return (
      <div className={classes.container}>
        <div id="mainH1Tag" tabIndex="-1" className="accessibility">
          <h1>My Organizational Dashboard</h1>
        </div>
        <a className="skip-main" href="#leftMenu">
          Back to main menu
        </a>
        <Typography className={classes.mainTitleWrap}>My Organizational Dashboard</Typography>
        <Paper className={classes.numbersWrapper}>
          {inProgress ? <NumbersLoader /> :
            <React.Fragment>
              <Grid container spacing={2} >
                {(isAdminModeActive() || getUSerDetails().members > 0) && numberBoxData && numberBoxData.length ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ display: "flex", margin: "-18px 1px 0px 0px" }}>
                        <Grid container>
                          {selectedRange === 'Custom' ? (
                            <React.Fragment>
                              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}></Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{ zIndex: 100 }}>
                                <DatePicker
                                  aria-label="select start date"
                                  className={classes.datePicker}
                                  selected={this.state.startDate}
                                  maxDate={new Date()}
                                  onChange={date => { this.handleStartDateChange(date) }}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Click to select a date"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{ zIndex: 100 }}>
                                <DatePicker
                                  aria-label="select end date"
                                  className={classes.datePicker}
                                  selected={this.state.endDate}
                                  minDate={this.state.startDate}
                                  maxDate={new Date()}
                                  onChange={date => { this.handleEndDateChange(date) }}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Click to select a date"
                                />
                              </Grid>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className={classes.dateRangeText}>{moment(this.state.startDate).format("DD-MMM-YY") + ' to ' + moment(this.state.endDate).format("DD-MMM-YY")}</div>
                            </React.Fragment>
                          )}
                        </Grid>
                        <TextField
                          className={classes.mtd}
                          id="progress"
                          select
                          classes={{ outlined: "padding:1px" }}
                          margin="dense"
                          value={this.state.selectedRange}
                          onChange={(e) => { this.handleChangeOption(e) }}
                          name="Progress"
                          variant="outlined"
                          disabled={numberBoxLoader}
                        >
                          {listOfSelection && listOfSelection.map((option) => (
                            <MenuItem style={{ padding: '1px', marginLeft: '5px', marginRight: '5px' }} key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : null}
                <div style={{ display: 'flex', marginTop: '-4px', width: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {numberBoxLoader ? (
                        <NumbersLoader />
                      ) : (
                        <NumberBoxes totalEmpCount={totalEmployees} totalEmpProfiles={totalProfiles} numberBoxes={numberBoxData} range={selectedRange} />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </React.Fragment>
          }
        </Paper>
        {isAdminModeActive() ? (
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CoursesByTeamChart Chart={Chart} coursesByTeam={dashboardData.courseCountsOfTeam} inProgress={inProgress} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MandateCourseChart showAvgLearningChart={showAvgLearningChart} Chart={Chart} mandateCourseData={mandateCourseData} mandateLoader={mandateLoader} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TrainingChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PolicyChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PendingApprovals
                  pendingApprovals={dashboardData.pendingApprovals}
                  onApprovalStatusChange={this.loadDashBoardData}
                  inProgress={inProgress}
                  history={this.props.history}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <UniqueSearchTerms uniqueSearchTerms={dashboardData.uniqueSearchTerms} inProgress={inProgress} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CoursesChart Chart={Chart} courses={dashboardData.courses} style={{ marginTop: 20 }} inProgress={inProgress} />
              </Grid>
              {showAvgLearningChart && <Grid item xs={12} sm={12} md={6} lg={6}>
                <AvgSpendingTimeChart Chart={Chart} history={this.props.history} avgUsersSpentData={avgUsersSpentData} avgChratLoader={avgChratLoader} />
              </Grid>}
              {tenant.showRedeemMenuRewardsGraph ? (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <RewardsChart Chart={Chart} rewards={dashboardData.rewards} style={{ marginTop: 20 }} />
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        ) : null}
        {getUSerType() === 'manager' ? (
          <React.Fragment>
            <Paper className={classes.managerData} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CoursesByTeamChart Chart={Chart} coursesByTeam={dashboardData.courseCountsOfTeam} inProgress={inProgress} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <MandateCourseChart showAvgLearningChart={showAvgLearningChart} Chart={Chart} mandateCourseData={mandateCourseData} mandateLoader={mandateLoader} />
                </Grid>
                {getUSerDetails().has_create_docs_access ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TrainingChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <PolicyChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
                    </Grid>
                  </React.Fragment>
                ) : null}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <PendingApprovals
                    pendingApprovals={dashboardData.pendingApprovals}
                    onApprovalStatusChange={this.loadDashBoardData}
                    inProgress={inProgress}
                    history={this.props.history}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <UniqueSearchTerms uniqueSearchTerms={dashboardData.uniqueSearchTerms} inProgress={inProgress} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CoursesChart Chart={Chart} courses={dashboardData.courses} style={{ marginTop: 20 }} inProgress={inProgress} />
                </Grid>
                {showAvgLearningChart && <Grid item xs={12} sm={12} md={6} lg={6}>
                  <AvgSpendingTimeChart Chart={Chart} history={this.props.history} avgUsersSpentData={avgUsersSpentData} avgChratLoader={avgChratLoader} />
                </Grid>}
              </Grid>
            </Paper>
          </React.Fragment>
        ) : null}
        {getUSerType() === 'employee' ? (
          <React.Fragment>
            <Paper className={classes.employeeData} >
              <Grid container spacing={2}>
                {getUSerDetails().has_create_docs_access ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TrainingChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <PolicyChart Chart={Chart} style={{ marginTop: 20 }} history={this.props.history} />
                    </Grid>
                  </React.Fragment>
                ) : null}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <UniqueSearchTerms uniqueSearchTerms={dashboardData.uniqueSearchTerms} inProgress={inProgress} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CoursesChart Chart={Chart} courses={dashboardData.courses} style={{ marginTop: 20 }} inProgress={inProgress} />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        ) : null}
      </div >
    );
  }
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(withStyles(useStyles)(Dashboard));
