import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import myAthinaLogo from '../../static/myAthinaLogo.png';
import AlertMessages from '../Common/AlertMessages'
import { getTenant } from '../../Configs/tenantConfig';

const useStyles = theme => ({
  paper: {
    marginTop: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '-4px',
    marginLeft: 10

  },
  logoImg: {
      width: '43px'
  },
   buttonProgress: {
    color: '#2e8eec'
  }
});
class InvalidLogin extends React.Component {
    state = {
        inProgress: false,
        error: {
            status: true
        },
      }
      componentDidMount() {
        window.FreshworksWidget('hide')
      }
      getTenantLogo = () => {
        const tenant = getTenant()
        switch(tenant.shortName) {
          case 'caseys':
            return (<img src={tenant.logo} style={{ maxWidth: 110 }} alt={tenant.tenant}/>)
          default:
            return (<img src={tenant.logo} style={{ maxWidth: 79 }} alt={tenant.tenant}/>)
        }
      }
      render() {
        const { classes, message } = this.props
        const { error } = this.state
        const tenant = getTenant()
        return (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">

                {this.getTenantLogo()}
                <img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina"/>
              </Typography>
              <Typography style={{ margin: 10 }}>
                Welcome to<br/>{window.location.host}
              </Typography>
              <AlertMessages status={error.status ? 'error' : 'success'} message={message}/>
            </div>
        </Container>
    )
    }
}
const mapStateToProps = state => {
    return {
      authDetails: state.authReducer.authDetails,
    }
  }
  export default connect(
    mapStateToProps, {}
  )(withStyles(useStyles)(InvalidLogin));
