import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SchoolIcon from '@material-ui/icons/School'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TimelineIcon from '@material-ui/icons/Timeline';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EventIcon from '@material-ui/icons/Event';
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { find } from 'lodash';
import MyAthinaIcon from '../../static/myathina-icon.png';
import { getUSerDetails, getUSerType, isAdminModeActive, getUserActiveMenus } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import treeIcon from '../../static/treeIcon.png'
import BudgetIcon from '../../static/budgetIcon.png'
import MyBudgetIcon from '../../static/mybudgetIcon.png'

export const getDepartmentMenus = () => {
    const tenantInfo = getTenant()
    switch (tenantInfo.tenant) {
        case 'ceProd':
        case 'ce':
            return [
                {
                    departmentId: 1,
                    name: 'Tech',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 20, 21]
                },
                {
                    departmentId: 2,
                    name: 'Front-end Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 3,
                    name: 'Backend Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 4,
                    name: 'Product Team',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 5,
                    name: 'Machine Learning Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 6,
                    name: 'Content Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 7,
                    name: 'Digital Marketing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 8,
                    name: 'Admin',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 20, 21]
                },
                {
                    departmentId: 9,
                    name: 'CEO',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 21]
                },
                {
                    departmentId: 11,
                    name: 'Chief Learning Officer',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 12,
                    name: 'Partner Engagements',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 13,
                    name: 'Project Team',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                }
            ]
        case 'caseys':
            return [
                {
                    departmentId: 1,
                    name: 'Store',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 21, 3, 6, 7, 8, 9, 10, 11, 15, 16, 20, 21]
                },
                {
                    departmentId: 2,
                    name: 'Store Employee',
                    defaultLandingPage: '/myevents',
                    activeMenus: [1, 21, 3, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
            ]
        case 'freo':
            return [
                {
                    departmentId: 3,
                    name: 'Engineering',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 4,
                    name: 'Marketing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 5,
                    name: 'Customer Success',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 6,
                    name: 'Data',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 7,
                    name: 'Product',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 8,
                    name: 'Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 9,
                    name: 'Operations',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 10,
                    name: 'BNPL',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 11,
                    name: 'Risk',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 12,
                    name: 'Collections',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 14,
                    name: 'Strategic Partnerships',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 15,
                    name: 'Revenue',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 16,
                    name: 'Finance',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 21]
                },
                {
                    departmentId: 17,
                    name: 'HR',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 14, 18, 21]
                },

            ]
        case 'demo':
            return [
                {
                    departmentId: 23,
                    name: 'Leadership',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 41,
                    name: 'Learning & Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 31,
                    name: 'Aleks Delivery Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 8,
                    name: 'Editorial Practices - Learning',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                }
            ]
        case 'ansrsource_dev':
            return [
                {
                    departmentId: 11,
                    name: 'Facilities & Admin',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 12,
                    name: 'Finance',
                    defaultLandingPage: '/verifyapprovals',
                    activeMenus: [17, 10, 21]
                }
            ]
        case 'ansrsource':
            return [
                {
                    departmentId: 1,
                    name: 'Facilities & Admin',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 3,
                    name: 'Vendor Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 4,
                    name: 'Editorial Practices – Qual Content & Supplements',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 7,
                    name: 'Editorial Practices – F&A Content & ESM',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 8,
                    name: 'Editorial Practices - Learning',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 17, 21]
                },
                {
                    departmentId: 9,
                    name: 'Editorial Practices – ACFA',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 21]
                },
                {
                    departmentId: 10,
                    name: 'Instructional Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 21]
                },
                {
                    departmentId: 11,
                    name: 'Quality Control',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 12,
                    name: 'Product Engineering',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 14,
                    name: 'Editorial Practices – Engineering and Science',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 15,
                    name: 'Graphic Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 16,
                    name: 'Business Excellence',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 17,
                    name: 'Marketing & Communication',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 18,
                    name: 'Human Resources',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 19,
                    name: 'eLearning UAT',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 20,
                    name: 'Copy Editing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 21,
                    name: 'eLearning Production',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 22,
                    name: 'Content Programming',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 23,
                    name: 'Leadership',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 24,
                    name: 'Learning & Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 27,
                    name: 'Editorial Delivery Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 28,
                    name: 'Learning Practice Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 29,
                    name: 'Learning Delivery Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 30,
                    name: 'Editorial Practice Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 31,
                    name: 'Aleks Delivery Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 33,
                    name: 'US Learning Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 34,
                    name: 'Partner Engagements',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 35,
                    name: 'Product Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 36,
                    name: 'US Delivery Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 37,
                    name: 'Learning Internal',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 38,
                    name: 'Finance',
                    defaultLandingPage: '/verifyapprovals',
                    activeMenus: [17, 10, 21]
                },
                {
                    departmentId: 39,
                    name: 'Learning Copy Editing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 40,
                    name: 'US Instructional Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
            ]
        case 'sandbox':
            return [
                {
                    departmentId: 1,
                    name: 'Tech',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 2,
                    name: 'Front-end Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 3,
                    name: 'Backend Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 4,
                    name: 'Product Team',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 5,
                    name: 'Machine Learning Development',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 6,
                    name: 'Content Management',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 7,
                    name: 'Digital Marketing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 8,
                    name: 'Admin',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 17, 21]
                },
                {
                    departmentId: 9,
                    name: 'CEO',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16, 17, 21]
                },
                {
                    departmentId: 11,
                    name: 'Chief Learning Officer',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 12,
                    name: 'Partner Engagements',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 13,
                    name: 'Project Team',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
            ]
        case 'falabella':
            return [
                {
                    departmentId: 1,
                    name: 'Ecommerce',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 3,
                    name: 'FME',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 4,
                    name: 'DRB Browse',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 5,
                    name: 'Commerce',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 6,
                    name: 'Retail Experiences',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 7,
                    name: 'Communications',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 8,
                    name: 'Support',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 9,
                    name: 'IT Infra',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 10,
                    name: 'Marketplace',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 11,
                    name: 'Linio Mexico - Marketing',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 12,
                    name: 'Logistics',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 13,
                    name: 'Human Resources',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 17, 21]
                },
                {
                    departmentId: 14,
                    name: 'Infrastructure Operations',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 15,
                    name: 'Facilities',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 16,
                    name: 'Data & Analytics',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 17,
                    name: 'POS',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 18,
                    name: 'Express Delivery',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 19,
                    name: 'WMS',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 20,
                    name: 'Business & Strategy',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 21,
                    name: 'OMS',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 22,
                    name: 'FbF',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 23,
                    name: 'Promise Engine',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 24,
                    name: 'Falabella Retail',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 25,
                    name: 'Finance',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 26,
                    name: 'Support Staff',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 27,
                    name: 'DRB Commerce',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 28,
                    name: 'Corporate Services',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 29,
                    name: 'Seller Experience & Platform',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 30,
                    name: 'SIS',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 31,
                    name: 'Site Reliability Engineering',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 32,
                    name: 'Corporate Architecture',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 33,
                    name: 'Digital Retail',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 38,
                    name: 'Mall Plaza',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 40,
                    name: 'Sodimac B2B',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 41,
                    name: 'Fazil',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 42,
                    name: 'Rex-Fazil',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 43,
                    name: 'Control Trafico',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 44,
                    name: 'Engineer',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 45,
                    name: 'Product Manager',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 46,
                    name: 'Lead Engineer',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 47,
                    name: 'Senior Engineer',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 48,
                    name: 'Mobile',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 49,
                    name: 'Mobile QA',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 50,
                    name: 'Mobile QA',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 51,
                    name: 'QA Automation',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                },
                {
                    departmentId: 52,
                    name: 'Stores',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 17, 21]
                }
            ]
        case 'prep':
            return [
                {
                    departmentId: 1,
                    name: 'CE PREP',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 3, 7, 8, 9, 11, 13, 15, 16]
                },
                {
                    departmentId: 2,
                    name: 'PREP CLIENT',
                    defaultLandingPage: '/prepclientmaterials',
                    // activeMenus: [13]
                    activeMenus: [3, 13]
                },
                {
                    departmentId: 4,
                    name: 'VTU',
                    defaultLandingPage: '/prepclientmaterials',
                    activeMenus: [3, 13]
                },
            ]
        case 'goniyo':
        case 'testgoniyo':
        case 'lowes':
        case 'ansr':
        default:
            return [
                {
                    departmentId: 34,
                    name: 'Design UX',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 14, 11, 15, 16, 21]
                },
                {
                    departmentId: 35,
                    name: 'Human Resource',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 36,
                    name: 'Data Engineering',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 4,
                    name: 'Tech',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 10,
                    name: 'CS',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 7,
                    name: 'R&C',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 1,
                    name: 'CEO',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 3,
                    name: 'CTO',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 20,
                    name: 'Founders office',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 17,
                    name: 'Tech P',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 18,
                    name: 'Product P',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 12,
                    name: 'Info Sec',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 19,
                    name: 'Info Tech',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 15,
                    name: 'Legal',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 16,
                    name: 'Admin',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 21,
                    name: 'Department Title',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 23,
                    name: 'Sales',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 25,
                    name: 'Blue',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 24,
                    name: 'Office IT',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 11,
                    name: 'Finance',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 17, 21]
                },
                {
                    departmentId: 22,
                    name: 'White',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 13,
                    name: 'IT',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 14,
                    name: 'Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 6,
                    name: 'HR',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 33,
                    name: 'Operations',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 8,
                    name: 'Global Sales',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 30,
                    name: 'Business',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 28,
                    name: 'User Experience and Design',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 32,
                    name: 'Customer Support and Operations',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 27,
                    name: 'Founders Office',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 5,
                    name: 'Product',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 31,
                    name: 'Customer operations',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 26,
                    name: 'Bharat',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 37,
                    name: 'Card Operations',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 38,
                    name: 'CXO',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                },
                {
                    departmentId: 9,
                    name: 'Marketing',
                    defaultLandingPage: '/mycourses',
                    activeMenus: [3, 8, 9, 10, 21]
                },
                {
                    departmentId: 29,
                    name: 'Niyo Money',
                    defaultLandingPage: '/app',
                    activeMenus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 15, 16, 21]
                }
            ]
    }
}

//FOR VERIFY APPROVALS MENU ACCESS
const getApprovalsMenuAccess = (tenant) => {
    if (tenant.shortName === 'FB' || tenant.shortName === 'sandbox' || tenant.shortName === 'ANSR' || tenant.shortName === 'Niyo') {
        if (getUSerDetails().is_leader) {
            return true
        } else if (getUSerDetails().is_verified_tab_allowed) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

//FOR APPROVAL REQUEST MENU ACCESS
const getApprovalReqMenu = (tenant) => {
    if (tenant.shortName === 'FB' || tenant.shortName === 'sandbox' || tenant.shortName === 'Niyo') {
        if (getUSerDetails().is_hr) {
            return true
        } else if (getUSerDetails().members > 0) {
            return true
        } else {
            return false
        }
    } else {
        return getUSerDetails().members > 0
    }
}

const getMenuList = () => {
    const tenant = getTenant()
    // 1,7,8,9,11,13
    switch (tenant.tenant) {
        case 'prep':
            return [
                {
                    id: 1,
                    menu: 'Learning Dashboard',
                    icon: <DashboardIcon />,
                    path: '/app',
                    enableView: true,
                    category: 'My Organization'
                },
                {
                    id: 7,
                    menu: 'My Team',
                    icon: <GroupIcon />,
                    path: '/members',
                    enableView: getUSerDetails().members > 0,
                    category: 'My Organization'
                },
                {
                    id: 11,
                    menu: 'Author Courses',
                    icon: <LocalLibraryIcon />,
                    enableView: getUSerType() === 'admin',
                    category: 'Me'
                    // enableView: true
                },
                {
                    id: 3,
                    menu: 'My Courses',
                    icon: <SchoolIcon />,
                    path: '/mycourses',
                    enableView: true,
                    category: 'Me'
                },
                {
                    id: 13,
                    menu: 'PREP Tutorials',
                    icon: <MenuBookIcon />,
                    path: '/prepclientmaterials',
                    enableView: (tenant.tenant === 'prep') ? true : false,
                    category: 'Me'
                },
                {
                    id: 9,
                    menu: 'Docs Library',
                    icon: <InsertDriveFile />,
                    path: '/mydocs',
                    enableView: true,
                    category: 'Me'
                },
            ]
        default:
            return [
                {
                    id: 1,
                    menu: 'Learning Dashboard',
                    icon: <DashboardIcon />,
                    path: '/app',
                    enableView: true,
                    category: 'My Organization'
                },
                {
                    id: 14,
                    menu: 'Skill Map',
                    icon: <img src={treeIcon} alt="Skill Map" aria-hidden="true" style={{ height: '24px' }} />,
                    path: '/skillmap',
                    enableView: getUSerType() === 'admin' && (tenant.shortName === 'CE' || tenant.shortName === 'sandbox' || tenant.shortName === 'Niyo' || tenant.shortName === 'ANSR' || tenant.shortName === 'FB' || tenant.shortName === 'DEMO' || tenant.shortName === 'freo'),
                    category: 'My Organization'
                },
                {
                    id: 19,
                    menu: 'Skill Index',
                    icon: <TimelineIcon />,
                    path: '/skillindex',
                    enableView: getUSerType() === 'admin' && (tenant.tenant === 'ce' || tenant.shortName === 'DEMO'),
                    category: 'My Organization'
                },
                {
                    id: 15,
                    menu: 'Manage Budget',
                    icon: <img src={BudgetIcon} alt="Manage Budget" aria-hidden="true" style={{ height: '24px' }} />,
                    path: '/budget',
                    enableView: getUSerType() === 'admin' && tenant.allocate_budget && (tenant.tenant === 'ce' || tenant.shortName === 'DEMO'),
                    category: 'My Organization'
                },
                {
                    id: 7,
                    menu: 'My Team',
                    icon: <GroupIcon />,
                    path: '/members',
                    enableView: getUSerDetails().members > 0,
                    category: 'My Organization'
                },
                {
                    id: 2,
                    menu: 'Search',
                    icon: <img src={MyAthinaIcon} style={{ marginLeft: '3px', width: '18px' }} alt="myathina" aria-hidden="true" />,
                    path: '/search',
                    enableView: true,
                    category: 'Me'
                },
                {
                    id: 3,
                    menu: 'My Courses',
                    icon: <SchoolIcon />,
                    path: '/mycourses',
                    enableView: true,
                    category: 'Me'
                },
                {
                    id: 4,
                    menu: 'My Learning Path',
                    icon: <EmojiPeopleIcon />,
                    path: '/mypath',
                    enableView: true,
                    category: 'Me'
                },
                {
                    id: 5,
                    menu: 'Redeem',
                    icon: <AttachMoneyIcon />,
                    path: '/rewards',
                    enableView: tenant.showRedeemMenuRewardsGraph && getUSerType() === 'admin',
                    category: 'My Organization'
                },
                {
                    id: 16,
                    menu: 'My Budget',
                    icon: <img src={MyBudgetIcon} alt="My Budget" aria-hidden="true" style={{ height: '23px', marginTop: '-6px' }} />,
                    path: '/mybudget',
                    enableView: tenant.allocate_budget && (tenant.tenant === 'ce' || tenant.shortName === 'DEMO'),
                    category: 'Me'
                },
                {
                    id: 6,
                    menu: 'Approval Requests',
                    icon: <PlaylistAddCheckIcon />,
                    path: '/approvalrequests',
                    enableView: getApprovalReqMenu(tenant),
                    category: 'Me'
                },
                {
                    id: 11,
                    menu: 'Author Courses',
                    icon: <LocalLibraryIcon />,
                    enableView: tenant.showAuthorCoursesMenu && (getUSerType() === 'admin' || getUSerDetails().has_authorcourses_access),
                    category: 'Me'
                },
                {
                    id: 12,
                    menu: 'PREP Materials',
                    icon: <MenuBookIcon />,
                    path: '/prepmaterials',
                    enableView: (tenant.tenant === 'prep') ? true : false,
                    category: 'Me'
                },
                {
                    id: 13,
                    menu: 'PREP Tutorials',
                    icon: <MenuBookIcon />,
                    path: '/prepclientmaterials',
                    enableView: (tenant.tenant === 'prep') ? true : false,
                    category: 'Me'
                },
                {
                    id: 17,
                    menu: 'Verify Approvals',
                    icon: <VerifiedUserIcon />,
                    path: '/verifyapprovals',
                    enableView: getApprovalsMenuAccess(tenant),
                    category: 'Me'
                },
                {
                    id: 18,
                    menu: 'Settings',
                    icon: <SettingsIcon />,
                    path: '/appconfig',
                    enableView: tenant.showOnboardSettingMenu && getUSerType() === 'admin' || getUSerDetails().has_onboadring_access,
                    category: 'My Organization'
                },
                {
                    id: 20,
                    menu: 'My Feed',
                    icon: <DynamicFeedIcon />,
                    path: '/myfeed',
                    enableView: false,
                    category: 'My Organization'
                },
                {
                    id: 21,
                    menu: 'My Events',
                    icon: <EventIcon />,
                    path: '/myevents',
                    enableView: (tenant.tenant === 'prep') ? false : true,
                    category: 'Me'
                },
                {
                    id: 10,
                    menu: 'My Certificates',
                    icon: <DescriptionIcon />,
                    path: '/mycertificates',
                    enableView: (tenant.tenant === 'prep') ? false : true,
                    category: 'Me'
                },
                {
                    id: 8,
                    menu: 'Course Library',
                    icon: <SchoolIcon />,
                    path: '/learningmaterials',
                    enableView: tenant.showAuthorCoursesMenu,
                    category: 'Me'
                },
                {
                    id: 9,
                    menu: 'Docs Library',
                    icon: <InsertDriveFile />,
                    path: '/mydocs',
                    enableView: true,
                    category: 'Me'
                },
                {
                    id: 22,
                    menu: 'Assessments',
                    icon: <AssessmentIcon />,
                    path: '/assessments',
                    enableView: tenant.showExternalAssessments,
                    category: 'Me'
                }
            ]
    }
}

export const authenticatedMenus = () => {
    const userDepartment = getUSerDetails().department
    const departmentMenus = getDepartmentMenus()
    const userMenu = find(departmentMenus, { 'departmentId': userDepartment.id })
    let menuList = getMenuList()
    const tenantInfo = getTenant()
    if (tenantInfo.showAutomatedActiveMenus) {
        menuList = getUserActiveMenus()
        return menuList
    } else {
        if (isAdminModeActive()) {
            return menuList
        } else {
            let menus = []
            for (let i in menuList) {
                const menuId = menuList[i].id
                if (userMenu.activeMenus.indexOf(menuId) > -1) {
                    menus.push(menuList[i])
                }
            }
            return menus
        }
    }
}
