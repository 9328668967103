import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import { colorStyles } from '../../../styles/style';
const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '26px',
    width: '100%',
    height: '300px'
  },
  txtWrapper: {
    width: '100%',
    textAlign: 'justify'
  }
}))

export default function HorizontalChartLoader(props) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.txtWrapper}>
        <Skeleton count={props.from === 'skillmap' ? 2 : 1} widthRandomness={0} heightRandomness={0} height="40px" width="100%" style={{ marginBottom: '12px' }} color={colorStyles.skeletonLoaderBg} />
        <Skeleton count={1} widthRandomness={0} heightRandomness={0} height="40px" width="90%" style={{ marginBottom: '12px' }} color={colorStyles.skeletonLoaderBg} />
        <Skeleton count={1} widthRandomness={0} heightRandomness={0} height="40px" width="80%" style={{ marginBottom: '12px' }} color={colorStyles.skeletonLoaderBg} />
        <Skeleton count={1} widthRandomness={0} heightRandomness={0} height="40px" width="70%" style={{ marginBottom: '12px' }} color={colorStyles.skeletonLoaderBg} />
        <Skeleton count={1} widthRandomness={0} heightRandomness={0} height="40px" width="60%" style={{ marginBottom: '12px' }} color={colorStyles.skeletonLoaderBg} />
      </div>
    </div>
  )
}