import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changePassword } from '../../store/Auth/actionCreator';
import myAthinaLogo from '../../static/myAthinaLogo.png';
import AlertMessages from '../../Components/Common/AlertMessages';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { getTenant } from '../../Configs/tenantConfig';

const useStyles = theme => ({
  paper: {
    marginTop: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '-4px',
    marginLeft: 10

  },
  logoImg: {
      width: '43px'
  },
   buttonProgress: {
    color: '#2e8eec'
  },
  label: {
    width: '100%',
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
    color: '#777'
  }
});
class ChangePassword extends React.Component {
    state = {
        password: '',
        confirmPassword: '',
        inProgress: false,
        error: {
            status: false,
            errorMessage: ''
        },
      }
    componentDidMount() {
      window.FreshworksWidget('hide')
    }
    successCallBack = res => {
        this.setState({
            error: {
                status: false,
                errorMessage: 'success'
            },
            inProgress: false
        })
        this.props.history.push('/login')
        this.props.showSnackBar(
          {
            state: true,
            message: 'Password updated successfully!',
            type: 'success'
          }
        )
    }
    failureCallBack = error => {
      let errorMesage = 'Invalid credentials'
      /*
      if(error && error.data) {
        errorMesage = error.data.password[0]
      }
      */
        this.setState({
            error: {
                status: true,
                errorMessage: errorMesage
            },
            inProgress: false
        })
    }
    onChangePassword = event => {
      event.preventDefault()
      this.setState(
          {
              error: {
                  status: false,
                  errorMessage: ''
              },
              inProgress: false
          }
      )
      const password = document.getElementById('password').value
      const confirmPassword = document.getElementById('confirmPassword').value
      let error = {
          status: false,
          errorMessage: ''
      }
      if(password === '' || confirmPassword === '') {
        error.status = true
        error.errorMessage = 'Please enter required information.'
      }
      if(password !== confirmPassword) {
        error.status = true
        error.errorMessage = 'Confirm password is not matching with password.'
      }
      if(error.status) {
        this.setState({ error: error })
      } else {
        const payload = {
            password: password,
            token: this.props.match.params.userToken
        }
        this.setState({ error: error, inProgress: true })
        changePassword(payload, this.successCallBack, this.failureCallBack)
      }
    }
    getTenantLogo = () => {
      const tenant = getTenant()
      switch (tenant.shortName) {
        case 'ZS':
            return (<img src={tenant.logo} style={{ maxWidth: 150, backgroundColor: '#000' }} alt={tenant.tenant}/>)
        case 'FB':
            return (<img src={tenant.logo} style={{ maxWidth: 164 }} alt={tenant.tenant}/>)
        default:
          return (<img src={tenant.logo} style={{ maxWidth: 79 }} alt={tenant.tenant}/>)
      }
    }
    render() {
      const { classes } = this.props
      const { inProgress, error } = this.state
      const tenant = getTenant()
      return (
        <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {this.getTenantLogo()}
          {tenant.shortName === 'FB' ? (
            <img
              src={myAthinaLogo}
              className={classes.myAthinaLogo}
              alt="myathina"
              style={{ width: 146, marginTop: 25 }}
            />
          ) : (
            <img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina"/>
          )}
        </Typography>
          {error.status ? (
              <div style={{marginTop: '20px',width: '90%'}}>
                  <AlertMessages status={error.status ? 'error' : 'success'} message={error.errorMessage} />
              </div>
          ) : null}
          <Typography component="h2" variant="h6" className={classes.label}>Reset password</Typography>
          <form className={classes.form} noValidate onSubmit={this.onChangePassword} autoComplete="off">
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
          />
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New password"
              type="password"
              id="confirmPassword"
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.onChangePassword}
              disabled={inProgress}
          >
              {inProgress && (
              <CircularProgress size={25} className={classes.buttonProgress} />
          )}
              Change password
          </Button>
          </form>
      </div>
      </Container>
  );
  }
}
const mapStateToProps = state => {
    return {
      authDetails: state.authReducer.authDetails,
    }
  }
  export default connect(
    mapStateToProps, { showSnackBar }
  )(withStyles(useStyles)(ChangePassword));
