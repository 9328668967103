const recommendInfo = {
    courseInfo: {},
    dialogOpen: false,
    membersList: [],
    teamList: []
}
export default (state = recommendInfo, action) => {
    switch (action.type) {
        case 'SHOW_RECOMMEND_DIALOG':
            return {
                courseInfo: action.payload.courseInfo,
                dialogOpen: true,
                membersList: action.payload.membersList,
                teamList: action.payload.teamList
            }
        case 'CLOSE_RECOMMEND_DIALOG':
        default:
            return recommendInfo
    }
}


