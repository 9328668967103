import { useMediaQuery } from '@material-ui/core'

export function responsiveRender(props) {
  const maxWidthLessThan700 = useMediaQuery(props.mediaCheckStr);
  return (maxWidthLessThan700 ? props.componentToRender : null)
}
export function responsiveAlternateRender(props) {
  const maxWidthLessThan700 = useMediaQuery(props.mediaCheckStr);
  return (maxWidthLessThan700 ? props.componentToRender : props.alternateComponentToRender)
}
