import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress'
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Pagination from './pagination';
import { fetchDeptAvgMemList } from '../../store/Dashboard/actionCreator';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles(theme => ({
  table: {
    height: 'auto',
    marginBottom: '20px'
  },
  tableHeadingCell: {
    padding: '12px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#2e8eec',
  },
  tableCell: {
    padding: "12px"
  },
  titleWrapper: {
    color: '#2e8eec'
  },
  buttonProgress: {
    color: '#2e8eec'
  }
}));

function MemberList(props) {
  const classes = useStyles();
  const departmentData = props && props.data && props.data.labelData;
  const openDialog = props && props.data && props.data.popup ? true : false
  const rowsPerPage = 10;
  const offset = 0;
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [inProgress, setInProgress] = useState(true);

  const successFetchData = res => {
    setInProgress(false);
    setTableData(res && res.results);
    setTotalCount(res && res.total_count);
  }
  const failureFetchData = err => {
    setInProgress(false);
    setTableData([]);
    setTotalCount(0);
    props.showSnackBar({
      state: true,
      message: 'Sorry something went wrong while fetching Members data!',
      type: 'error'
    })
  }

  useEffect(() => {
    setInProgress(true);
    const payload = {
      offset: offset,
      number: 10,
      domain_id: departmentData.domain_id,
      status: true
    }
    fetchDeptAvgMemList(payload, successFetchData, failureFetchData)
  }, [departmentData.id])

  const onPagination = pageNum => {
    setPage(pageNum);
    setInProgress(true)
    const payload = {
      offset: pageNum * rowsPerPage,
      number: rowsPerPage,
      domain_id: departmentData.domain_id,
      status: true
    }
    fetchDeptAvgMemList(payload, successFetchData, failureFetchData)
  }

  const onTableCloseClick = () => {
    props.closeAveSpentMemTable();
  }

  return (
    <Dialog
      onClose={onTableCloseClick}
      aria-labelledby="Team members average spent distribution table"
      open={openDialog}
      maxWidth={'md'}
    >
      <DialogTitle className={classes.titleWrapper}>{`Total Learning Hours Spent by "${departmentData.label}" Team`}</DialogTitle>
      <DialogContent>
        <Table className={classes.table} aria-label="Department Level total hours spent team members table">
          <TableHead>
            <TableRow>
              <TableCell component="th" align="center" className={classes.tableHeadingCell}>Name</TableCell>
              <TableCell component="th" align="center" className={classes.tableHeadingCell}>Courses Completed</TableCell>
              <TableCell component="th" align="center" className={classes.tableHeadingCell}>Total Hours Spent</TableCell>
            </TableRow>
          </TableHead>
          {inProgress ? (
            <CircularProgress size={30} className={classes.buttonProgress} />
          ) : (
            <React.Fragment>
              {tableData && tableData.length ? (
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row" align="center" className={classes.tableCell}>
                        {row && row.name && row.name}
                      </TableCell>
                      <TableCell scope="row" align="center" className={classes.tableCell}>
                        {row && row.courses_completed}
                      </TableCell>
                      <TableCell scope="row" align="center" className={classes.tableCell}>
                        {`${row && row.hours_spent} Hours`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <div style={{ marginTop: '10px', marginBottom: '10px', fontSize: '13px' }}>No data found</div>
              )}
            </React.Fragment>
          )}
        </Table>
        {totalCount && totalCount > 10 && !inProgress ? (
          <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onTableCloseClick} variant="outlined" color="primary" style={{ textTransform: 'none' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { showSnackBar })(MemberList);