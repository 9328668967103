import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { groupBy } from 'lodash';
//LOCAL IMPORTS
import { colorStyles } from '../../styles/style';

const useStyles = makeStyles(theme => ({
  selectedMenu: {
    backgroundColor: colorStyles.leftMenuActive,
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: colorStyles.leftMenuHover,
    }
  },
  badge: {
    backgroundColor: '#2e8eec',
    marginTop: '10px',
    marginLeft: '11px'
  },
  menu: {
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: colorStyles.leftMenuHover,
    },
  },
  accordionHeader: {
    color: '#2e8eec',
    textAlign: 'initial',
    fontWeight: 500,
    fontSize: '15px'
  },
  accordionWrapper: {
    backgroundColor: colorStyles.leftMenuBg,
  },
  scrollWrapper: {
    // maxHeight: 380,
    // overflow: 'hidden',
    // '&:hover': {
    //   overflowY: 'scroll',
    // },
    marginTop: '-26px',
  },
}));

function AutomatedMenusList(props) {
  const classes = useStyles();
  //FOR RECOMMENDATIONS BADGE COUNT
  const recommendedCount = props && props.recommendedCount ? props.recommendedCount : 0;
  const userActiveMenus = props && props.authenticatedMenus()
  //for doing the sorting of menus

  userActiveMenus && userActiveMenus.length && userActiveMenus.sort(function (a, b) {
    return b.category.length - a.category.length;
  })
  //FOR DOING THE SEPARATION BASED ON CATEGORY
  const activeMenusList = groupBy(userActiveMenus, menu => menu.category)

  //FOR RENDERING THE ACCORDIONS BASED ON THE CATEGORY
  const getAccordions = (data) => {
    return (
      <Accordion defaultExpanded className={classes.accordionWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#2e8eec' }} />}
          aria-controls={data && data[0]}
          id={data && data[0]}
        >
          <Typography className={classes.accordionHeader}>{data && data[0] ? data[0] : 'NA'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List id="leftMenu" className={classes.scrollWrapper}>
            {data && data[1] && data[1].length && data[1].map((data, index) => (
              <React.Fragment key={index} >
                {data.show ?
                  <React.Fragment>
                    <ListItem button onClick={() => { props.onMenuClick(data) }} className={props.selectedMenu === data.name ? classes.selectedMenu : classes.menu}>
                      <ListItemIcon style={{ color: '#2e8eec', minWidth: 30 }}>
                        <img src={data && data.menu_icon_url && data.menu_icon_url} alt="menu icon" style={{ width: '22px', marginBottom: '2px' }} />
                      </ListItemIcon>
                      {data.name === "My Learning Path" ? (
                        <ListItemText
                          primary={
                            <div style={{ display: 'flex' }}>
                              <div>{data.name}</div>
                              {recommendedCount > 0 && (
                                <Badge max={9} badgeContent={recommendedCount} className={classes.badge} color="primary" aria-label={recommendedCount ? `new recommendations count ${recommendedCount}` : null} />
                              )}
                            </div>
                          }
                        />
                      ) : <ListItemText primary={data.name} />}
                    </ListItem>
                  </React.Fragment>
                  : null}
              </React.Fragment>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <div style={{ marginBottom: userActiveMenus && userActiveMenus.length ? '55px' : '' }}>
      {activeMenusList && Object.keys(activeMenusList) && Object.keys(activeMenusList).length ? (
        activeMenusList && Object.entries(activeMenusList).map((data, index) =>
          <React.Fragment key={index}>
            {getAccordions(data && data)}
          </React.Fragment>
        )
      ) : null}
    </div>
  )
}

const mapStateToProps = () => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(AutomatedMenusList);
