import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PersonIcon from '@material-ui/icons/Person';
import UserSettingsPopup from './userSettingsPopup';
import { getUSerDetails, getDomainInfo, logUserActivity } from '../../Helpers/basic'
import { getTenant } from '../../Configs/tenantConfig'
import { logout } from '../../store/Auth/actionCreator'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    color: '#2e8eec',
    width: 33,
    height: 33
  },
  arrow1: {
    animation: 'slide1 1s ease-in-out infinite',
    marginRight: '10px',
    color: '#ffc107',
    verticalAlign: 'bottom'
  }
}));

function ProfileMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUserSettings, setOpenUserSettings] = React.useState(false);
  const anchorRef = React.useRef(null);
  const tenant = getTenant()
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const openUserSettingsPopup = () => {
    setOpenUserSettings(true)
  }
  const closeUserSettingsPopup = () => {
    setOpenUserSettings(false)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const userDisplayName = () => {
    const userDetails = getUSerDetails()
    const tenant = getTenant()
    const userProfileImage = props.userProfilePicture && props.userProfilePicture
    if (userDetails.first_name) {
      return (
        <div>
          {tenant.showProfilePicture ? (
            <Avatar alt="User avatar" src={userProfileImage} style={{ width: '43px', height: '43px', marginBottom: '1px', backgroundColor: '#64b5f6' }} />
          ) : (
            <PersonIcon className={classes.profileIcon} />
          )}
          <Typography variant="caption" display="block" gutterBottom style={{ color: tenant.shortName === 'ZS' ? '#fff' : '#333' }}>
            {userDetails.first_name}
          </Typography>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ color: '#333', display: 'inline-block' }}>
            <span style={{ verticalAlign: 'top', fontStyle: 'italic', color: '#999' }}>update profile</span>
            <ArrowRightAltIcon className={classes.arrow1} />
          </div>
          <PersonIcon className={classes.profileIcon} />
        </div>
      )
    }
  }
  const goToProfile = () => {
    logUserActivity(
      {
        action: 'profile-menu-click',
        url: window.location.href,
        object_name: '',
        element_id: 'profile',
        element_name: 'profile',
        element_class: "sidebar-item-click"
      }
    )
    props.history.push('/profile')
  }
  const goToSettings = () => {
    logUserActivity(
      {
        action: 'settings-menu-click',
        url: window.location.href,
        object_name: '',
        element_id: 'settings',
        element_name: 'settings',
        element_class: "sidebar-item-click"
      }
    )
    props.history.push('/settings')
  }
  const logout = () => {
    let cookieDetails = getDomainInfo();
    document.cookie = cookieDetails.domainTokenLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
    document.cookie = cookieDetails.domainUserLabel + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
    document.cookie = cookieDetails.domainUserLabel + '_isadmin' + '=; path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + cookieDetails.domainName;
    localStorage.setItem('user_active_menus', []);
    const tenant = getTenant()
    props.history.push(!tenant.sso ? '/login' : '/init')
  }
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ textTransform: 'none' }}
        >
          {userDisplayName()}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={goToProfile} style={{ paddingLeft: 50, paddingRight: 50 }}>
                      Profile
                    </MenuItem>
                    {tenant.settings && tenant.settings.activate ? (
                      <MenuItem onClick={openUserSettingsPopup} style={{ paddingLeft: 50, paddingRight: 50 }}>
                        Settings
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={logout} style={{ paddingLeft: 50, paddingRight: 50 }}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {openUserSettings ? (
        <UserSettingsPopup open={openUserSettings} closeUserSettingsPopup={closeUserSettingsPopup} />
      ) : null}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    userProfilePicture: state.profilePicReducer.userProfilePicture
  }
}
export default connect(
  mapStateToProps, { logout }
)(ProfileMenu);
