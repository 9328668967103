import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Home from '../Components/Home';
import Login from '../Components/Login';
import LoggedOut from '../Components/LoggedOut';
import ETAAuth from '../Components/Login/ETAAuth';
import ForgotPassword from '../Components/Login/forgotPassword';
import ChangePassword from '../Components/Login/changePassword';
import { PrivateRoute } from '../router';
import SnackBarmessages from '../Components/Common/AlertMessages/snackBarMessages';
import { getTenant } from '../Configs/tenantConfig';
import { getCookieDomain, getDomainInfo, getCookie } from '../Helpers/basic';
import {
  Members,
  Profile,
  Rewards,
  MyAthinaSearch,
  MyLearningPath,
  MyCourses,
  ApprovalRequests,
  VerifyApprovals,
  TrainingDocs,
  MyDocs,
  RedirectPage,
  LearningMaterials,
  UserProfile,
  SkillMap,
  RecommendCourse,
  ManagingBudget,
  MyBudget,
  AppSettings,
  SkillIndex,
  MyFeed,
  MyEvents,
  MyCertificates,
  ExternalAssessments,
  ManageClients,
  ManageCoupons,
  ClientDashboard,
  ManageGroups,
  ManageGroupLearners,
  CouponDashboard
} from './components';


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
})
class Layout extends React.Component {
  componentDidMount() {
    const url = window.location.href
    const tenant = getTenant();
    const domainDet = getDomainInfo();
    const token = getCookie(domainDet.domainTokenLabel);
    const pathName = window.location.pathname
    const isChangePasswordPage = pathName.indexOf('/Y2hhbmdlX3Bhc3N3b3Jk/') > -1
    if (!tenant.sso) {
      if (window.location.pathname !== '/login' && window.location.pathname !== '/forgotpassword' && !isChangePasswordPage && !token) {
        window.location = '/login'
      } else if (window.location.pathname === '/' && token) {
        window.location = '/app'
      }
    } else if (window.location.pathname !== '/' && url.indexOf('/redirect?') < 0 && url.indexOf('/init') < 0 && url.indexOf('/auth/') < 0 && !token) {
      window.location = `https://us-central1-sso-b2bformany.cloudfunctions.net/app/login?tenant=${tenant.tenant}`
    }
  }

  render() {
    const { alertReducer } = this.props
    getCookieDomain()
    const tenant = getTenant();
    return (
      <React.Fragment>
        <Switch>
          {(tenant.sso) ? (
            <Route exact path='/' component={LoggedOut} history={this.props.history} />
          ) : null}
          <Route path='/login' component={Login} />
          <Route path='/init' component={LoggedOut} />
          <Route path='/auth/sso-mard/:etaToken' component={ETAAuth} />
          <Route path='/forgotpassword' component={ForgotPassword} history={this.props.history} />
          <Route path={'/Y2hhbmdlX3Bhc3N3b3Jk/:userToken'} component={ChangePassword} history={this.props.history} />
          <Route exact path='/redirect' component={RedirectPage} history={this.props.history} />
          <PrivateRoute exact path='/app' component={Home} history={this.props.history} />
          <PrivateRoute exact path='/welcome' component={UserProfile} history={this.props.history} />
          <PrivateRoute exact path='/members' component={Members} history={this.props.history} />
          <PrivateRoute exact path='/profile' component={Profile} history={this.props.history} />
          <PrivateRoute exact path='/rewards' component={Rewards} history={this.props.history} />
          <PrivateRoute exact path='/search' component={MyAthinaSearch} history={this.props.history} />
          <PrivateRoute exact path='/mypath' component={MyLearningPath} history={this.props.history} />
          <PrivateRoute exact path='/mycourses' component={MyCourses} history={this.props.history} />
          <PrivateRoute exact path='/approvalrequests' component={ApprovalRequests} history={this.props.history} />
          <PrivateRoute exact path='/verifyapprovals' component={VerifyApprovals} history={this.props.history} />
          <PrivateRoute exact path='/trainingdocs/:doctype' component={TrainingDocs} history={this.props.history} />
          <PrivateRoute exact path='/mydocs/' component={MyDocs} history={this.props.history} />
          <PrivateRoute exact path='/mydocs/:doctype' component={MyDocs} history={this.props.history} />
          {
            //<PrivateRoute exact path='/redirect' component={RedirectPage} history={this.props.history} />
          }
          <PrivateRoute exact path='/learningmaterials' component={LearningMaterials} history={this.props.history} />
          <PrivateRoute exact path='/prepmaterials' component={LearningMaterials} history={this.props.history} />
          <PrivateRoute exact path='/prepclientmaterials' component={LearningMaterials} history={this.props.history} />
          <PrivateRoute exact path='/skillmap' component={SkillMap} history={this.props.history} />
          <PrivateRoute exact path='/budget' component={ManagingBudget} history={this.props.history} />
          <PrivateRoute exact path='/mybudget' component={MyBudget} history={this.props.history} />
          <PrivateRoute exact path='/appconfig' component={AppSettings} history={this.props.history} />
          <PrivateRoute exact path='/skillindex' component={SkillIndex} history={this.props.history} />
          <PrivateRoute exact path='/myfeed' component={MyFeed} history={this.props.history} />
          <PrivateRoute exact path='/myevents' component={MyEvents} history={this.props.history} />
          <PrivateRoute exact path="/mycertificates" component={MyCertificates} history={this.props.history} />
          <PrivateRoute exact path="/assessments" component={ExternalAssessments} history={this.props.history} />
          <PrivateRoute exact path="/clients" component={ManageClients} history={this.props.history} />
          <PrivateRoute exact path="/coupons" component={ManageCoupons} history={this.props.history} />
          <PrivateRoute exact path="/coupon/courses/:couponId" component={CouponDashboard} history={this.props.history} />
          <PrivateRoute exact path="/client/:slugName" component={ClientDashboard} history={this.props.history} />
          <PrivateRoute exact path="/client/:slugName/adduser" component={AppSettings} history={this.props.history} />
          <PrivateRoute exact path="/client/:slugName/groups" component={ManageGroups} history={this.props.history} />
          <PrivateRoute exact path="/client/:slugName/grouplearners/:groupId" component={ManageGroupLearners} history={this.props.history} />
          {(!tenant.sso) ? (
            <Route render={() => <Login />} />
          ) : (
            <Route render={() => <LoggedOut />} />
          )}
        </Switch>
        <SnackBarmessages state={alertReducer.state} message={alertReducer.message} type={alertReducer.type} />
        <RecommendCourse />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
    alertReducer: state.alertReducer,

  }
}
export default connect(
  mapStateToProps, {}
)(withStyles(useStyles)(Layout));
