import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
//LOCAL IMPORTS
import { convertUTCTimeZone, openInNewTab } from '../../Helpers/basic'
import { fetchMandateCoursesData, fetchSingleMndtCrsReport, fetchMndtCrsUsersStatusReport, fetchCrsUsersStatusReportCSV } from '../../store/Dashboard/actionCreator'
import ExportButton from './exportButton'
import PieChartLoader from '../Common/SkeletonLoaders/SkillmapLoader/doughnutChartLoader';
import { getTenant } from '../../Configs/tenantConfig';
import Pagination from './pagination';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    float: 'left',
  },
  courseChartWrapper: {
    flexGrow: 1,
    marginTop: 10,
    float: 'left',
    padding: '1px 1px 1px 1px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '339px',
    width: '100%',
    position: 'relative',
  },
  courseChartWrapperMob: {
    flexGrow: 1,
    marginTop: 20,
    float: 'left',
    padding: '1px 1px 1px 1px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    height: '100%',
    position: 'relative',
  },
  courseChart: {
    position: 'relative',
    height: '140px',
    width: '281px'
  },
  courseChartDataWrapper: {
    display: 'inline-block',
    textAlign: 'center',
    backgroundColor: '#4172a2',
  },
  courseChartDataWrapperMob: {
    display: 'inline-block',
    textAlign: 'center',
    backgroundColor: '#4172a2',
    width: '94%'
  },
  courseChartLegendBox: {
    height: 5,
    width: 15,
    display: 'inline-block',
    marginRight: 5
  },
  courseChartTableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'left',
    color: '#fff',
    maxWidth: '157px',
    wordWrap: 'word-break'
  },
  courseChartNumberTableCell: {
    padding: 6,
    fontSize: '0.8rem',
    paddingRight: '10px',
    color: '#fff'
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '0 1rem 0 0'
  },
  titleWrapper: {
    color: '#2E8EEC'
  },
  container: {
    maxHeight: '211px'
  },
  icnBtnWrapper: {
    color: '#2e8eec',
    float: 'right',
    marginTop: '-9px',
    marginLeft: '-29px'
  }
}));

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]

function CoursesChart(props) {
  const classes = useStyles();
  const tenantInfo = getTenant();
  const mobileView = useMediaQuery('(max-width:600px)');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmpData, setSelectedEmpData] = useState({});
  const [selectedRange, setSelectedRange] = useState('YTD');
  const [inProgress, setInProgress] = useState(false);
  const [mandateCourseData, setMandateCourseData] = useState({});
  const [courseData, setCourseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [exportData, setExportData] = useState([])
  const [reportLoader, setReportLoader] = useState(false)
  let today = new Date()
  let month = today.getDate() + '-' + today.getMonth() + '-' + today.getFullYear()
  let time = today.toLocaleTimeString()
  let yearMonth = month + '_' + time

  //FOR SHOWING THE COURSE LEVEL MANDATE REPORT
  const [crsLevelReportData, setCrsLevelReportData] = useState([]); //FOR SHOWING CRS LEVEL DOUGHNUT CHART DATA
  const [selectedCourseData, setSelectedCourseData] = useState({}); //FOR STORING THE SELECTED COURSE DATA INFO
  const [crsLevelReportLoader, setCrsLevelReportLoader] = useState(false); //FOR SHOWING THE CRS LEVEL DOUGHNUT LOADER
  const [showCrsLevelReport, setShowCrsLevelReport] = useState(false); //if true crs level chart will render
  //FOR SHOWING THE SINLGE COURSE STATUS BASED USER INFO
  const [chartStatusReportLoader, setChartStatusReportLoader] = useState(false); //FOR FETCH USERS OF SINGLE STATUS BASED CRS CHART LOADER
  const [chartStatusTotalCount, setChartStatusTotalCount] = useState(0); //FOR MAINTAING THE COUNT OF SINGLE CRS CHART STATUS TYPE
  const [selectedCrsChartLabel, setSelectedCrsChartLabel] = useState(''); //SELECTED CHART TYPE OF CRS DOUGHNUT
  const [showCrsChartStatusData, setShowCrsChartStatusData] = useState(false); //FOR SHOWING THE SINGL CRS CHART STATUS DATA ON POPUP
  const rowsPerPage = 10;
  const offset = 0;
  const [page, setPage] = useState(0);

  const colorArray = [
    "#caf270",
    '#45c490',
    '#008d93',
    '#F4A540',
    '#ff8f8f',
    '#ccc'
  ];

  const renderCourseChart = (courses) => {
    const courseData = courses && courses.mandate_course && courses.mandate_course
    let courseDataLabels = []
    let courseDataValues = []
    let gotValue = false
    for (let i in courseData) {
      courseDataLabels.push(courseData[i].label)
      courseDataValues.push(courseData[i].value)
      if (!gotValue && courseData[i].value) {
        gotValue = true
      }
    }
    if (!gotValue) {
      courseDataLabels.push('empty')
      courseDataValues.push(100)
    }
    const courseChart = document.getElementById('mandateChart')
    const Chart = props.Chart
    new Chart(courseChart, {
      type: 'doughnut',
      data: {
        labels: courseDataLabels,
        datasets: [{
          label: '# of Votes',
          data: courseDataValues,
          backgroundColor: [
            "#caf270",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderColor: [
            "#caf270",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: gotValue
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  useEffect(() => {
    if (props.mandateCourseData && props.mandateCourseData.mandate_course && props.mandateCourseData.mandate_course.length) {
      renderCourseChart(props.mandateCourseData);
      if (props.mandateCourseData.mandate_course) {
        setCourseData(props.mandateCourseData.mandate_course);
      }
      if (props.mandateCourseData.course_details) {
        setTableData(props.mandateCourseData.course_details);
      }
    }
  }, [props.mandateCourseData])

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  //ON COUNT CLICK FROM TABLE SHOWING THE POPUP WITH USERS INFO
  const handleCountClick = (event, data) => {
    setOpenDialog(true);
    setShowCrsChartStatusData(false);
    setSelectedEmpData(data);
  }

  const mandateDataSuccessCB = res => {
    setMandateCourseData(res && res.results);
    setCourseData(res && res.results.mandate_course);
    setTableData(res && res.results.course_details);
    setInProgress(false);
    renderCourseChart(res.results);
    setCrsLevelReportLoader(false);
  }

  const mandateDataFailureCB = err => {
    setInProgress(false);
    setMandateCourseData({});
    setCrsLevelReportLoader(false);
  }

  const handleChangeOption = (event, type) => {
    let rangeValue = ''
    if (showCrsLevelReport || type === 'singleCourse') {
      //FOR CLEARING OUT THE SINGLE SELECTION DATA
      if (type === 'singleCourse') {
        setCrsLevelReportData([]);
        setCrsLevelReportLoader(true);
        setShowCrsLevelReport(false);
        setSelectedCourseData({});
        let range = selectedRange
        setSelectedRange(range)
        rangeValue = selectedRange
      } else {
        let range = event.target.value
        setSelectedRange(range)
        rangeValue = event.target.value
      }
    } else {
      setSelectedRange(event.target.value)
      rangeValue = event.target.value
    }

    if (type !== 'singleCourse' && showCrsLevelReport) {
      getSingleCourseReport(selectedCourseData.course_id, rangeValue);
    } else {
      setShowCrsChartStatusData(false);
      setInProgress(true);
      let payload = ''
      if (rangeValue && rangeValue === 'YTD') {
        payload = {
          range: rangeValue,
          value: new Date().getFullYear()
        }
      } else {
        payload = {
          range: rangeValue,
          value: (new Date().getMonth() + 1).toString().padStart(2, "0")
        }
      }
      fetchMandateCoursesData(payload, mandateDataSuccessCB, mandateDataFailureCB)
    }
  }

  const getCourseStatus = (row) => {
    const courseStatus = row && row.status && row.status
    if (row && row.is_due_date_passed) {
      return "due-date-ended"
    } else {
      switch (courseStatus) {
        case 'completed':
          return row.status && row.status
        default:
          if (row && row.status === null || row.status === 'yet-to-start') {
            return "Not Started"
          } else {
            return row.status ? row.status : 'N/A'
          }
      }
    }
  }
  const successCSVCB = res => {
    setReportLoader(false);
    let fileUrl = res && res.file_url && res.file_url
    openInNewTab(fileUrl);
  }
  const failureCSVCB = err => {
    setReportLoader(false);
    props.showSnackBar({
      state: true,
      message: 'Something went wrong while generating CSV report!',
      type: 'error'
    })
  }

  const onClickExportTrigger = (userData) => {
    setReportLoader(true)

    //FOR Single selected course status based csv file genaration
    if (showCrsChartStatusData) {
      let payload = ''
      if (selectedRange === 'YTD') {
        payload = {
          type: selectedCrsChartLabel,
          course_id: selectedCourseData.course_id,
          is_csv: true,
          range: selectedRange,
          value: new Date().getFullYear()
        }
      } else {
        payload = {
          type: selectedCrsChartLabel,
          course_id: selectedCourseData.course_id,
          is_csv: true,
          range: selectedRange,
          value: (new Date().getMonth() + 1).toString().padStart(2, "0")
        }
      }
      fetchCrsUsersStatusReportCSV(payload, successCSVCB, failureCSVCB)
    } else {
      let data = userData
      let downloadData = []
      if (data && data.employee_details && data.employee_details.length) {
        for (let i in data.employee_details) {
          let obj = data.employee_details[i]
          obj.due_date_mandate = obj.due_date_mandate ? convertUTCTimeZone(obj.due_date_mandate) : 'NA'
          obj.status = getCourseStatus(obj)
          obj.course_title = data && data.course_title ? data.course_title : 'NA'
          downloadData.push(obj)
        }
      }
      setExportData(downloadData)
      setTimeout(() => {
        document.getElementById('exportReport').click()
        setReportLoader(false)
      }, 1000)
    }
  }

  const csvHeaders = [
    { label: "Employee Name", key: "name" },
    { label: "Employee Email", key: "email" },
    { label: "Course Title", key: "course_title" },
    { label: "Course Due Date", key: "due_date_mandate" },
    { label: "Course status", key: "status" }
  ]

  //FOR FETCHING THE SINGLE COURSE SINGLE STATUS USERS INFO
  const successCrsReportCB = res => {
    setChartStatusTotalCount(res && res.results && res.results.total_count);
    setSelectedEmpData(res && res.results);
    setChartStatusReportLoader(false);
  }
  const failureCrsReportCB = err => {
    setChartStatusTotalCount(0);
    setSelectedEmpData([]);
    setChartStatusReportLoader(false);
    props.showSnackBar({
      state: true,
      message: 'Something went wrong while fetching employees report!',
      type: 'error'
    })
  }

  //FOR SHOWING THE SINGLE MANDATE COURSE USERS INFO BASED ON CHART CLICK
  const getCourseStatusUsersInfo = (label, courseId, offset, number, selectedRange) => {
    setChartStatusReportLoader(true);
    setOpenDialog(true);
    let payload = ''
    if (selectedRange === 'YTD') {
      payload = {
        type: label,
        course_id: courseId,
        offset: offset,
        number: number,
        range: selectedRange,
        value: new Date().getFullYear()
      }
    } else {
      payload = {
        type: label,
        course_id: courseId,
        offset: offset,
        number: number,
        range: selectedRange,
        value: (new Date().getMonth() + 1).toString().padStart(2, "0")
      }
    }
    fetchMndtCrsUsersStatusReport(payload, successCrsReportCB, failureCrsReportCB)
  }

  //FOR PAGINATION OF SINGLE COURSE STATUS POPUP
  const onPagination = pageNum => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum);
    const offsetNum = pageNum * rowsPerPage;
    getCourseStatusUsersInfo(selectedCrsChartLabel, selectedCourseData.course_id, offsetNum, rowsPerPage, selectedRange);
  }

  const renderSingleCourseChart = (courses, courseId, dateRange) => {
    const courseData = courses && courses.mandate_course && courses.mandate_course
    //FOR ONCLICK OF CHART LABEL
    const onChartItemClick = (e, chartElement) => {
      if (chartElement && chartElement.length) {
        let chartLable = chartElement && chartElement[0] && chartElement[0]._view && chartElement[0]._view.label
        //courseId is selected single course from right table
        if (chartLable && chartLable !== 'empty') {
          setShowCrsChartStatusData(true);
          setPage(0);
          setSelectedCrsChartLabel(chartLable);
          getCourseStatusUsersInfo(chartLable, courseId, offset, rowsPerPage, dateRange)
        }
      }
    }

    let courseDataLabels = []
    let courseDataValues = []
    let gotValue = false
    for (let i in courseData) {
      courseDataLabels.push(courseData[i].label)
      courseDataValues.push(courseData[i].value)
      if (!gotValue && courseData[i].value) {
        gotValue = true
      }
    }
    if (!gotValue) {
      courseDataLabels.push('empty')
      courseDataValues.push(100)
    }
    const courseChart = document.getElementById('mandateChartCourseLevel')
    const Chart = props.Chart
    new Chart(courseChart, {
      type: 'doughnut',
      data: {
        labels: courseDataLabels,
        datasets: [{
          data: courseDataValues,
          backgroundColor: [
            "#caf270",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderColor: [
            "#caf270",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
          }
        },
        tooltips: {
          enabled: gotValue
        },
        onClick: onChartItemClick,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  //COURSE LEVEL REPORT CALLBACKS
  const successReportCB = (res, courseId, dateRange) => {
    setCrsLevelReportLoader(false);
    setCrsLevelReportData(res && res.results && res.results.mandate_course)
    renderSingleCourseChart(res && res.results, courseId, dateRange)
  }
  const failureReportCB = err => {
    setCrsLevelReportLoader(false);
    setCrsLevelReportData([]);
    props.showSnackBar({
      state: true,
      message: 'Something went wrong while fetching course report!',
      type: 'error'
    })
  }

  //FOR FETCHING THE SINLGE COURSE MANDATE REPORT
  const getSingleCourseReport = (courseId, dateRange) => {
    setShowCrsLevelReport(true);
    setCrsLevelReportLoader(true);
    let payload = ''
    if (dateRange === 'YTD') {
      payload = {
        "course_id": courseId,
        "range": dateRange,
        "value": new Date().getFullYear()
      }
    } else {
      payload = {
        "course_id": courseId,
        "range": dateRange,
        "value": (new Date().getMonth() + 1).toString().padStart(2, "0")
      }
    }
    fetchSingleMndtCrsReport(payload, successReportCB, failureReportCB, courseId, dateRange)
  }

  //FOR SHOWING THE COURSE LEVEL doughnut CHART
  const onSingleCourseReportClick = (row) => {
    setPage(0);
    setSelectedCourseData(row)
    getSingleCourseReport(row.course_id, selectedRange);
  }

  const getTableStatus = (row) => {
    const courseStatus = row && row.status && row.status
    if (row && row.is_due_date_passed) {
      return (
        <React.Fragment>
          <TableCell component="th" scope="row" align="center">
            <span style={{ backgroundColor: '#d50000', color: '#ffff', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingRight: '4px', paddingLeft: '4px' }}>due-date-ended</span>
          </TableCell>
        </React.Fragment>
      )
    } else {
      switch (courseStatus) {
        case 'completed':
          return (
            <React.Fragment>
              <TableCell component="th" scope="row" align="center">
                <span style={{ backgroundColor: '#008d93', color: '#ffff', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '17px', paddingRight: '17px' }}>{row.status && row.status}</span>
              </TableCell>
            </React.Fragment>
          )
        case 'in-progress':
          return (
            <React.Fragment>
              <TableCell component="th" scope="row" align="center">
                <span style={{ backgroundColor: '#45c490', color: '#ffff', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '15px', paddingRight: '15px' }}>{row.status && row.status}</span>
              </TableCell>
            </React.Fragment>
          )
        case 'ongoing':
          return (
            <React.Fragment>
              <TableCell component="th" scope="row" align="center">
                <span style={{ backgroundColor: '#45c490', color: '#ffff', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '23px', paddingRight: '23px' }}>{row.status && row.status}</span>
              </TableCell>
            </React.Fragment>
          )
        case 'yet-to-start':
          return (
            <React.Fragment>
              <TableCell component="th" scope="row" align="center">
                <span style={{ backgroundColor: '#caf270', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '16px', paddingRight: '16px' }}>Not Started</span>
              </TableCell>
            </React.Fragment>
          )
        case 'approved':
          return (
            <React.Fragment>
              <TableCell component="th" scope="row" align="center">
                <span style={{ backgroundColor: '#F8C045', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '16px', paddingRight: '16px' }}>{row.status && row.status}</span>
              </TableCell>
            </React.Fragment>
          )
        default:
          if (row && row.status === null) {
            return (
              <React.Fragment>
                <TableCell component="th" scope="row" align="center">
                  <span style={{ backgroundColor: '#caf270', padding: '2px', borderRadius: '2px', fontSize: '13px', paddingLeft: '16px', paddingRight: '16px' }}>Not Started</span>
                </TableCell>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                <TableCell component="th" scope="row" align="center">
                  {row.status ? row.status : 'N/A'}
                </TableCell>
              </React.Fragment>
            )
          }
      }
    }
  }

  return (
    <Paper className={mobileView ? classes.courseChartWrapperMob : classes.courseChartWrapper}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '14px' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ width: '100%', textAlign: 'left', marginTop: '15px', marginLeft: '10px' }}>
              Mandatory Courses
            </Typography>
            <div className={classes.mtdCtr}>
              <TextField
                className={classes.mtd}
                id="progress"
                select
                classes={{ outlined: "padding:1px" }}
                margin="dense"
                value={selectedRange}
                onChange={handleChangeOption}
                name="Progress"
                variant="outlined"
                disabled={props.mandateLoader || inProgress}
              >
                {listOfSelection && listOfSelection.map((option) => (
                  <MenuItem style={{ padding: '1px' }} key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: '-11px' }} >
          <div className={classes.courseChart}>
            {showCrsLevelReport ? (
              <React.Fragment>
                {crsLevelReportLoader ? (
                  <PieChartLoader />
                ) : (
                  <React.Fragment>
                    <Tooltip title="Close">
                      <IconButton className={classes.icnBtnWrapper} aria-label="Close course level report chart" component="span" onClick={() => { handleChangeOption('', 'singleCourse') }}>
                        <HighlightOffIcon style={{ color: '#2e8eec' }} />
                      </IconButton>
                    </Tooltip>
                    {crsLevelReportData && crsLevelReportData.length ? (
                      <React.Fragment>
                        <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                          {crsLevelReportData && crsLevelReportData.length && crsLevelReportData.map((row, key) => (
                            <li key={key} style={{ marginRight: 10 }}>
                              <div className={classes.legend}>
                                {tenantInfo.tenant !== 'caseys' ? (
                                  <React.Fragment>
                                    {row.label && row.label !== 'Certified' && <span className={classes.courseChartLegendBox} style={{ backgroundColor: colorArray[key] }}></span>}
                                    {row.label && row.label !== 'Certified' && <span>{row.label && row.label} ({row.value && row.value})</span>}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <span className={classes.courseChartLegendBox} style={{ backgroundColor: colorArray[key] }}></span>
                                    <span>{row.label && row.label} ({row.value && row.value})</span>
                                  </React.Fragment>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ) : null}
                    <canvas id="mandateChartCourseLevel" aria-label="Mandatory Single Courses Chart" role="img" ></canvas>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {props.mandateLoader || inProgress ? (
                  <PieChartLoader />
                ) : (
                  <React.Fragment>
                    {courseData && courseData.length ? (
                      <React.Fragment>
                        <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                          {courseData && courseData.length && courseData.map((row, key) => (
                            <li key={key} style={{ marginRight: 10 }}>
                              <div className={classes.legend}>
                                {tenantInfo.tenant !== 'caseys' ? (
                                  <React.Fragment>
                                    {row.label && row.label !== 'Certified' && <span className={classes.courseChartLegendBox} style={{ backgroundColor: colorArray[key] }}></span>}
                                    {row.label && row.label !== 'Certified' && <span>{row.label && row.label} ({row.value && row.value})</span>}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <span className={classes.courseChartLegendBox} style={{ backgroundColor: colorArray[key] }}></span>
                                    <span>{row.label && row.label} ({row.value && row.value})</span>
                                  </React.Fragment>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ) : null}
                    <canvas id="mandateChart" aria-label="Mandatory Courses Chart" role="img" ></canvas>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: mobileView ? '74px' : null }}>
          <div className={mobileView ? classes.courseChartDataWrapperMob : classes.courseChartDataWrapper}>
            <TableContainer className={classes.container}>
              <Table className={classes.table} aria-label="Top Mandatory Courses">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.courseChartTableCell} style={{ fontWeight: '700', fontSize: '1rem' }}>Courses Assigned</TableCell>
                    <TableCell align="right" className={classes.courseChartNumberTableCell} style={{ fontWeight: '700', fontSize: '1rem', maxWidth: '87px' }}>No of Employees</TableCell>
                  </TableRow>
                </TableHead>
                {props.mandateLoader || inProgress ? (
                  <div style={{ fontSize: '13px', fontWeight: '600', color: '#ffff', width: '100px' }}>Loading...</div>
                ) : (
                  <TableBody>
                    {tableData && tableData.length ? (
                      <React.Fragment>
                        {tableData.map((row, key) => (
                          <TableRow key={key} style={{ backgroundColor: ((selectedCourseData && selectedCourseData.course_id) === (row && row.course_id)) ? '#fff' : '' }}>
                            <TableCell scope="row" align="center" className={classes.courseChartTableCell}>
                              <Link style={{ color: ((selectedCourseData && selectedCourseData.course_id) === (row && row.course_id)) ? '#2e8eec' : '#ffff', fontWeight: ((selectedCourseData && selectedCourseData.course_id) === (row && row.course_id)) ? 900 : 0, textAlign: 'left', padding: '6px', fontSize: '0.8rem', maxWidth: '157px' }} component="button"
                                aria-describedby={'Course Level Breakdown'}
                                onClick={() => { onSingleCourseReportClick(row) }}
                              >
                                {row.course_title && row.course_title.length && row.course_title}
                              </Link>
                              {/* {row.course_title && row.course_title.length && row.course_title} */}
                            </TableCell>
                            <TableCell align="right" scope="row" className={classes.courseChartNumberTableCell}>
                              <Link style={{ color: ((selectedCourseData && selectedCourseData.course_id) === (row && row.course_id)) ? '#2e8eec' : '#ffff', fontWeight: ((selectedCourseData && selectedCourseData.course_id) === (row && row.course_id)) ? 900 : 0 }} component="button"
                                aria-describedby={'employee count'}
                                onClick={(event) => { handleCountClick(event, row) }}
                              >
                                {row.employee_count && row.employee_count}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} component="th" scope="row" align="center" className={classes.courseChartTableCell}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          <Dialog
            open={openDialog}
            maxWidth={'md'}
            fullWidth
            onClose={handleDialogClose}
            aria-label="Mandatory course information"
          >
            <DialogTitle className={classes.titleWrapper}>
              {showCrsChartStatusData ? (
                selectedCourseData && selectedCourseData.course_title && selectedCourseData.course_title
              ) : (
                selectedEmpData && selectedEmpData.course_title && selectedEmpData.course_title
              )}
            </DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex' }}>
                {showCrsChartStatusData && <DialogContentText style={{ display: 'inline-block', marginTop: '8px', width: '100%' }}>
                  {`Employees with ${selectedCrsChartLabel} Information`}
                </DialogContentText>}
                <React.Fragment>
                  <ExportButton reportLoader={reportLoader} onClickExport={() => { onClickExportTrigger(selectedEmpData) }} />
                  <CSVLink data={exportData} headers={csvHeaders} id="exportReport" style={{ display: 'none' }} filename={`Mandatory_report_${yearMonth}.csv`} aria-hidden="true">
                    <GetAppIcon />
                  </CSVLink>
                </React.Fragment>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Selected Mandatory course">
                  <TableHead style={{ backgroundColor: '#EBF7FA' }}>
                    <TableRow>
                      <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>E-mail</TableCell>
                      {!showCrsChartStatusData && <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>Status</TableCell>}
                      <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>Due Date</TableCell>
                    </TableRow>
                  </TableHead>
                  {showCrsChartStatusData && chartStatusReportLoader ? (
                    <div style={{ fontSize: '13px', textAlign: 'center', paddingTop: '10px', paddingBottom: '12px' }}>Loading...</div>
                  ) : (
                    <React.Fragment>
                      {selectedEmpData && selectedEmpData.employee_details && selectedEmpData.employee_details.length > 0 ? (
                        <React.Fragment>
                          <TableBody>
                            {selectedEmpData.employee_details.map((row, key) => (
                              <TableRow key={key}>
                                <TableCell component="th" scope="row" align="center">
                                  {row.name && row.name}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {row.email && row.email}
                                </TableCell>
                                {!showCrsChartStatusData && getTableStatus(row)}
                                <TableCell component="th" scope="row" align="center" >
                                  {row.due_date_mandate && convertUTCTimeZone(row.due_date_mandate)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </React.Fragment>
                      ) : (
                        <Typography style={{ fontSize: '14px', textAlign: 'center' }}>No records found</Typography>
                      )}
                    </React.Fragment>
                  )}
                </Table>
              </TableContainer>
              {!chartStatusReportLoader && showCrsChartStatusData && chartStatusTotalCount > 10 ? (
                <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={chartStatusTotalCount} />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} variant="outlined" color="primary" style={{ textTransform: 'none' }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Paper>
  )

}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CoursesChart);
