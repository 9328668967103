import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

const mockCount = {
  count: 6
}
const clear = {
 count: 0
}

const mockList = {
 results: [
  {
    "id":1,
    "title": 'My Learning path',
    "desc": 'New recommendations added by Myathina',
    "date": "2020-01-12T17:09:08.675506Z"
  },
  {
    "id":2,
    "title": 'My Courses',
    "desc": 'Your requested course has been approved please check',
    "date": "2020-01-12T17:09:08.675506Z"
  },
  {
   "id":1,
   "title": 'My Learning path',
   "desc": 'New recommendations added by Myathina',
   "date": "2020-01-12T17:09:08.675506Z"
  },
  {
    "id":2,
    "title": 'My Courses',
    "desc": 'Your requested course has been approved please check',
    "date": "2020-01-12T17:09:08.675506Z"
  }
 ]
}

export const fetchNotificationCount = (payLoad, successCallBack, failureCallBack) => {
 const url =`${apiConfig.notifications.fetchNotificationCount}`
 axios.get(url)
 .then(function (response) {
   successCallBack(response.data)
 })
 .catch(function (error) {
   //failureCallBack(error)
   successCallBack(mockCount)
 })
}

export const fetchNotificationList = (successCallBack, failureCallBack) => {
 const url = `${apiConfig.notifications.fetchNotificationList}`
 axios.get(url)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(mockList)
  })
}

export const clearNotificationCount = (payLoad, successCallBack, failureCallBack) => {
 const url =`${apiConfig.notifications.fetchNotificationCount}`
 axios.get(url)
 .then(function (response) {
   successCallBack(response.data)
 })
 .catch(function (error) {
   //failureCallBack(error)
   successCallBack(clear)
 })
}