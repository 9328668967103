import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { colorStyles } from '../../styles/style'
import { convertUTCTimeZone } from '../../Helpers/basic'
import { fetchNotificationCount, clearNotificationCount, fetchNotificationList } from '../../store/Notifications/actionCreator'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  buttonProgress: {
   marginTop: '20px',
   color: colorStyles.fontColor
  },
  titleWrap: {
    fontSize: '15px',
    fontWeight: 500,
    color: colorStyles.fontColor
  },
  descWrap: {
    fontSize: '14px',
    color: '#999',
    marginTop: '4px'
  }
}));

function Notification(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [ loader, setLoader ] = React.useState(true)
  const [notificationCount,setNotificationCount]  = React.useState(0)
  const [notificationsData, setNotificationsData] = React.useState([])

  const notificationSuccessCB = res => {
   setNotificationCount(res.count)
 }
 const notificationFailureCB = error => {
  setNotificationCount(0)
 }

 const successNotificationList = res => {
  setLoader(false)
  setNotificationsData(res && res.results && res.results)
 }
 const failureNotificationList = error => {
  setLoader(false)
  setNotificationsData([])
 }

  useEffect(()=>{
   fetchNotificationCount({}, notificationSuccessCB, notificationFailureCB )
  },[])

  const handleToggle = () => {
    clearNotificationCount({}, notificationSuccessCB, notificationFailureCB)
    fetchNotificationList({}, successNotificationList, failureNotificationList)
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setLoader(false)
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  
  return (
    <div className={classes.root}>
      <div>
        <IconButton  ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} color="primary" aria-label="Notifications" component="span">
          <Tooltip title="Notifications panel">
            <Badge badgeContent={notificationCount} color="secondary">
              <NotificationsIcon style={{ color: colorStyles.fontColor }} fontSize="large"/>
            </Badge>
          </Tooltip>
        </IconButton>
        <ClickAwayListener onClickAway={handleClose}>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: '320px', height: '320px', marginLeft: '-230px', overflow: 'auto' }}
            >
              <Paper>
                { loader ? (
                  <CircularProgress size={50} className={classes.buttonProgress} />
                ) : (               
                  <React.Fragment>
                   <List aria-label="notifications">
                     { notificationsData && notificationsData.length > 0 ? (
                        notificationsData.map((data, index)=> (
                         <React.Fragment>
                          <ListItem key={index}>
                            <div>
                              <Typography className={classes.titleWrap}>{data && data.title}</Typography>
                              <Typography className={classes.descWrap}>{data && data.desc}</Typography>
                              <Typography className={classes.descWrap}>{data && data.date ? convertUTCTimeZone(data.date) : null }</Typography>
                            </div>
                          </ListItem>
                          <Divider/>
                         </React.Fragment>
                        ))
                      ) : null }
                   </List>
                  </React.Fragment>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
        </ClickAwayListener>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { }
)(Notification);
