import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { find } from 'lodash';
import { login } from '../../store/Auth/actionCreator';
// import myAthinaLogo from '../../static/myAthinaLogo.png';
import myAthinaLogo from '../../static/myathina-logo-1.svg';
import AlertMessages from '../../Components/Common/AlertMessages';
import { generateJwtToken, getDomainInfo, getUSerDetails, getActiveStepComponent } from '../../Helpers/basic';
import { getTenant } from '../../Configs/tenantConfig';
import { getDepartmentMenus } from '../AppLayout/menuList'
import { fetchStepperComponents } from '../../store/UserProfile/actionCreator'
import Loader from '../Common/SkeletonLoaders/LazyLoaders/skillMapLoader';
import history from '../../history';

const useStyles = theme => ({
  paper: {
    marginTop: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotpassword: {
    textAlign: 'right'
  },
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '-4px',
    marginLeft: 10

  },
  logoImg: {
    width: '43px'
  },
  buttonProgress: {
    color: '#2e8eec'
  }
});
class Login extends React.Component {
  state = {
    userName: '',
    password: '',
    inProgress: false,
    error: {
      status: false,
      errorMessage: ''
    },
    userMenu: {},
    showPassword: false
  }
  componentDidMount() {
    window.FreshworksWidget('hide')
  }

  //FOR CHECKING THE WELCOME PAGE FILLED STATUS CALLBACKS
  componentsSuccessCB = res => {
    const { userMenu } = this.state
    let stepperList = res && res.results && res.results.component && res.results.component
    let stepsArrayList = []
    Object.entries(stepperList).forEach(([key, value]) => {
      stepsArrayList.push(value && value.key)
    });
    let filledData = res && res.results && res.results.user_data && res.results.user_data
    const defaultStep = getActiveStepComponent(stepsArrayList, filledData)
    if (defaultStep === 'allFilled' || defaultStep === 'finish') {
      //FOR AUTOMATED MENU CONFIG CHECK
      if (getTenant().showAutomatedActiveMenus) {
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(getUSerDetails().defaultLandingPage)
        }
      } else {
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(userMenu.defaultLandingPage)
        }
      }
    }
    else {
      history.push('/welcome')
    }
  }
  componentsFailureCB = error => {
    if (getTenant().showAutomatedActiveMenus) {
      history.push(getUSerDetails().defaultLandingPage)
    } else {
      history.push(this.state.userMenu.defaultLandingPage)
    }
  }

  //LOG IN SUCCESS CALLBACKS
  successCallBack = res => {
    this.setState({
      error: {
        status: false,
        errorMessage: 'success'
      },
      inProgress: false
    })
    const userDepartment = res.user.department
    const departmentMenus = getDepartmentMenus()
    const userMenu = find(departmentMenus, { 'departmentId': userDepartment.id })
    this.setState({
      userMenu: userMenu
    })
    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);
    let domainDet = getDomainInfo();

    //FOR SETTING UP THE USER_ACTIVE_MENUS
    if (res && res.active_menus && res.active_menus.length) {
      const encodeMenus = encodeURI(JSON.stringify(res.active_menus && res.active_menus))
      localStorage.setItem('user_active_menus', encodeMenus);
    } else {
      localStorage.setItem('user_active_menus', [])
    }

    document.cookie = domainDet.domainTokenLabel + "=" + res.token + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    const user_token = generateJwtToken(res.user)
    myDate.setMonth(myDate.getMonth() + 1);
    document.cookie = domainDet.domainUserLabel + "=" + user_token + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    if (getTenant().show_welcomepage) {
      const payload = {
        user_id: getUSerDetails() && getUSerDetails().id && getUSerDetails().id
      }
      fetchStepperComponents(payload, this.componentsSuccessCB, this.componentsFailureCB)
    } else {
      //FOR AUTOMATED MENU CONFIG CHECK
      if (getTenant().showAutomatedActiveMenus) {
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(res && res.user && res.user.defaultLandingPage)
        }
      } else {
        if (localStorage.getItem('mailRedirectRoute')) {
          const path = localStorage.getItem('mailRedirectRoute')
          this.props.history.push(`/${path}`)
          localStorage.removeItem('mailRedirectRoute')
        } else {
          this.props.history.push(userMenu.defaultLandingPage)
        }
      }
    }
    window.FreshworksWidget('hide')
  }
  failureCallBack = error => {
    this.setState({
      error: {
        status: true,
        errorMessage: 'Invalid credentials'
      },
      inProgress: false
    })
  }

  //ON LOG IN CLICK
  onLogin = event => {
    event.preventDefault()
    this.setState(
      {
        error: {
          status: false,
          errorMessage: ''
        },
        inProgress: true
      }
    )
    const userName = document.getElementById('userName').value
    const password = document.getElementById('password').value
    if (userName !== '' && password !== '') {
      const payload = {
        username: userName,
        password: password
      }
      this.props.login(payload, this.successCallBack, this.failureCallBack)
    } else {
      this.setState({
        error: {
          status: true,
          errorMessage: 'Please enter required information.'
        },
        inProgress: false
      })
    }
  }
  getTenantLogo = () => {
    const tenant = getTenant()
    switch (tenant.shortName) {
      case 'ZS':
        return (<img src={tenant.logo} style={{ maxWidth: 150, backgroundColor: '#000' }} alt={`${tenant.tenant} logo`} />)
      case 'FB':
        return (<img src={tenant.logo} style={{ maxWidth: 164 }} alt={`${tenant.tenant} logo`} />)
      case 'ansrsourcegems':
        return (<img src={tenant.logo} style={{ maxWidth: 164 }} alt={`${tenant.tenant} logo`} />)
      case 'ANSR':
        return (<img src={tenant.logo} style={{ maxWidth: 183 }} alt={`${tenant.tenant} logo`} />)
      case 'caseys':
        return (<img src={tenant.logo} style={{ maxWidth: 110 }} alt={`${tenant.tenant} logo`} />)
      case 'freo':
        return (<img src={tenant.logo} style={{ maxWidth: 114 }} alt={`${tenant.tenant} logo`} />)
      default:
        return (<img src={tenant.logo} style={{ maxWidth: 79 }} alt={`${tenant.tenant} logo`} />)
    }
  }
  getmyAthinaLogo = tenant => {
    const { classes } = this.props
    switch (tenant) {
      case 'FB':
        return (
          <img
            src={myAthinaLogo}
            className={classes.myAthinaLogo}
            alt="myathina"
            style={{ width: 146, marginTop: 25 }}
          />
        )
      case 'freo':
        return (
          <img
            src={myAthinaLogo}
            className={classes.myAthinaLogo}
            alt="myathina"
            style={{ width: 146, marginTop: 15 }}
          />
        )
      default:
        return (<img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina logo" />)
    }
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes } = this.props
    const { inProgress, error, showPassword } = this.state
    const tenant = getTenant()
    const authLogin = tenant.sso

    // const enableForgotPassword = tenant.tenant === 'falabella' ? true : false
    const enableForgotPassword = !authLogin ? true : false
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {!authLogin ? (
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              style={{ backgroundColor: tenant.shortName === 'ZS' ? '#000' : '#fff', padding: 10, width: '100%', marginBottom: tenant.shortName === 'FB' ? '-44px' : 0 }}
            >
              {this.getTenantLogo()}
              {this.getmyAthinaLogo(tenant.shortName)}
            </Typography>
            {error.status ? (
              <div style={{ marginTop: '20px', width: '90%' }}>
                <AlertMessages status={error.status ? 'error' : 'success'} message={error.errorMessage} />
              </div>
            ) : null}
            <form className={classes.form} noValidate onSubmit={this.onLogin} autoComplete="off">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userName"
                aria-label="Please add your email address"
                label="Email Address"
                name="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                aria-label="Please add password"
                name="password"
                label="Password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle secret visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility style={{ color: '#3f51b5' }} /> : <VisibilityOff style={{ color: '#3f51b5' }} />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
              {enableForgotPassword ? (
                <div className={classes.forgotpassword}>
                  <Link href="/forgotpassword" variant="body2">
                    Forgot / Reset password?
                  </Link>
                </div>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.onLogin}
                disabled={inProgress}
              >
                {inProgress && (
                  <CircularProgress size={25} className={classes.buttonProgress} />
                )}
                Sign In
              </Button>
            </form>
          </div>
        ) : (
          <Loader />
        )}

      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, { login }
)(withStyles(useStyles)(Login));
