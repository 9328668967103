import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import myAthinaLogo from '../../static/myAthinaLogo.png';
import { getTenant } from '../../Configs/tenantConfig';
import { getLoggedInStatus, getUSerDetails } from '../../Helpers/basic';
//import { getDepartmentMenus } from '../AppLayout/menuList';


const useStyles = theme => ({
  paper: {
    marginTop: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '-4px',
    marginLeft: 10

  },
  logoImg: {
    width: '43px'
  },
  buttonProgress: {
    color: '#2e8eec'
  }
});
class LoggedOut extends React.Component {

  state = {
    inProgress: false,
    error: {
      status: false,
      errorMessage: ''
    },
  }

  constructor(props) {
    super(props)
    if (getLoggedInStatus()) {
      window.location = getUSerDetails().defaultLandingPage ? getUSerDetails().defaultLandingPage : '/app'
      //const userDepartment = getUSerDetails().department
      // const departmentMenus = getDepartmentMenus()
      // const userMenu = find(departmentMenus, { 'departmentId': userDepartment.id })
      // window.location = userMenu.defaultLandingPage ? userMenu.defaultLandingPage : '/app';
    }
  }

  redirectToSignIn = () => {
    // window.location='/login'
    const tenant = getTenant()
    window.location = `https://us-central1-sso-b2bformany.cloudfunctions.net/app/login?tenant=${tenant.tenant}`
  }
  componentDidMount() {
    window.FreshworksWidget('hide')
  }
  getTenantLogo = () => {
    const tenant = getTenant()
    switch (tenant.shortName) {
      case 'ZS':
        return (<img src={tenant.logo} style={{ maxWidth: 144, backgroundColor: '#000', padding: 10 }} alt={tenant.tenant} />)
      case 'FB':
        return (<img src={tenant.logo} style={{ maxWidth: 140, marginTop: '-17px' }} alt={tenant.tenant} />)
      case 'caseys':
        return (<img src={tenant.logo} style={{ maxWidth: 110 }} alt={tenant.tenant} />)
      default:
        return (<img src={tenant.logo} style={{ maxWidth: 79 }} alt={tenant.tenant} />)
    }
  }
  render() {
    const { classes } = this.props
    const { inProgress } = this.state
    const tenant = getTenant()
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {this.getTenantLogo()}
            <img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina" />
          </Typography>
          <Typography style={{ margin: 10 }}>
            Welcome to<br />{window.location.host}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.redirectToSignIn}
            disabled={inProgress}
          >
            Sign In
          </Button>
        </div>


      </Container>
    )
  }
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, {}
)(withStyles(useStyles)(LoggedOut));
