import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { redirectTo, getDomainInfo, isAdminModeActive } from '../../Helpers/basic'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    // color: theme.palette.grey[500],
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    border: `1px solid`,
    borderColor: theme.palette.primary.main,
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch);

export default function AdminViewSwitch() {
  const [state, setState] = React.useState({
    checkedB: isAdminModeActive(),
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);
    let domainDet = getDomainInfo();
    document.cookie = domainDet.domainUserLabel +'_isadmin' + "=" + event.target.checked + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    window.location.reload()
  };

  return (
    <FormGroup>
      <Typography component="div">
        <Typography style={{ fontWeight: 400} }>Application mode</Typography>
        <Grid component="label" container alignItems="center" spacing={1} style={{ color: '#333', fontWeight: 300 }}>
          <Grid item>Employee</Grid>
          <Grid item>
            <AntSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />
          </Grid>
          <Grid item>Admin</Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
}
