import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getDashboardData } from '../../store/Dashboard/actionCreator';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    float: 'left',
    width: '100%'
  },
  uniqueSearchWrapper: {
    padding: '1rem',
    backgroundColor: '#fff',
    position: 'relative',
    height: 340,
    color: theme.palette.text.secondary,
    marginTop: 10
  },
  courseChartDataWrapper: {
    display: 'inline-block',
    textAlign: 'left',
    marginTop: '1px',
    backgroundColor: '#4172a2',
    width: '100%',
    overflowY: 'auto',
    height: '265px'
  },
  tableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'center',
    color: '#fff',
    maxWidth: '133px',
    wordWrap: 'break-word'
  },
  tableHeadCell: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 700,
    padding: 6,
  },
  tableHeadNumberCell: {
    fontSize: '1rem',
    paddingRight: '20px',
    color: '#fff',
    fontWeight: 700,
    padding: 6,
  },
  tableNumberCell: {
    padding: 6,
    paddingRight: '20px',
    fontSize: '0.8rem',
    color: '#fff',
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '-11px 11px 0 0'
  }
});

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]
class UniqueSearchTerms extends React.Component {
  state = {
    prgoressLoader: false,
    selectedRange: 'YTD',
    newChartData: [],
    loadNewData: false
  }

  successCB = res => {
    this.setState({
      prgoressLoader: false,
      loadNewData: true
    })
    if (res && res.results && res.results.length && res.results[0]) {
      this.setState({
        newChartData: res && res.results && res.results[0] && res.results[0].uniqueSearchTerms.list && res.results[0].uniqueSearchTerms.list.length && res.results[0].uniqueSearchTerms.list
      })
    }
  }

  failureCB = err => {
    this.setState({
      prgoressLoader: false,
      newChartData: []
    })
    this.props.showSnackBar({
      state: true,
      message: 'Sorry something went wrong while fetching Unique Search Terms!',
      type: 'error'
    })
  }

  handleChangeOption = (event) => {
    this.setState({
      prgoressLoader: true,
      selectedRange: event.target.value,
      loadNewData: true
    })
    let payload = ''
    if (event.target.value && event.target.value === 'YTD') {
      payload = {}
    } else {
      payload = {
        search_range: event.target.value
      }
    }
    getDashboardData(payload, this.successCB, this.failureCB)
  }

  render() {
    const { classes, uniqueSearchTerms = [], inProgress } = this.props
    const { prgoressLoader, selectedRange, loadNewData, newChartData } = this.state
    const uniqueSearchesData = !loadNewData ? uniqueSearchTerms && uniqueSearchTerms.list && uniqueSearchTerms.list : newChartData
    return (
      <div className={classes.root}>
        <Paper className={classes.uniqueSearchWrapper}>
          <div>
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" style={{ width: '100%', textAlign: 'left', marginTop: '1px' }}>
                Top 5 Unique Search Terms
              </Typography>
              <div className={classes.mtdCtr}>
                <TextField
                  className={classes.mtd}
                  id="progress"
                  select
                  classes={{ outlined: "padding:1px" }}
                  margin="dense"
                  value={selectedRange}
                  onChange={this.handleChangeOption}
                  name="Progress"
                  variant="outlined"
                  disabled={prgoressLoader || inProgress}
                >
                  {listOfSelection && listOfSelection.map((option) => (
                    <MenuItem style={{ padding: '1px', marginLeft: '5px' }} key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className={classes.courseChartDataWrapper}>
              <Table className={classes.table} aria-label="Searchterms">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableHeadCell}>Search Term</TableCell>
                    <TableCell align="right" className={classes.tableHeadNumberCell}>Total Unique Searches</TableCell>
                  </TableRow>
                </TableHead>
                {prgoressLoader || inProgress ? (
                  <Typography style={{ color: '#fff', marginTop: '10px', fontSize: '16px', fontWeight: 500 }}>Loading...</Typography>
                ) : (
                  <TableBody>
                    {uniqueSearchesData && uniqueSearchesData.length ? (
                      <React.Fragment>
                        {uniqueSearchesData.map((row, key) => (
                          <TableRow key={key}>
                            <TableCell scope="row" align="center" className={classes.tableCell}>
                              {row.searchTerm}
                            </TableCell>
                            <TableCell scope="row" align="right" className={classes.tableNumberCell}>
                              {row.count}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} scope="row" align="center" className={classes.tableCell}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(withStyles(useStyles)(UniqueSearchTerms));
