import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
const qs = require('querystring')


export const logActivity = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.course.makeVisitedCourse}`
  axios.post(
    url,
    qs.stringify(payLoad)
  )
  .then(function (response) {
     successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
