const iconColor = "#2e8eec";
const appBlue = "#2e8eec";
const appGrey = "#464444";
const lightGrey = "#f9f9f9";
const appGreen = "#6ac685";
const banner_darkBlue = "#1c234a";
const orange = "#f2903d";
const background_blue = "#f3f6f7";
const background_lightblue = "#f3f3f3";
const egyptian_blue = "#2368a9";
const tory_blue = "#11539f";
const red = "#f24e3d";
const linkWaterAppBar = '#ebf7fa';
const darkBlue = '#044766';
const darkGrey = '#f5f5f5';

// Constant style property
const linkWaterHover = '#e4f7fc';
const scrollBlue = '#c4d4e8';
const pictonBlue = '#2e8eec';
const egyptianBlue = '#0e76a8';
const white = '#fff';
const blackSqueeze = '#f2f8fa';
const linkWater = '#f8fdff';
const linkWaterSel = '#d2f0f8';
const black = '#090909';
const aquaBlue = '#ebf7fa';
const mysticBlue = '#e4ebee';


export const style = {
    brdCrmbPrimary: {
        color: egyptian_blue,
        fontWeight: 500,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    brdCrmbSeparator: {
        color: appBlue,
    },
    brdCrmbSecondary: {
        color: appBlue,
    },
    containerPaddingTop: {
        paddingTop: "2rem",
    },
    logoImg: {
        width: "10rem",
        marginLeft: '1rem',
        cursor: "pointer",
    },
    root: {
        display: "flex",
    },
    iconFrame: {
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
    },
    iconContainer: {
        height: "100%",
        paddingRight: "0.5rem",
    },
    icon: {
        color: iconColor,
        fontSize: "1.5rem",
        padding: "1.8rem 0.5rem 0 0",
    },
    faIcon: {
        color: iconColor,
        fontSize: "1.5rem",
    },
    certIcon: {
        color: iconColor,
        fontSize: "2.5rem",
        padding: "0 0.5rem 0 0",
    },
    certIconSmall: {
        color: iconColor,
        fontSize: "1.5rem",
        padding: "0 0.5rem 0 0",
    },
    completedIcon: {
        color: appGreen,
        fontSize: "1.5rem",
        padding: "1.8rem 0.5rem 1rem 0",
    },
    iconTxt: {
        padding: "1.2rem 0.5rem 1rem 1rem",
        fontSize: "0.8rem",
        color: iconColor,
    },
    courseMainBanner: {
        paddingTop: "2rem",
        maxHeight: "360px",
        width: "100%",
    },
    fontColour: {
        _blue: appBlue,
        _grey: appGrey,
        _green: appGreen,
        _darkBlue: banner_darkBlue,
        _orange: orange,
        _red: red,
        _egyptian_blue: egyptian_blue,
        _tory_blue: tory_blue,
    },
    backgroundColor: {
        _card: lightGrey,
        _screen: background_blue,
        _white: "#FFF",
        _screenLight: background_lightblue,
        _tabHeaderBg: linkWaterAppBar,
        _tabHeaderFnt: darkBlue,
        _chipLoader: darkGrey
    },
    fontSize: {
        _heading: "24px",
        _sideHeading: "20px",
        _labels: "16px",
        _description: "14px",
        _smallDesc: "12px",
        _helperText: "10px",
    },
    iconSize: {
        _small: "1rem",
        _medium: "1.5rem",
        _large: "2rem",
    },
    fontWeight: {
        _heading: "600",
        _sideHeading: "400",
    },
    button: {
        _backgroundColor: appBlue,
        _color: "#FFF",
    },
    fontFamily: {
        _appFont: "Roboto",
    },
    iconColor: {
        _color: iconColor
    }
};

export const colorStyles = {
    background: blackSqueeze,
    bgWhite: white,
    fontColor: pictonBlue,
    btnColor: pictonBlue,
    appTextColor: egyptianBlue,
    btnText: white,
    leftMenuBg: linkWater,
    leftMenuHover: linkWaterHover,
    leftMenuActive: linkWaterSel,
    headerBg: linkWater,
    tabHeaderBg: linkWaterAppBar,
    listSel: linkWaterSel,
    fontBlack: black,
    tabHeaderFnt: darkBlue,
    accordianBg: aquaBlue,
    elementIcon: pictonBlue,
    skeletonLoaderBg: mysticBlue
}

export const theme2 = {
    background: blackSqueeze,
    bgWhite: white,
    fontColor: pictonBlue,
    btnColor: pictonBlue,
    appTextColor: egyptianBlue,
    btnText: white,
    leftMenuBg: linkWater,
    leftMenuHover: linkWaterHover,
    leftMenuActive: linkWaterSel,
    headerBg: linkWater,
    tabHeaderBg: linkWaterAppBar,
    listSel: linkWaterSel,
    fontBlack: black,
    tabHeaderFnt: darkBlue,
    accordianBg: aquaBlue,
    elementIcon: pictonBlue,
    skeletonLoaderBg: mysticBlue
}