import React from 'react';
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CELogo from '../../static/ce.svg';
import { openInNewTab, openFreshworksWidget, redirectTo } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fcfcfc',
    marginTop: 100,
    position: 'fixed',
    bottom: 0
  },
  pageFooter: {
    display: 'inline-block',
    width: '100%',
    textAlign:'center'
  }
}));
export default function Footer() {
  const classes = useStyles();
  const dt = new Date()
  const openFeedBack = (type) => {
    openFreshworksWidget(type)
  }

  const openCE = () => {
    openInNewTab('http://continualengine.com/')
  }
  const onClickUploadCourse = () => {
    redirectTo('https://myathina-hosting.firebaseapp.com/mycourse', false)
  }
  return(
    <div className={classes.root}>
      <footer className={classes.pageFooter}>
        <div>
          <Link component="button" color="inherit" onClick={onClickUploadCourse} style={{
            textAlign: 'center',
            padding: 10,
            width: '100%',
            fontWeight: 'bold',
            color: 'blue',
            display: 'none'
          }}>
            Upload your Course
          </Link>
          <Link component="button" color="inherit" onClick={()=>{openFeedBack('help')}} style={{ color: 'blue' }}>
            Help
          </Link>
          <span style={{ marginRight: '3px', marginLeft: '3px'}}>/</span>
          <Link component="button" color="inherit" onClick={()=>{openFeedBack('feedback')}} style={{ color: 'blue' }}>
            Feedback
          </Link>
        </div>
        <div>
          <Typography variant="overline" gutterBottom style={{ textTransform: 'none', marginLeft: '5px' }}>
            &copy; {dt.getFullYear()}, Powered by
          </Typography>
          <Link onClick={openCE} component="button" title="Continual Engine" color="inherit" className={classes.copyImg}>
            <img src={CELogo} alt="Continual Engine" width="40"/>
          </Link>
        </div>
      </footer>
    </div>
  )
}
