const initState = {
  authDetails: {}
}
export default (state = initState, action) => {
 switch (action.type) {
  case 'FETCH_USER_DETAILS':
    return {
    authDetails: action.payload.user
   }
  case 'USER_LOGOUT':
    return initState
  case 'SET_USER_DETAILS':
    return {
      authDetails: action.payload.user
    }
  default:
   return state
 }
}
