import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { find } from 'lodash';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import HorizontalChartLoader from '../Common/SkeletonLoaders/horizontalChartLoader';
import { fetchDeptAvgSpentChart } from '../../store/Dashboard/actionCreator';
import MembersAvgListTable from './membersAvgListTable';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    float: 'left',
    marginTop: 10,
    width: '100%'
  },
  horizontalChartWrapper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    float: 'left',
    height: '340px',
    width: '100%'
  },
  horizontalChartWrapperMob: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#fff',
    float: 'left',
    height: '100%',
    width: '98%',
  },
  mtd: {
    fontSize: '10px',
    width: '80px'
  },
  mtdCtr: {
    textAlign: 'end',
    margin: '-13px 0px 0px 17px'
  },
}));

export const listOfSelection = [
  {
    id: 1,
    name: 'YTD'
  },
  {
    id: 2,
    name: 'MTD'
  }
]

function AvgSpendingChart(props) {
  const classes = useStyles();
  const [selectedRange, setSelectedRange] = useState('YTD');
  const mobileView = useMediaQuery('(max-width:600px)');
  const [chartData, setChartData] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [membersTable, setMembersTable] = useState({
    labelData: {},
    popup: false
  })

  const renderHorizontalChart = (avgUsersSpentData) => {
    const horizontalChart = document.getElementById('learningHoursChart')
    const onChartItemClick = (e, chartElement) => {
      if (chartElement && chartElement.length) {
        let chartLable = chartElement && chartElement[0] && chartElement[0]._view && chartElement[0]._view.label
        let labelData = find(avgUsersSpentData, { 'label': chartLable })
        setMembersTable({
          labelData: labelData,
          popup: true
        })
      }
    }

    let dataPoints = []
    let labels = []
    let colorArr = []
    for (let i in avgUsersSpentData) {
      labels.push(avgUsersSpentData[i].label)
      dataPoints.push(avgUsersSpentData[i].avg_time)
      colorArr.push('#67DCBB')
    }

    const Chart = props.Chart
    new Chart(horizontalChart, {
      plugins: [{
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            chart.legend.options.labels.padding = 20;
            this.height = this.height + 10;
          };
        },
        afterDatasetDraw: function (chart, easing) {
          let height = (labels.length) * 50
          if (labels.length <= 4) {
            document.getElementById("learningHoursChart").style.height = '250px'
          } else if (labels.length <= 8) {
            document.getElementById("learningHoursChart").style.height = '400px'
          } else {
            document.getElementById("learningHoursChart").style.height = `${height}px`
          }
        }
      }],
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [{
          maxBarThickness: 30,
          categoryPercentage: 1.0,
          barPercentage: 0.5,
          minBarLength: 1,
          label: 'Average Hours',
          data: dataPoints,
          backgroundColor: colorArr,
          borderColor: colorArr,
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 1,
              callback: function (label) {
                if (/\s/.test(label)) {
                  var splited = []
                  splited = label.split(" ")
                  var newLable = []
                  if (splited && splited.length >= 4) {
                    newLable = label.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,1}\b/g)
                  } else {
                    newLable = label.split(" ")
                  }
                  return newLable
                } else {
                  return label;
                }
              },
            },
            gridLines: {
              offsetGridLines: false
            }
          }],
          xAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              fontSize: 11,
              userCallback: function (label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
            scaleLabel: {
              display: false
            }
          }]
        },
        onClick: onChartItemClick,
        legend: {
          display: false
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
        },
        gridLines: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }

  useEffect(() => {
    if (props.avgUsersSpentData && props.avgUsersSpentData.length) {
      setChartData(props.avgUsersSpentData)
      renderHorizontalChart(props.avgUsersSpentData)
    }
  }, [props.avgUsersSpentData])


  //FOR FETCHING AVERAGE TIME SPENT BY TEAMS CALLBACKS
  const successAvgCB = res => {
    setInProgress(false);
    setChartData(res.results);
    renderHorizontalChart(res.results);
  }
  const failureAvgCB = err => {
    setChartData([]);
    setInProgress(false);
    props.showSnackBar({
      state: true,
      message: 'Something went wrong while fetching Average Learning Hours!',
      type: 'error'
    })
  }

  const handleChangeOption = (event) => {
    setInProgress(true);
    setSelectedRange(event.target.value)
    let payload = ''
    if (event.target.value && event.target.value === 'YTD') {
      payload = {
        range: event.target.value,
        value: new Date().getFullYear()
      }
    } else {
      payload = {
        range: event.target.value,
        value: (new Date().getMonth() + 1).toString().padStart(2, "0")
      }
    }
    fetchDeptAvgSpentChart(payload, successAvgCB, failureAvgCB)
  }

  const closeAveSpentMemTable = () => {
    setMembersTable({
      labelData: {},
      popup: false
    })
  }

  return (
    <div className={classes.root}>
      <Paper className={mobileView ? classes.horizontalChartWrapperMob : classes.horizontalChartWrapper}>
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" style={{ width: '100%', textAlign: 'left' }}>
                Average Learning Hours per team member, by Team
              </Typography>
              <div className={classes.mtdCtr}>
                <TextField
                  className={classes.mtd}
                  id="progress"
                  select
                  classes={{ outlined: "padding:1px" }}
                  margin="dense"
                  value={selectedRange}
                  onChange={handleChangeOption}
                  name="Progress"
                  variant="outlined"
                  disabled={props.avgChratLoader || inProgress}
                >
                  {listOfSelection && listOfSelection.map((option) => (
                    <MenuItem style={{ padding: '1px', marginLeft: '5px' }} key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            {props.avgChratLoader || inProgress ? (
              <HorizontalChartLoader />
            ) : (
              <React.Fragment>
                <div class="dashBoardchartWrapper">
                  <div class="dashBoardchartAreaWrapper">
                    <div class="dashBoardchartAreaWrapper2">
                      <canvas id="learningHoursChart" aria-label="Learning Hours Spent By Teams Chart" role="img" height={chartData.length ? "600" : "50"} width="200"></canvas>
                      {chartData && !chartData.length && <p style={{ fontSize: '18px' }}>No data found!</p>}
                    </div>
                  </div>
                </div>
                <div id="main" tabIndex="-1" className="accessibility">
                  <ul style={{ fontSize: '0.7rem', padding: 0 }}>
                    {chartData && chartData.length && chartData.map((row, key) => (
                      <li key={key} style={{ marginRight: 10 }}>
                        <span>{`Total ${row && row.avg_time && row.avg_time} average Hours spent by ${row && row.label && row.label}`}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      {
        membersTable && membersTable.popup ? (
          <MembersAvgListTable closeAveSpentMemTable={closeAveSpentMemTable} data={membersTable} history={props.history} />
        ) : null
      }
    </div >
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(AvgSpendingChart);