import Coursera from '../static/providers/coursera.jpg';
import Edureka from '../static/providers/edureka.png';
import NPTEL from '../static/providers/nptel.jpg';
import Udacity from '../static/providers/udacity.png';
import Udemy from '../static/providers/udemy.png';
import Educative from '../static/providers/educative.png';
import Datacamp from '../static/providers/datacamp.png';
import Futurelearn from '../static/providers/futurelearn.jpg';
import MyAthina from '../static/providers/myAthinaLogo.png';
import ContinualEngine from '../static/ce.png'
import ANSRSOURCE from '../static/providers/ansrsource.png'
import PREPImg from '../static/prep.svg'
import Goniyo from '../static/goniyo_logo.png'
import Zopsmart from '../static/zopsmart_logo.png'
import LinkedIn from '../static/providers/linkedin.png'
import LinuxFoundation from '../static/providers/icons/linuxfoundation.svg'
import Caseys from '../static/caseys.svg'
import Freo from '../static/freo.svg'

export const providerImgMap = {
  Coursera: {
    img: Coursera
  },
  Edureka: {
    img: Edureka
  },
  NPTEL: {
    img: NPTEL
  },
  Udacity: {
    img: Udacity
  },
  Udemy: {
    img: Udemy
  },
  educative: {
    img: Educative
  },
  Educative: {
    img: Educative
  },
  Datacamp: {
    img: Datacamp
  },
  datacamp: {
    img: Datacamp
  },
  Futurelearn: {
    img: Futurelearn
  },
  MyAthina: {
    img: MyAthina
  },
  myathina: {
    img: MyAthina
  },
  Myathina: {
    img: MyAthina
  },
  sandbox: {
    img: MyAthina
  },
  continualengine: {
    img: ContinualEngine
  },
  ContinualEngine: {
    img: ContinualEngine
  },
  'Continual Engine': {
    img: ContinualEngine
  },
  Ansrsource: {
    img: ANSRSOURCE
  },
  'ANSR SOURCE': {
    img: ANSRSOURCE
  },
  ansrsourcegems: {
    img: ANSRSOURCE
  },
  'ANSR Source': {
    img: ANSRSOURCE
  },
  'prep': {
    img: PREPImg
  },
  'PREP': {
    img: PREPImg
  },
  'Goniyo': {
    img: Goniyo
  },
  'goniyo': {
    img: Goniyo
  },
  'Zopsmart': {
    img: Zopsmart
  },
  'zopsmart': {
    img: Zopsmart
  },
  'caseys': {
    img: Caseys
  },
  'Casey': {
    img: Caseys
  },
  'Linkedin': {
    img: LinkedIn
  },
  'Linkedin Learning': {
    img: LinkedIn
  },
  'Linuxfoundation': {
    img: LinuxFoundation
  },
  'freo': {
    img: Freo
  }
}
export const enableDetailsPage = false;
export const enableShareOption = {
  enabled: false,
  options: {
    url: false,
    fb: false,
    ln: false
  }
}
export const courseComparison = {
  enabled: true,
  limit: 3,
}
