import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import { getLoggedInStatus, getUrlParams } from '../src/Helpers/basic'

//FOR CHECKING THE MAIL REDIRECTIONS AFTER LOGIN
if (!getLoggedInStatus()) {
    const url = getUrlParams(window.location.href, 'type')
    if (url && url.length) {
        localStorage.setItem('mailRedirectRoute', url)
    }
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        getLoggedInStatus()
            ? <Component {...props} />
            : <Redirect to='/init' />
    )} />
)
