import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const fetchMembers = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.members.listUrl}?offset=0&number=10`
  axios.get(
    url
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const getDashboardData = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.dashboardDataUrl}`
  axios.get(
    url, { params: payLoad }
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchMyDocs = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.trainingDataUrl}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}


//FOR FETCHING THE MANDATED COURSES AND USERS INFO
export const fetchMandateCoursesData = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.mandateCoursesData}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockCrsReport = {
  "results": {
    "mandate_course": [
      {
        "label": "Not Started",
        "value": 16
      },
      {
        "label": "In Progress",
        "value": 10
      },
      {
        "label": "Completed",
        "value": 11
      },
      {
        "label": "Certified",
        "value": 0
      },
      {
        "label": "Overdue",
        "value": 20
      }
    ]
  }
}
//FOR FETCHING THE SINGLE MANDATED COURSE REPORT
export const fetchSingleMndtCrsReport = (payload, successCallBack, failureCallBack, courseId, dateRange) => {
  const url = `${apiConfig.dashboard.fetchSingleMndtCrsReport}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data, courseId, dateRange)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockCrsReport, courseId, dateRange)
    })
}

const mockUserData = {
  "results": {
    "total_count": 30,
    "employee_details": [
      { "id": 12, "email": "kalyan.kumar@continualengine.com", "name": 'Kalyan Kumar', "due_date_mandate": "2020-09-03T18:30:00Z" },
      { "id": 8, "email": "ganesan.d@continualengine.com", "name": 'Ganesan D', "due_date_mandate": "2020-09-03T18:30:00Z" }
    ]
  }
}
//FOR FETCHING THE SINGLE COURSE USERS STATUS REPORT INFO
export const fetchMndtCrsUsersStatusReport = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.fetchMndtCrsUsersStatusReport}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockUserData)
    })
}

//FOR FETCHING THE CSV FILE OF SINGLE COURSE USERS STATUS REPORT INFO
export const fetchCrsUsersStatusReportCSV = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.fetchCrsUsersStatusReportCSV}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockUserData)
    })
}

const mockAvgChrtYTD = {
  "results": [
    {
      "id": 4,
      "label": 'Quality Control',
      "avg_time": 27
    },
    {
      "id": 1,
      "label": 'Qual Content',
      "avg_time": 24
    },
    {
      "id": 2,
      "label": 'eLearning UAT',
      "avg_time": 20
    },
    {
      "id": 3,
      "label": 'Human Resources',
      "avg_time": 9
    }
  ]
}

const mockAvgChrtMTD = {
  "results": [
    {
      "id": 4,
      "label": 'Quality Control',
      "avg_time": 18
    },
    {
      "id": 1,
      "label": 'Qual Content',
      "avg_time": 12
    },
    {
      "id": 2,
      "label": 'eLearning UAT',
      "avg_time": 10
    },
    {
      "id": 3,
      "label": 'Human Resources',
      "avg_time": 5
    }
  ]
}
//FOR FETCHING AVE SPENT TIME BY USERS
export const fetchDeptAvgSpentChart = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.fetchDeptAvgSpentChart}`
  //const url = `${apiConfig.dashboard.fetchDeptAvgSpentCharts}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //failureCallBack(error)
      if (payLoad.range === 'YTD') {
        successCallBack(mockAvgChrtYTD)
      } else {
        successCallBack(mockAvgChrtMTD)
      }
    })
}

const mockAvgMemList1 = {
  "total_count": 9,
  "results": [
    {
      "id": 1,
      "name": 'Kalyan Kumar',
      "courses_completed": 2,
      "hours_spent": 5
    },
    {
      "id": 2,
      "name": 'Ganesan D',
      "courses_completed": 4,
      "hours_spent": 23
    },
    {
      "id": 3,
      "name": 'Dhana Prabhu',
      "courses_completed": 1,
      "hours_spent": 3
    },
    {
      "id": 4,
      "name": 'Deepak',
      "courses_completed": 3,
      "hours_spent": 48
    }
  ]
}
const mockAvgMemList2 = {
  "total_count": 5,
  "results": [
    {
      "id": 1,
      "name": 'Ranjeeth',
      "courses_completed": 2,
      "hours_spent": 5
    },
    {
      "id": 2,
      "name": 'Ravi',
      "courses_completed": 4,
      "hours_spent": 23
    },
    {
      "id": 3,
      "name": 'Sowjanya',
      "courses_completed": 1,
      "hours_spent": 3
    },
    {
      "id": 4,
      "name": 'Prathap',
      "courses_completed": 3,
      "hours_spent": 48
    },
  ]
}
const mockAvgMemList3 = {
  "total_count": 5,
  "results": [
    {
      "id": 1,
      "name": 'Vignesh',
      "courses_completed": 2,
      "hours_spent": 4
    },
    {
      "id": 2,
      "name": 'Prasanna Kumar',
      "courses_completed": 1,
      "hours_spent": 10
    },
    {
      "id": 3,
      "name": 'Lalitha',
      "courses_completed": 1,
      "hours_spent": 10
    },
    {
      "id": 4,
      "name": 'Anusha',
      "courses_completed": 3,
      "hours_spent": 10
    },
  ]
}
const mockAvgMemList4 = {
  "total_count": 5,
  "results": [
    {
      "id": 1,
      "name": 'Laukik Sarode',
      "courses_completed": 2,
      "hours_spent": 10
    },
    {
      "id": 2,
      "name": 'Deepak Kumar',
      "courses_completed": 4,
      "hours_spent": 48
    },
    {
      "id": 3,
      "name": 'Prabhu',
      "courses_completed": 1,
      "hours_spent": 3
    },
    {
      "id": 4,
      "name": 'Prasanth Potnuru',
      "courses_completed": 3,
      "hours_spent": 48
    },
  ]
}

//FOR FETCHING SINGLE DEPARTMENT MEMBERS AVG SPENT TIME DETAILS
export const fetchDeptAvgMemList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.fetchDeptAvgMemList}`
  //const url = `${apiConfig.dashboard.fetchDeptAvgMemLists}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //failureCallBack(error)
      if (payLoad.department_id === 1) {
        successCallBack(mockAvgMemList1)
      } else if (payLoad.department_id === 2) {
        successCallBack(mockAvgMemList2)
      } else if (payLoad.department_id === 3) {
        successCallBack(mockAvgMemList3)
      } else {
        successCallBack(mockAvgMemList4)
      }
    })
}

//FOR FETCHING DASHBOARD POLICY CHART DATA
export const fetchPolicyChartData = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.dashboard.fetchPolicyChartData}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}