
import Loadable from 'react-loadable';
import SkillMapLoader from '../Components/Common/SkeletonLoaders/LazyLoaders/skillMapLoader';

const Profile = Loadable({
    loader: () => import('../Components/Profile'),
    loading() {
        return <SkillMapLoader />
    }
});

const Members = Loadable({
    loader: () => import('../Components/Members'),
    loading() {
        return <SkillMapLoader />
    }
});

const Rewards = Loadable({
    loader: () => import('../Components/Rewards'),
    loading() {
        return <SkillMapLoader />
    }
});

const MyAthinaSearch = Loadable({
    loader: () => import('../Components/Search'),
    loading() {
        return <SkillMapLoader />
    }
});

const MyLearningPath = Loadable({
    loader: () => import('../Components/MyLearningPath'),
    loading() {
        return <SkillMapLoader />
    }
});

const MyCourses = Loadable({
    loader: () => import('../Components/MyCourses'),
    loading() {
        return <SkillMapLoader />
    }
});

const ApprovalRequests = Loadable({
    loader: () => import('../Components/ApprovalRequests'),
    loading() {
        return <SkillMapLoader />
    }
});

const VerifyApprovals = Loadable({
    loader: () => import('../Components/VerifyApprovals'),
    loading() {
        return <SkillMapLoader />
    }
});

const TrainingDocs = Loadable({
    loader: () => import('../Components/TrainingDocs'),
    loading() {
        return <SkillMapLoader />
    }
});

const MyDocs = Loadable({
    loader: () => import('../Components/MyDocs'),
    loading() {
        return <SkillMapLoader />
    }
});

const RedirectPage = Loadable({
    loader: () => import('../Components/RedirectPage'),
    loading() {
        return <SkillMapLoader />
    }
});

const LearningMaterials = Loadable({
    loader: () => import('../Components/LearningMaterials'),
    loading() {
        return <SkillMapLoader />
    }
});

const UserProfile = Loadable({
    loader: () => import('../Components/UserProfile'),
    loading() {
        return <SkillMapLoader />
    }
});

const SkillMap = Loadable({
    loader: () => import('../Components/SkillMap'),
    loading() {
        return <SkillMapLoader />
    }
});

const RecommendCourse = Loadable({
    loader: () => import('../Components/Recommend'),
    loading() {
        return <div></div>
    }
});

const ManagingBudget = Loadable({
    loader: () => import('../Components/ManageBudget'),
    loading() {
        return <SkillMapLoader />
    }
});


const MyBudget = Loadable({
    loader: () => import('../Components/MyBudget'),
    loading() {
        return <SkillMapLoader />
    }
});

const AppSettings = Loadable({
    loader: () => import('../Components/AccountSettings'),
    loading() {
        return <SkillMapLoader />
    }
});
const SkillIndex = Loadable({
    loader: () => import('../Components/SkillIndex'),
    loading() {
        return <SkillMapLoader />
    }
});
const MyFeed = Loadable({
    loader: () => import('../Components/MyFeed'),
    loading() {
        return <SkillMapLoader />
    }
});
const MyEvents = Loadable({
    loader: () => import('../Components/MyEvents'),
    loading() {
        return <SkillMapLoader />
    }
});

const MyCertificates = Loadable({
    loader: () => import('../Components/MyCertificates'),
    loading() {
        return <SkillMapLoader />
    }
});

const ExternalAssessments = Loadable({
    loader: () => import('../Components/ExternalAssessments'),
    loading() {
        return <SkillMapLoader />
    }
})

const ManageClients = Loadable({
    loader: () => import('../Components/ManageClients'),
    loading() {
        return <SkillMapLoader />
    }
})
const ManageCoupons = Loadable({
    loader: () => import('../Components/ManageCoupons'),
    loading() {
        return <SkillMapLoader />
    }
})
const ClientDashboard = Loadable({
    loader: () => import('../Components/ClientDashboard'),
    loading() {
        return <SkillMapLoader />
    }
})
const ManageGroups = Loadable({
    loader: () => import('../Components/ManageGroups'),
    loading() {
        return <SkillMapLoader />
    }
})
const ManageGroupLearners = Loadable({
    loader: () => import('../Components/ManageGroupLearners'),
    loading() {
        return <SkillMapLoader />
    }
})
const CouponDashboard = Loadable({
    loader: () => import('../Components/CouponDashboard'),
    loading() {
        return <SkillMapLoader />
    }
})


export {
    Members,
    Profile,
    Rewards,
    MyAthinaSearch,
    MyLearningPath,
    MyCourses,
    ApprovalRequests,
    VerifyApprovals,
    TrainingDocs,
    MyDocs,
    RedirectPage,
    LearningMaterials,
    UserProfile,
    SkillMap,
    RecommendCourse,
    ManagingBudget,
    MyBudget,
    AppSettings,
    SkillIndex,
    MyFeed,
    MyEvents,
    MyCertificates,
    ExternalAssessments,
    ManageClients,
    ManageCoupons,
    ClientDashboard,
    ManageGroups,
    ManageGroupLearners,
    CouponDashboard
};
