import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
const qs = require('querystring')


export const checkUserAuth = (payload, successCallBack, failureCallBack) => dispatch => {
  const url =`${apiConfig.userAuth.etaAuth}`
  const header = { headers: { 'Authorization': 'Token ' + payload.etaToken } }
  axios.post(
    url, {}, header
  )
  .then(function (response) {
    const res = response.data
    const acceptableDomain = res.user.email.split('@')[1]
    res.user.organization.acceptableDomain = acceptableDomain
    dispatch( { type: 'SET_USER_DETAILS', payload: res })
    successCallBack(res)
  })
  .catch(function (error) {
    dispatch(
      {
        type: 'SET_USER_DETAILS',
        payload: []
      }
    )
    failureCallBack(error)
  })
}
export const login_1 = (payLoad, successCallBack, failureCallBack)  => dispatch => {
  // const url =`${apiConfig.userAuth.loginUrl}`
  const url =`http://localhost:8080/assert`
  const req = qs.stringify({ SAMLRequest: payLoad.SAMLResponse })
    axios.post(
      url, req
    )
    .then(function (response) {
      const res = response.data
      const acceptableDomain = res.user.email.split('@')[1]
      res.user.organization.acceptableDomain = acceptableDomain
      dispatch( { type: 'SET_USER_DETAILS', payload: res })
      successCallBack(res)
    })
    .catch(function (error) {
      dispatch(
        {
          type: 'SET_USER_DETAILS',
          payload: []
        }
      )
      failureCallBack(error)
    })

}

export const login = (payLoad, successCallBack, failureCallBack)  => dispatch => {
  const url =`${apiConfig.userAuth.loginUrl}`
  // const url =`http://localhost:8080/assert`
    axios.post(
      url, qs.stringify(payLoad), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    )
    .then(function (response) {
      const res = response.data
      const acceptableDomain = res.user.email.split('@')[1]
      res.user.organization.acceptableDomain = acceptableDomain
      dispatch( { type: 'SET_USER_DETAILS', payload: res })
      successCallBack(res)
    })
    .catch(function (error) {
      dispatch(
        {
          type: 'SET_USER_DETAILS',
          payload: []
        }
      )
      failureCallBack(error)
    })

}
export const logout = (payLoad, successCallBack, failureCallBack)  => dispatch => {
  const url =`${apiConfig.userAuth.logoutUrl}`
    axios.post(
      url, qs.stringify(payLoad), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    )
    .then(function (response) {
      dispatch( { type: 'USER_LOGOUT', payload: response.data })
    })
    .catch(function (error) {
      dispatch(
        {
          type: 'USER_LOGOUT',
          payload: []
        }
      )
      successCallBack(error)
      // failureCallBack()
    })

}

export const requestForgotPassword = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userAuth.reqChangePasswordUrl}`
  axios.post(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
export const changePassword = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.userAuth.changePasswordUrl}`
  axios.post(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error.response)
  })
}
