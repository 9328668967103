import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { logout } from '../../store/Auth/actionCreator';
import AppLayout from '../AppLayout';
import LeftMenu from '../AppLayout/leftMenu';
import Dashboard from '../Dashboard';
import { getDomainInfo } from '../../Helpers/basic';
import { responsiveAlternateRender as ResponsiveAlternateRender } from '../../Helpers/responsiveRender';

const useStyles = theme => ({
  container: {
    padding: 0,
    backgroundColor: '#f5f5f5'
  }
});
class Home extends React.Component {
  onLogout = () => {
    this.props.logout(
      {},
      res => {
        let cookieDetails = getDomainInfo();
        document.cookie = cookieDetails.domainTokenLabel + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + cookieDetails.domainName;
        document.cookie = cookieDetails.domainUserLabel + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + cookieDetails.domainName;
        document.cookie = cookieDetails.domainUserLabel + '_isadmin' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + cookieDetails.domainName;
        localStorage.setItem('user_active_menus', []);
      },
      () => {

      }
    )
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.container}>
          <AppLayout history={this.props.history} />
          <Grid container style={{ backgroundColor: '#fff' }}>
            <Hidden only={['xs', 'sm']}>
              <Grid item md={2} lg={2}>
                <LeftMenu history={this.props.history} selectedMenu={'Learning Dashboard'} />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Dashboard history={this.props.history} />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails
  }
}
export default connect(
  mapStateToProps, { logout }
)(withStyles(useStyles)(Home));
