import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProfileMenu from './profileMenu';
import { responsiveRender as ResponsiveRender } from '../../Helpers/responsiveRender'
import MyAthinaLogoSVG from '../../static/myathina-logo-1.svg';
import { logout } from '../../store/Auth/actionCreator'
import Footer from '../Footer';
import { authenticatedMenus } from './menuList';
import AdminViewSwitch from './adminViewSwitch';
import CourseComparator from "../Common/CourseComparator";
import { logUserActivity, redirectTo, getDomainInfo, getUSerDetails, getUSerType, hasSearchAccess } from '../../Helpers/basic'
import { getTenant } from '../../Configs/tenantConfig'
import { colorStyles } from '../../styles/style';
import { courseComparison } from '../../Configs/courseConfig';
import Notifications from './notifications';
import { fetchProfilePic } from '../../store/Profile/actionCreator';
import vtuLogo from '../../static/vtu_logo.png';
import AutomatedMenuListing from './automatedMenus';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 60,
  },
  grow: {
    flexGrow: 1,
  },
  list: {
    width: 200
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#4172a2'
  },
  selectedMenu: {
    backgroundColor: '#ccc',
  },
  adminSwitchWrapper: {
    marginRight: 40,
    padding: 11,
    backgroundColor: '#fff',
    color: '#333',
    border: '1px solid #ccc'
  },
  scrollWrapper: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'scroll',
    },
    marginTop: '-26px',
  },
  accordionHeader: {
    color: '#2e8eec',
    textAlign: 'initial',
    fontWeight: 500,
    fontSize: '15px'
  },
  accordionWrapper: {
    backgroundColor: colorStyles.leftMenuBg,
  }
});

class AppLayout extends React.Component {
  state = {
    left: false,
    appMenus: authenticatedMenus(),
    toOpenCourseComparator: false,
    showNotifications: false
  }

  successProfileCB = () => { }
  failureProfileCB = () => { }

  componentDidMount() {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide')
    }
    this.setState({
      appMenus: authenticatedMenus()
    })
    if (getTenant().showProfilePicture) {
      this.props.fetchProfilePic({}, this.successProfileCB, this.failureProfileCB)
    }
  }

  onMenuClick = menu => {
    let menuName = ''
    //FOR CHECKING THE MENU NAME
    if (getTenant().showAutomatedActiveMenus) {
      menuName = menu.name
    } else {
      menuName = menu.menu
    }

    if (menuName === "Author Courses") {
      const url = getDomainInfo().hostingURL
      redirectTo(url, true)
    } else {
      const menuTrackingName = menuName.toLowerCase().replace(' ', '-')
      logUserActivity(
        {
          action: menuName ? menuName : 'left-menu-click',
          url: window.location.href,
          object_name: '',
          element_id: menu.id,
          element_name: menuTrackingName,
          element_class: "sidebar-item-click"
        }
      )
      this.props.history.push(menu.path)
    }
  }

  toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ left: open });
  }

  sideList = side => {
    const { classes } = this.props
    return (
      <div
        className={classes.list}
        role="presentation"
        onClick={this.toggleDrawer(side, false)}
        onKeyDown={this.toggleDrawer(side, false)}
      >
        {getTenant().showAutomatedActiveMenus ? (
          <AutomatedMenuListing
            authenticatedMenus={authenticatedMenus}
            selectedMenu={this.props.selectedMenu}
            onMenuClick={this.onMenuClick}
          />
        ) : (
          <React.Fragment>
            {(hasSearchAccess() || getDomainInfo().client === 'caseys') && <Accordion defaultExpanded className={classes.accordionWrapper}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#2e8eec' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordionHeader}>My Organization</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List style={{ marginTop: '-26px' }}>
                  {this.state.appMenus.map((data, index) => (
                    <React.Fragment key={index} >
                      {data.enableView && data.category === 'My Organization' ?
                        <ListItem button style={{ marginTop: data.subMenu ? '-163px' : null }} onClick={() => { this.onMenuClick(data) }} className={this.props.selectedMenu === data.menu ? classes.selectedMenu : null}>
                          <ListItemIcon style={{ color: '#2e8eec', minWidth: 36 }}>
                            {data.icon}
                          </ListItemIcon>
                          <ListItemText primary={data.menu} />
                        </ListItem>
                        : null}
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>}
            <Accordion defaultExpanded style={{ marginBottom: '50px', backgroundColor: colorStyles.leftMenuBg }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#2e8eec' }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.accordionHeader}>Me</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.scrollWrapper}>
                  {this.state.appMenus.map((data, index) => (
                    <React.Fragment key={index} >
                      {data.enableView && data.category === 'Me' ?
                        <ListItem button style={{ marginTop: data.subMenu ? '-161px' : null }} onClick={() => { this.onMenuClick(data) }} className={this.props.selectedMenu === data.menu ? classes.selectedMenu : null}>
                          <ListItemIcon style={{ color: '#2e8eec', minWidth: 36 }}>
                            {data.icon}
                          </ListItemIcon>
                          <ListItemText primary={data.menu} />
                        </ListItem>
                        : null}
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        )}
        <Footer />
      </div>
    )
  }

  mobileMenuIcon = (mediaCheckStr, componentToRender) => {
    return <ResponsiveRender mediaCheckStr={mediaCheckStr} componentToRender={componentToRender} />
  }

  mobileMenu = () => {
    const { classes } = this.props
    const { left } = this.state
    return (
      <div>
        <IconButton edge='start' className={classes.menuButton} onClick={this.toggleDrawer('left', true)}><MenuIcon /></IconButton>
        <SwipeableDrawer
          open={left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          {this.sideList('left')}
        </SwipeableDrawer>
      </div>
    )
  }

  getCourseToCompare = () => {
    let courseToCompare = JSON.parse(localStorage.getItem('courseToCompare'))
    return courseToCompare ? courseToCompare : []
  }

  getCourseCompareNotice = () => {
    const courseToCompare = this.getCourseToCompare()
    if (!courseToCompare.length) {
      return 'No courses to compare'
    } else {
      return courseToCompare.length === 1 ? `${courseToCompare.length} course to compare` : `${courseToCompare.length} courses to compare`
    }
  }
  openCourseComparator = () => {
    this.setState({ toOpenCourseComparator: true })
  }

  onCloseCourseComparator = () => {
    this.setState({ toOpenCourseComparator: false })
  }

  getTenantLogo = () => {
    const tenant = getTenant();
    const userInfo = getUSerDetails();
    let userDept = userInfo.department && Object.keys(userInfo.department).length > 0 && userInfo.department.id;
    switch (tenant.shortName) {
      case 'CE':
      case 'DEMO':
      case 'sandbox':
        return (<img src={tenant.logo} style={{ width: 86, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'ZS':
        return (<img src={tenant.logo} style={{ width: 150, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'FB':
        return (<img src={tenant.logo} style={{ width: 128, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'PREP':
        return (<img src={userDept == 4 ? vtuLogo : tenant.logo} style={{ width: 128, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'ansrsourcegems':
        return (<img src={tenant.logo} style={{ width: 128, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'ANSR':
        return (<img src={tenant.logo} style={{ width: 128, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'caseys':
        return (<img src={tenant.logo} style={{ width: 100, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      case 'freo':
        return (<img src={tenant.logo} style={{ width: 90, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
      default:
        return (<img src={tenant.logo} style={{ width: 45, marginRight: 10 }} alt={`${tenant.tenant} logo`} />)
    }
  }
  render() {
    const { classes } = this.props
    const tenant = getTenant()
    const { showNotifications } = this.state
    // const enableAdminSwitch = (getUSerType() === 'admin')
    const enableAdminSwitch = false
    return (
      <div className={classes.root}>
        <AppBar style={{ top: 0, backgroundColor: tenant.shortName === 'ZS' ? '#000' : colorStyles.headerBg, height: 65 }} >
          <Toolbar>
            {this.mobileMenuIcon('(max-width:959px)', this.mobileMenu())}
            {this.getTenantLogo()}
            <img src={MyAthinaLogoSVG} style={{ width: tenant.shortName === 'FB' ? 123 : 138 }} alt="myathina logo" />
            <div className={classes.grow} />
            {showNotifications ? (
              <div className={classes.sectionDesktop}>
                <div>
                  <Notifications history={this.props.history} />
                </div>
              </div>
            ) : null}
            {enableAdminSwitch ? (
              <div className={classes.adminSwitchWrapper}>
                <div>
                  <AdminViewSwitch history={this.props.history} />
                </div>
              </div>
            ) : null}
            <div className={classes.sectionDesktop}>
              <div>
                <ProfileMenu history={this.props.history} />
              </div>
            </div>
            {this.props.showCompare ? (
              <div id="courseCompareNotice" aria-label="Course comparator" style={{ display: this.getCourseToCompare().length ? 'block' : 'none' }} onClick={this.openCourseComparator}>
                {this.getCourseCompareNotice()}
              </div>
            ) : null}
          </Toolbar>
        </AppBar>
        {courseComparison.enabled && this.state.toOpenCourseComparator ? (
          <CourseComparator open={this.state.toOpenCourseComparator} onCloseCourseComparator={this.onCloseCourseComparator} />
        ) : null}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails
  }
}
export default connect(
  mapStateToProps, { logout, fetchProfilePic }
)(withStyles(useStyles)(AppLayout));
