import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { colorStyles } from '../../../../styles/style';
export default function SkillmapProfile(props) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} xm={12} md={12} lg={12}>
          <Skeleton variant="circle" width={140} color={colorStyles.skeletonLoaderBg} height={140} style={{ marginLeft: '54px' }} />
        </Grid>
      </Grid>
    </div>
  )
}