import { getEnv } from '../Helpers/basic'
const apiDomain = env => {
  switch (env) {
    case 'prod':
      return '//api.myathina.com/'
    case 'clone':
      return '//104.211.75.247/'
    case 'goniyo_dev':
    case 'ce_dev':
    case 'prep_dev':
    case 'zopsmart_dev':
    case 'sandbox_dev':
    case 'falabella_dev':
    case 'ansrsource_dev':
    case 'demo_dev':
    case 'caseys_dev':
    case 'freo_dev':
    case 'ansrsourcegems_dev':
      return 'https://wsre.api.moocpedia.com/'
    case 'dev':
    case 'test':
    default:
      return 'https://test.api.moocpedia.com/'
    // return '//52.172.131.129:8000/'
  }
}
const domain = apiDomain(getEnv());
export const apiConfig = {
  affiliateId: 'R1sOYsaeUzM',
  affiliateProviders: ['Datacamp'],

  activityLog: {
    logUrl: `${domain}accounts/activity/`
  },
  userAuth: {
    loginUrl: `${domain}user/authenticate/`,
    logoutUrl: `${domain}user/logout/`,
    reqChangePasswordUrl: `${domain}api/password_reset/`,
    changePasswordUrl: `${domain}api/password_reset/confirm/`,
    etaAuth: `${domain}accounts/auth/profile/`
  },
  dashboard: {
    dashboardDataUrl: `${domain}dashboard/courses/report/`,
    dashboardCustomerData: `${domain}dashboard/customer/report/`, //FOR NUMBER RANGE FILTER OLD API not in use
    trainingDataUrl: `${domain}dashboard/documents/report/`,
    mandateCoursesData: `${domain}dashboard/mandate/report/`, //FOR FETCHING THE MANDATED COURSES
    fetchDeptAvgSpentChart: `${domain}dashboard/learning/`, // /hours/ FOR FETCHING THE TEAMS AVG SPENT TIME CHART
    fetchDeptAvgMemList: `${domain}dashboard/learning/`, //FOR FETCHING THE DEPARTMENT MEMBERS LIST AVG SPENT TIME
    fetchPolicyChartData: `${domain}dashboard/policies/report/`, //for fetching dashboard policy data
    fetchSingleMndtCrsReport: `${domain}dashboard/mandate/courselevel/report/`, //FOR FETCHING THE REPORT OF ONE MANDATED COURSE
    fetchMndtCrsUsersStatusReport: `${domain}dashboard/mandate/courselevel/status/`, //FOR FETCHING SINGLE COURSE STATUS BASED EMP INFO
    fetchCrsUsersStatusReportCSV: `${domain}dashboard/mandate/courselevel/status/` //for getting users status csv
  },
  teams: {
    listURL: `${domain}accounts/fetch_team_list/`
  },
  members: {
    enableAddMember: false,
    // listUrl: `${domain}accounts/users/`,
    listUrl: `${domain}accounts/userprofile/reportees/`,
    addMembers: `${domain}accounts/register/`,
    toggleAddMemberAccess: `${domain}accounts/userprofile/`,
    removingUser: `${domain}accounts/userprofile/`,
    membersList: `${domain}accounts/members/`,
    orgMembersList: `${domain}accounts/org-members/`
  },
  profile: {
    fetch: `${domain}accounts/userprofile/`,
    update: `${domain}accounts/user/`,
    fetchSkills: `${domain}accounts/skills/`,
    changePassword: `${domain}accounts/dwphc/`,
    updateProfilePic: `${domain}accounts/user_profile_avatar/`,
    fetchProfilePic: `${domain}accounts/user_profile_avatar/`,
    removeProfilePic: `${domain}accounts/user_profile_avatar/`
  },
  rewards: {
    fetchRewards: `${domain}rewards/course/approve/display/`,
    claimRewardPoints: `${domain}rewards/course/approve/redeem/`
  },
  course: {
    search: {
      redirectPageTime: 3000,
      searchUrl: `${domain}v1/courses/search/`,
      //FOR fetch LISTING
      fetchArticles: `${domain}v1/courses/search_articles/`,
      fetchWebPages: `${domain}v1/courses/search_google/`,
      fetchVideosData: `${domain}v1/courses/search_youtube/`,
      //actions
      articleLikeUnlike: `${domain}v1/courses/search_like/`,
      articlesRecommend: `${domain}v1/courses/search_recommend/`,
      articlesPostCommnet: `${domain}v1/courses/search_comment/`,
      articlesFetchComments: `${domain}v1/courses/search_comment_fetch/`,
      fetchAddedUserComment: `${domain}v1/courses/fetch_comment/`,

      resultsPerPage: 10,
      searchRecommendedUrl: `${domain}course/track/?level=1`,
      detailsRecommendedUrl: `${domain}course/track/?level=2`,
      recommendToUrl: `${domain}accounts/usercourse/recommend/`,
      recommendedCourseList: `${domain}accounts/recommended_course_list/`,
      recommendTracking: `${domain}accounts/recommended_course_notification/`,
      recommendedCourseList: `${domain}accounts/recommended_course_notification/`,
    },
    detailsUrl: `${domain}v1/course`,
    makeVisitedCourse: `${domain}accounts/activity/`,
    relatedCourses: `${domain}v1/courses/search/`,
    myCourses: {
      mycourses: `${domain}accounts/usercourse/`,
      archiveVistedCourse: `${domain}accounts/usercourse/`,
      withdrawApprovalReq: `${domain}accounts/usercourse/`,
      requestToExtendDueDate: `${domain}accounts/extend/`,
      requestToExternalCourse: `${domain}accounts/usercourse/external/`,
    },
    learningMaterials: {
      learningMaterials: `${domain}hosting/course/progress/list/`,
      CourseCategory: `${domain}hosting/category/`,
    }
  },
  approvalrequests: {
    dueDateExtensionRequests: `${domain}accounts/extend/view/`,
    updateDueDate: `${domain}accounts/extend/verify/`,
    fetchTabCounts: `${domain}dashboard/courses/tabcount/`,
    fetchMngrAppReq: `${domain}dashboard/courses/pending/`,
    fetchLeaderAppReq: `${domain}dashboard/leader/`
  },
  myLearningPath: {
    recommendedCourse: `${domain}course/path/`,
    //FOR MYPATH OPRIMIZATION
    mypathRecGetResults: `${domain}course/learning/view/`,
    mypathLearningGoalGetResults: `${domain}course/learninggoal/view/`,
    mypathRecPost: `${domain}course/path/`,
  },
  trainingDocs: {
    nextButtonActivation: 8000,
    //nextButtonActivation: 1000,
    policyDocNextButtonActivation: 1000,
    trainingUrl: `${domain}docs/user/`,
    completeTrainingUrl: `${domain}training/path/`,
    finishButton: `${domain}training/path/`,
    pdfImageViewer: `${domain}docs/log/`,
    fetchAssessment: `${domain}training/assessment/`,
    submitAssessment: `${domain}assessment/user/`,
  },
  myDocs: {
    fetchDepartments: `${domain}docs/deptlist/`,
    myDocsUrl: `${domain}docs/training/`,
    editDoc: `${domain}docs/`,
    nextButton: `${domain}docs/log/`,
    statusForDoc: `${domain}docs/users/?trainingId=`,
    postAssessment: `${domain}assessment/construct/`,

    //new docs library flow
    fetchDocsLibraryTabCounts: `${domain}docs/tabcounts/`, //FOR FETCHING THE DOCS LIBRARY TAB COUNTS
    fetchDocsLibraryFiles: `${domain}docs/files/`, //FOR FETCHING THE TAB DATA BASED ON QUERY PARAMS
    deleteDocsLibraryFile: `${domain}docs/files/`, //FOR DELETING THE DOC LIBRARY FILE BASED ON ID AND TYPE
    uploadVideo: `${domain}docs/video/`, //FOR UPLOAD VIDEO
    likeUnlikeDocsVideo: `${domain}docs/like_status/`, //FOR LIKE OR DISLIKE OF VIDEO IN VIMEO
    postVideoTrackInfo: `${domain}docs/video/tracking/`, //FOR POSTING THE VIMEO DOCS VIDEO DETAILS
    getVideoTrackInfo: `${domain}docs/video/tracking/`,//FOR GETTIGN THE VIMEO DOCS VIDEO DETAILS
    updateVideo: `${domain}docs/video/update/`, //FOR UPDATE VIDEO
    searchDocsLibraryFiles: `${domain}docs/search/`, //FOR SEARCH QUERY ON ALL THE TABS
    courseCategory: `${domain}hosting/category/`, //FOR FETCHING THE COURSE CATEGORIES LIST
    fetchCourseTags: `${domain}hosting/skill/`, //FOR FETCHING THE COURSE TAGS LIST
    videoUploadSize: 629000000,
    transcriptSize: 200097152,
    thumbnailSize: 2097152,

    uploadDocsVideoChunks: `${domain}docs/chunk/video/`
  },

  googleAnalytics: {
    id: 'UA-152754387-1'
  },
  userProfile: {
    fetchComponents: `${domain}profile/render/`,
    fetchRoles: `${domain}profile/role/`,
    fetchSkiils: `${domain}profile/skill/`,
    fetchInterest: `${domain}profile/interest/`,
    worktimeallocation: `${domain}profile/work/`,
    submitDetails: `${domain}profile/create/`
  },
  skillMap: {
    fetchMainNodeFunctions: `${domain}profile/funtion_domain/`,
    fetchFunctionChartData: `${domain}profile/funtion_domain/`,
    fetchParetoChartData: `${domain}profile/department/`,
    fetchTreeData: `${domain}profile/org_tree/`,
    fetchMembersTableList: `${domain}profile/member/`,
  },
  manageBudget: {
    addBudget: `${domain}accounts/budget/assign/`, //assign budget
    getMyBudget: `${domain}accounts/budget/user/`, //FOR FETCH MY individual BUDGET in req approval page
    fetchAllocatedIndividualBudgets: `${domain}accounts/budget/member/`, //FOR ALREADY ALLOCATED MEMBERS FETCH
    fetchAllocatedTeamBudgets: `${domain}accounts/budget/team/`, //FOR ALREADY ALLOCATED TEAMS FETCH

    fetchMyTeamBudget: `${domain}budget/team_budget/`, //FOR FETCH MY TEAM BUDGET for myteam
    fetchMyBudget: `${domain}budget/my_budget/`, //FOR FETCH MY BUDGET in mybudget page
    fetchMyUtilizedBudget: `${domain}budget/my_utilized_budget/`, //FOR FETCH MY UTILIZED BUDGET for utilizatinon in mybudget
    fetchMyApprovalPendingBudget: `${domain}budget.myapproval_budget/` //FOR FETCHING MY APPROVAL PENDING BUDGET in mybudget page
  },
  //FOR NEW OPTIMIZATION MYCOURSES API CALLS
  userCoursesTrack: {
    internalCourses: `${domain}accounts/usercourse/internal/`,
    externalCourses: `${domain}accounts/usercourse/external/`,
    tranings: `${domain}docs/fetch/`,

    //FOR EXTERNAL ASSESSMENTS
    fetchExternalAssessments: `${domain}accounts/external/assessments/`,
    fetchExternalAssignments: `${domain}accounts/external/assignments/`

  },
  //FOR NEW OPTIMIZATION MYLEARNINGPATH API CALLS
  userMyPathTrack: {
    visitedCourses: `${domain}accounts/usercourse/explore/`, //visited tab data
    approvalPendingCourses: `${domain}accounts/usercourse/explore/`, //approval tab data
    rejectedCourses: `${domain}accounts/usercourse/explore/`, //rejected tab data
    recToMeTrack: `${domain}accounts/recommended/view/`, //FOR FETHICNG REC TO ME RECOMMENDATIONS
    recByMeTrack: `${domain}accounts/recommended/view/`, //rec by me
  },
  //FOR VERIFY APPROVALS API CALLS
  verifyApprovals: {
    completedCourses: `${domain}accounts/verify/view/`, //FOR FETHCING TAB DATA
    verificationStatus: `${domain}accounts/verify/`, // FOR MARK IT VERIFY BUTTON CALLS
    verificationCounts: `${domain}accounts/verify/`, // FOR FETHCING TAB COUNTS
    reimburseStatus: `${domain}accounts/reimburse/`, //FOR REIMBURSEMENT VERIFICATION
    downloadZipFile: `${domain}accounts/downloadzip/`
  },
  //FOR ENTIRE NOTIFICATION MODULE
  notifications: {
    fetchNotificationCount: `${domain}accounts/notifications_count/`,
    fetchNotificationList: `${domain}accounts/notifications_list/`
  },
  //FOR ENTIRE USER SETTINGS APPCONFIG
  appSettings: {
    fetchColumnNames: `${domain}profile/onboarding/headers/`, //FOR FETCHING THE COLUMNS
    fetchOnboardedUsers: `${domain}profile/onboarding/users/`, //FOR FETCHING THE ALREADY ONBOARDED EMPLOYEES
    uploadUsersFile: `${domain}profile/onboarding/users/`, //FOR UPLOADING XLSX FILE
    verifyEmployees: `${domain}profile/onboarding/verify/`, //FOR VERIFYING THE XLSX FILE USERS
    verifyErrorEntries: `${domain}profile/onboarding/verify/`, //FOR VERIFYING THE ERROR AND WARNING ENTRIES single
    proceedToOnboard: `${domain}profile/onboarding/save/`, //FOR ONBOADRING THE CORRECT ENTRIES
    updateUser: `${domain}profile/onboarding/users/`, //FOR UPDATING THE USER INFO FROM ONBOARDED USERS
    deleteUser: `${domain}profile/onboarding/users/`, //FOR DELETING THE ONBOARDED USER
    makeUserActive: `${domain}profile/onboarding/users/`, //FOR MAKING USER STATUS TO ACTIVE
    proceedAsNewTeam: `${domain}profile/onboarding/newteam/`, //FOR CREATING NEW TEAM AND ADD USERS
    deleteNewDeptFunCard: `${domain}profile/onboarding/delteam/`, //FOR DELETING THE NEW DEPT OR FUN CARD
    fetchFunctionDomains: `${domain}profile/functionlist/` //FOR FETCHING THE FUNCTION DOMAIN LIST
  },
  appSettingsClientManagement: {
    fetchColumnNames: `${domain}clientmanagement/onboarding/headers/`, //FOR FETCHING THE COLUMNS
    fetchOnboardedUsers: `${domain}clientmanagement/onboarding/users/`, //FOR FETCHING THE ALREADY ONBOARDED EMPLOYEES
    uploadUsersFile: `${domain}clientmanagement/onboarding/users/`, //FOR UPLOADING XLSX FILE
    verifyEmployees: `${domain}clientmanagement/onboarding/verify/`, //FOR VERIFYING THE XLSX FILE USERS
    verifyErrorEntries: `${domain}clientmanagement/onboarding/verify/`, //FOR VERIFYING THE ERROR AND WARNING ENTRIES single
    proceedToOnboard: `${domain}clientmanagement/onboarding/save/`, //FOR ONBOADRING THE CORRECT ENTRIES
    updateUser: `${domain}clientmanagement/onboarding/users/`, //FOR UPDATING THE USER INFO FROM ONBOARDED USERS
    deleteUser: `${domain}clientmanagement/onboarding/users/`, //FOR DELETING THE ONBOARDED USER
    makeUserActive: `${domain}clientmanagement/onboarding/users/`, //FOR MAKING USER STATUS TO ACTIVE
    proceedAsNewTeam: `${domain}clientmanagement/onboarding/newteam/`, //FOR CREATING NEW TEAM AND ADD USERS
    deleteNewDeptFunCard: `${domain}clientmanagement/onboarding/delteam/`, //FOR DELETING THE NEW DEPT OR FUN CARD
    fetchFunctionDomains: `${domain}clientmanagement/functionlist/` //FOR FETCHING THE FUNCTION DOMAIN LIST
  },
  //FOR myathina USER/DEPT PERMISSONS
  userPermissions: {
    fetchTenantAdmins: `${domain}profile/adminprivileges/`, //FOR FETCHING THE ALL THE ADMINS DATA
    makeUsersAsAdmins: `${domain}profile/adminprivileges/`, //FOR MAKING THE USERS AS ADMINS
    removeAdminAccess: `${domain}profile/adminprivileges/`, //FOR REMOVING THE ADMIN ACCESS TO USER
    fetchLeftMenus: `${domain}profile/leftpanel_menus/`, //FOR FETCHING THE ALL LEFT MENUS
    assignMenusToDepts: `${domain}profile/department_menus/`, //FOR ASSIGNING THE MENUS TO DEPARTMENTS
    fetchSingleDeptMenus: `${domain}profile/department_menus/`, //FOR FETHICNG THE SINGLE DEPAMENT ACTIVE MENUS
    fetchSingleUserMenus: `${domain}profile/fetch_user_menus/`, //FOR FETCHING THE SINGLE USER ACTIVE MENUS
    fetchSingleMenuUsers: `${domain}profile/menu_users/`, //FOR FETCHING THE SINGLE MENU USERS BASED ON MENU ID
    onSaveSingleMenuUsers: `${domain}profile/menu_users/`, //FOR SAVING THE SELECTED USERS FOR SINGLE MENU
  },
  userSettings: {
    fetchUserSettings: `${domain}users/settings`,
    updateUserSettings: `${domain}accounts/update_settings`,
    fetchAllStores: `${domain}users/stores`,
  },
  skillIndex: {
    fetchTenantSkillIndex: `${domain}tenant/skillindex`,
    fetchTopTenSkillsIndex: `${domain}tenant/toptenskillsindex`,
  },
  myEvents: {
    fetchAssignedEvents: `${domain}live_events/offline_event/`,
    fetchOnlineAssignedEvents: `${domain}live_events/list_event/new/`,
    confirmBooking: `${domain}live_events/confirm_booking/`,
    fetchScheduledEvents: `${domain}live_events/offline_event/`,
    //fetchOnlineScheduledEvents: `${domain}live_events/list_event/`,//for fetching the scheduled online events
    fetchOnlineScheduledEvents: `${domain}live_events/schedule_events/`,//for fetching the scheduled online events
    fetchPastEvents: `${domain}live_events/past_event/`, //for fetching the past events
    fetchZoomHolders: `${domain}accounts/profile/zoom_holders/`,
    updateZoomKey: `${domain}accounts/profile/updatekeys/`,
    fetchZoomKey: `${domain}accounts/auth/profile/`,
    createEvent: `${domain}live_events/live_event/`,
    createEventNew: `${domain}live_events/live_event/new/`,
    enrollEvent: `${domain}live_events/enrollEvent/`,
    verifyXlsxZoomAttendies: `${domain}live_events/assign_user/`, //FOR VERIFYING THE XLSX UPLOAD ATTENDIES
    fetchEventEmpInfo: `${domain}live_events/emp_info/`, //FOR GETTING ACCEPTED INVITED USERS INFO OF A MEETING
    exportCSVEvntEmpInfo: `${domain}live_events/emp_info/`, //FOR DOWLOADING THE CSV OF EMP INFO
    fetchEvntPariticipants: `${domain}live_events/participants/`, //FOR FETCHING THE PARTICIPATED USERS INFO OF A MEETING
    deleteEvent: `${domain}live_events/live_event/new/`, //FOR DELETING THE EVENT
    updateEvent: `${domain}live_events/live_event/new/` //FOR UPDATING THE EVENT
  },
  //FOR EXTERNAL Assessments
  externalAssessments: {
    fetchAssignmentsList: `${domain}external/assignments/listing/`, //FOR FETCHING THE ASSIGNMENTS LIST
  },
  certification: {
    getTemplateThumbnails: `${domain}live_events/template_info/`, //FOR GETTING THE TEMPLATE THUMBNAILS LIST
    generateCertificate: `${domain}live_events/generate/certificates/`, //FOR GENERATING THE CERTIFICATE TO EMPLOYEES
    fetchMyCertificates: `${domain}live_events/user/certificates/`, //FOR FETCHING MY CERTIFICATES
    downloadCertificate: `${domain}live_events/mycertificates/` //FOR DOWNLOADING SINGLE CERTIFICATE
  },
  clientsDashboard: {
    fetchClientsReport: `${domain}clientmanagement/report/`,
    addClient: `${domain}clientmanagement/client/`,
    fetchClientDetail: `${domain}clientmanagement/client/`,
    fetchClientGroups: `${domain}clientmanagement/clientgroup/`,
    addGroup: `${domain}clientmanagement/clientgroup/`,
    updateGroup: `${domain}clientmanagement/clientgroup/`,
    fetchGroupLearners: `${domain}clientmanagement/learner/`,
    addLearner: `${domain}clientmanagement/learner/`,
    removeLearner: `${domain}clientmanagement/learner/`,
    fetchAllCoupons: `${domain}clientmanagement/coupons/`,
    addCoupon: `${domain}clientmanagement/coupons/`,
    fetchCouponInfo: `${domain}clientmanagement/coupons/`,
    uploadXapiCourse: `${domain}hosting/xapicourse/`,
    uploadScormCourse: `${domain}hosting/scormcourse/`,
    fetchCouponCourses: `${domain}clientmanagement/coupons/course/`,
    removeCoursesFromCoupon: `${domain}clientmanagement/coupons/course/`
  }
}
