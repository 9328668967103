const initState = {
  state: false,
  message: '',
  type: null
}
export default (state = initState, action) => {
  switch (action.type) {
    case 'SHOW_SNACK_BAR':
      return {
        state: true,
        message: action.payload.message,
        type: action.payload.type
      }
    case 'CLOSE_SNACK_BAR':
    default:
      return initState
  }
}
