import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { mockInterests } from '../Profile/mockSkills';

export const fetchProfile = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.profile.fetch}`
  axios.get(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const updateProfile = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.profile.update}${payLoad.user_id}${'/'}`
  axios.put(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchSkills = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.profile.fetchSkills}`
  axios.get(url)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchInterests = () => {
  return mockInterests
}

export const changePassword = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.profile.changePassword}`
  axios.post(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error.response)
  })
}

export const updateProfilePic = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.updateProfilePic}`
  axios.post(url,payload)
  .then(function (response){
    successCallBack(response.data)
  })
  .catch(function (error){
    failureCallBack(error.response)
  })
}

export const fetchProfilePic = (payLoad, successCallBack, failureCallBack) => dispatch => {
  const url =`${apiConfig.profile.fetchProfilePic}`
  axios.get(url)
  .then(function (response) {
    dispatch( { type: 'FETCH_USER_PROFILE', payload: response })
    successCallBack(response.data)
  })
  .catch(function (error) {
    dispatch( { type: 'REMOVE_USER_PROFILE', payload: [] })
    failureCallBack(error)
  })
}

export const removeProfilePic = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.profile.removeProfilePic}`
  axios.delete(url,formData)
  .then(function (response){
    successCallBack(response.data)
  })
  .catch(function (error){
    failureCallBack(error.response)
  })
}
