import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CurrencyInput from '../MyLearningPath/numberTextField';
import { getTenant } from '../../Configs/tenantConfig';
import { fetchUserSettings, updateUserSettings, fetchAllStores } from '../../store/UserSettings/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { generateJwtToken, getDomainInfo, getUSerDetails } from '../../Helpers/basic';
// import GoogleMap from '../Common/GoogleMap';

const currencies = [

  {
    name: 'USD',
    value: '$'
  },
  /*
  {
    value: '€'
  },
  */
  {
    name: 'INR',
    value: '₹'
  },
]
let languages = [
  {
    value: 'English'
  },
]
function UserSettingsPopup(props) {
  const defaultCurrency = getUSerDetails().user_currency_type || { name: 'INR', value: '₹' };
  const [currency, setCurrency] = useState({ name: 'INR', value: '₹' });
  const [language, setLanguage] = useState('English');
  const [userSettings, setUserSettings] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const [allStores, setAllStores] = useState([])
  const [fetchAllStoresInProgress, setFetchAllStoresInProgress] = useState(false)
  const [validationError, setValidationError] = useState({
    error: false,
    errorMessage: ''
  })
  const tenant = getTenant()
  if (tenant.tenant !== 'freo') {
    languages.push({
      value: 'Spanish'
    })
  }

  const handleChangeCurrency = evnt => {
    let currInfo = find(currencies, { name: evnt.target.value })
    setCurrency(currInfo)
  }
  const handleChangeLanguage = evnt => {
    setLanguage(evnt.target.value)
  }
  const handleClose = () => {
    props.closeUserSettingsPopup();
  };
  const fetchUserSettingsSuccessCallBack = res => {
    const defaultCurrency = getUSerDetails().user_currency_type || { name: 'INR', value: '₹' };
    setCurrency(defaultCurrency)
    setLanguage(res.language)
    setInProgress(false)
  }
  const fetchUserSettingsFailureCallBack = err => {
    props.showSnackBar(
      {
        state: true,
        message: 'Something went wrong while fetching user settings.',
        type: 'error'
      }
    )
    setInProgress(false)
    setCurrency({
      name: 'INR',
      value: '₹'
    })
    setLanguage('English')

  }
  const fetchAllStoresSuccessCallBack = res => {
    setAllStores(res.Store_Lookup)
    setFetchAllStoresInProgress(false)
  }
  const fetchAllStoresFailureCallBack = err => {
    setFetchAllStoresInProgress(false)
    props.showSnackBar(
      {
        state: true,
        message: 'Something went wrong while fetching stores list.',
        type: 'error'
      }
    )
  }
  useEffect(() => {
    setInProgress(true)
    setFetchAllStoresInProgress(true)
    fetchUserSettings({}, fetchUserSettingsSuccessCallBack, fetchUserSettingsFailureCallBack)
    fetchAllStores({}, fetchAllStoresSuccessCallBack, fetchAllStoresFailureCallBack)
  }, [])
  const updateUserSettingsSuccessCallBack = res => {
    props.showSnackBar(
      {
        state: true,
        message: 'User settings updated successfully!',
        type: 'success'
      }
    )
    let oldUserDetails = getUSerDetails()
    oldUserDetails.user_currency_type = currency;
    const user_token = generateJwtToken(oldUserDetails);
    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);
    const domainDet = getDomainInfo();
    document.cookie = domainDet.domainUserLabel + "=" + user_token + ";path=/;expires=" + myDate + ";domain=" + domainDet.domainName;
    handleClose();
  }
  const updateUserSettingsFailureCallBack = err => {
    props.showSnackBar(
      {
        state: true,
        message: 'Something went wrong while updating user settings. Please try again later.',
        type: 'error'
      }
    )
    handleClose()
  }
  const editUserSettings = () => {
    setInProgress(true)
    updateUserSettings({
      currency: currency.name,
      language: language
    }, updateUserSettingsSuccessCallBack, updateUserSettingsFailureCallBack)
  }
  const enableGoogleSettings = false;
  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set your Language and Currency type here.
          </DialogContentText>
          {tenant.settings && tenant.settings.languageSettings ? (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <TextField
                select
                label="Language"
                value={language}
                onChange={handleChangeLanguage}
                style={{ marginRight: 20 }}
              >
                {languages.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : null}
          {tenant.settings && tenant.settings.currencySettings ? (
            <div>
              <TextField
                select
                label="Currency"
                value={currency.name}
                defaultValue={currency.name}
                onChange={handleChangeCurrency}
                style={{ marginRight: 20 }}
              >
                {currencies.map(option => (
                  <MenuItem key={option.value} value={option.name}>
                    {option.value} {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : null}
          {tenant.settings && tenant.settings.locationSettings ? (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div>
                <Autocomplete
                  id="homeStore"
                  options={allStores}
                  getOptionLabel={(option) => option.Store}
                  style={{ width: 300 }}
                  renderOption={(option, { selected }) => (
                    <div>
                      <Typography variant="body1" gutterBottom>
                        {option.Store}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {option.Address}<br />
                        {option.City}<br />
                        {option.State}<br />
                        {option.County}<br />
                        {option.Zip}<br />
                      </Typography>
                    </div>
                  )}
                  renderInput={(params) => <TextField {...params} label="Your Home Store" variant="outlined" />}
                />
              </div>
              {enableGoogleSettings ? (
                <div style={{ marginTop: 20, marginBottom: 20, width: 500, height: 500 }}>
                  <GoogleMap latitude={42.4885067} longitude={-96.0747934} />
                </div>
              ) : null}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={25} />
          ) : null
          }
          <Button onClick={handleClose} color="primary" disabled={inProgress}>
            Cancel
          </Button>
          <Button onClick={editUserSettings} color="primary" disabled={inProgress}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(UserSettingsPopup);
