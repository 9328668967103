export const allStores = {
    "Store_Lookup": [
        {
            "Store_ID": "50",
            "Store": "MOVILLE",
            "Address": "4 S 1ST ST",
            "City": "MOVILLE",
            "State": "IOWA",
            "Zip": "51039",
            "County": "WOODBURY",
            "Store_Latitude": "42.4885067",
            "Store_Longitude": "-96.0747934",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "DARLA NAGEL"
        },
        {
            "Store_ID": "55",
            "Store": "MARCUS #1",
            "Address": "4801 HWY 143",
            "City": "MARCUS",
            "State": "IOWA",
            "Zip": "51035",
            "County": "CHEROKEE",
            "Store_Latitude": "42.8224324",
            "Store_Longitude": "-95.799128",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "VICKIE RUDEN"
        },
        {
            "Store_ID": "63",
            "Store": "ROCK RAPIDS",
            "Address": "709 1ST AVE",
            "City": "ROCK RAPIDS",
            "State": "IOWA",
            "Zip": "51246",
            "County": "LYON",
            "Store_Latitude": "43.4321669",
            "Store_Longitude": "-96.175104",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "CASSANDRA HINRICHS"
        },
        {
            "Store_ID": "68",
            "Store": "ALTON",
            "Address": "1109 3RD AVE",
            "City": "ALTON",
            "State": "IOWA",
            "Zip": "51003",
            "County": "SIOUX",
            "Store_Latitude": "42.9889506",
            "Store_Longitude": "-96.0090739",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "PAM PLATHE"
        },
        {
            "Store_ID": "1006",
            "Store": "PIPESTONE #1",
            "Address": "711 8TH AVE SE",
            "City": "PIPESTONE",
            "State": "MINNESOTA",
            "Zip": "56164",
            "County": "PIPESTONE",
            "Store_Latitude": "43.9934568",
            "Store_Longitude": "-96.3069024",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "DEBBIE PANTEKOEK"
        },
        {
            "Store_ID": "1159",
            "Store": "CORRECTIONVILLE",
            "Address": "524 11TH ST",
            "City": "CORRECTIONVILLE",
            "State": "IOWA",
            "Zip": "51016",
            "County": "WOODBURY",
            "Store_Latitude": "42.4819367",
            "Store_Longitude": "-95.7830468",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "JILL HOWARD"
        },
        {
            "Store_ID": "1166",
            "Store": "HULL",
            "Address": "612 DIVISION ST",
            "City": "HULL",
            "State": "IOWA",
            "Zip": "51239",
            "County": "SIOUX",
            "Store_Latitude": "43.1854439",
            "Store_Longitude": "-96.137085",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "PAMELA BUREN"
        },
        {
            "Store_ID": "1167",
            "Store": "HOLSTEIN",
            "Address": "714 S MAIN ST",
            "City": "HOLSTEIN",
            "State": "IOWA",
            "Zip": "51025",
            "County": "IDA",
            "Store_Latitude": "42.4805835",
            "Store_Longitude": "-95.5443812",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "SARA CARSTENS"
        },
        {
            "Store_ID": "1265",
            "Store": "SIBLEY",
            "Address": "702 2ND AVE",
            "City": "SIBLEY",
            "State": "IOWA",
            "Zip": "51249",
            "County": "OSCEOLA",
            "Store_Latitude": "43.401453",
            "Store_Longitude": "-95.7511621",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "SARA DIRKS"
        },
        {
            "Store_ID": "1315",
            "Store": "ARNOLDS PARK",
            "Address": "357 HWY 71 S",
            "City": "ARNOLDS PARK",
            "State": "IOWA",
            "Zip": "51331",
            "County": "DICKINSON",
            "Store_Latitude": "43.3630907",
            "Store_Longitude": "-95.1389022",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "AMY STRAIT"
        },
        {
            "Store_ID": "1316",
            "Store": "MILFORD",
            "Address": "510 OKOBOJI AVE",
            "City": "MILFORD",
            "State": "IOWA",
            "Zip": "51351",
            "County": "DICKINSON",
            "Store_Latitude": "43.3200881",
            "Store_Longitude": "-95.1495533",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "ELIZABETH HENSON"
        },
        {
            "Store_ID": "1328",
            "Store": "ESTHERVILLE #1",
            "Address": "1332 N 9TH ST",
            "City": "ESTHERVILLE",
            "State": "IOWA",
            "Zip": "51334",
            "County": "EMMET",
            "Store_Latitude": "43.4134491",
            "Store_Longitude": "-94.8301464",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "FELICIA CARRILLO"
        },
        {
            "Store_ID": "1329",
            "Store": "ESTHERVILLE #2",
            "Address": "8 CENTRAL AVE",
            "City": "ESTHERVILLE",
            "State": "IOWA",
            "Zip": "51334",
            "County": "EMMET",
            "Store_Latitude": "43.4015871",
            "Store_Longitude": "-94.8436084",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "REBECCA BUCHHOLZ"
        },
        {
            "Store_ID": "1365",
            "Store": "PAULLINA #1",
            "Address": "315 E GRAND AVE",
            "City": "PAULLINA",
            "State": "IOWA",
            "Zip": "51046",
            "County": "O BRIEN",
            "Store_Latitude": "42.9830672",
            "Store_Longitude": "-95.6840036",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "NO MANAGER AT THIS TIME"
        },
        {
            "Store_ID": "1536",
            "Store": "GEORGE",
            "Address": "301 E MICHIGAN AVE",
            "City": "GEORGE",
            "State": "IOWA",
            "Zip": "51237",
            "County": "LYON",
            "Store_Latitude": "43.3433536",
            "Store_Longitude": "-95.9981077",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "ADRIELLE HECK"
        },
        {
            "Store_ID": "1563",
            "Store": "LENNOX",
            "Address": "416 N PINE ST",
            "City": "LENNOX",
            "State": "SOUTH DAKOTA",
            "Zip": "57039",
            "County": "LINCOLN",
            "Store_Latitude": "43.3592323",
            "Store_Longitude": "-96.8863508",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "LAURA THIESSE"
        },
        {
            "Store_ID": "1660",
            "Store": "JACKSON",
            "Address": "603 3RD ST",
            "City": "JACKSON",
            "State": "MINNESOTA",
            "Zip": "56143",
            "County": "JACKSON",
            "Store_Latitude": "43.6225501",
            "Store_Longitude": "-94.9893074",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "STEPHANIE BLANKE"
        },
        {
            "Store_ID": "1686",
            "Store": "WORTHINGTON #1",
            "Address": "1704 OXFORD ST",
            "City": "WORTHINGTON",
            "State": "MINNESOTA",
            "Zip": "56187",
            "County": "NOBLES",
            "Store_Latitude": "43.6307336",
            "Store_Longitude": "-95.5857636",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "ANN GROENEWOLD"
        },
        {
            "Store_ID": "1687",
            "Store": "FULDA",
            "Address": "301 HWY 59 N",
            "City": "FULDA",
            "State": "MINNESOTA",
            "Zip": "56131",
            "County": "MURRAY",
            "Store_Latitude": "43.8720519",
            "Store_Longitude": "-95.5929064",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "SARA KRUEGER"
        },
        {
            "Store_ID": "1694",
            "Store": "BROOKINGS #1",
            "Address": "620 8TH ST S",
            "City": "BROOKINGS",
            "State": "SOUTH DAKOTA",
            "Zip": "57006",
            "County": "BROOKINGS",
            "Store_Latitude": "44.2968508",
            "Store_Longitude": "-96.7944073",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "ELIZABETH HINCHLIFFE"
        },
        {
            "Store_ID": "1718",
            "Store": "MADISON",
            "Address": "400 S WASHINGTON AVE",
            "City": "MADISON",
            "State": "SOUTH DAKOTA",
            "Zip": "57042",
            "County": "LAKE",
            "Store_Latitude": "44.0019231",
            "Store_Longitude": "-97.1092745",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "ANDREA HINCHLIFFE"
        },
        {
            "Store_ID": "1739",
            "Store": "LUVERNE #2",
            "Address": "302 S KNISS AVE",
            "City": "LUVERNE",
            "State": "MINNESOTA",
            "Zip": "56156",
            "County": "ROCK",
            "Store_Latitude": "43.6512806",
            "Store_Longitude": "-96.2135146",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "MARGO WALGRAVE"
        },
        {
            "Store_ID": "1748",
            "Store": "MARSHALL #1",
            "Address": "100 E MAIN ST",
            "City": "MARSHALL",
            "State": "MINNESOTA",
            "Zip": "56258",
            "County": "LYON",
            "Store_Latitude": "44.4457309",
            "Store_Longitude": "-95.7876428",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "EVA SHAW"
        },
        {
            "Store_ID": "1772",
            "Store": "FAIRMONT #1",
            "Address": "202 E BLUE EARTH AVE",
            "City": "FAIRMONT",
            "State": "MINNESOTA",
            "Zip": "56031",
            "County": "MARTIN",
            "Store_Latitude": "43.6518694",
            "Store_Longitude": "-94.4605045",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "JESSE MITCHELL"
        },
        {
            "Store_ID": "1776",
            "Store": "WINDOM",
            "Address": "612 2ND AVE N",
            "City": "WINDOM",
            "State": "MINNESOTA",
            "Zip": "56101",
            "County": "COTTONWOOD",
            "Store_Latitude": "43.8627527",
            "Store_Longitude": "-95.1153551",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "JULIAN SAN MIGUEL"
        },
        {
            "Store_ID": "1849",
            "Store": "ST JAMES #1",
            "Address": "107 1ST AVE S",
            "City": "ST JAMES",
            "State": "MINNESOTA",
            "Zip": "56081",
            "County": "WATONWAN",
            "Store_Latitude": "43.9801423",
            "Store_Longitude": "-94.6342245",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "DEBRA SIEM"
        },
        {
            "Store_ID": "1966",
            "Store": "CANBY",
            "Address": "104 1ST ST W",
            "City": "CANBY",
            "State": "MINNESOTA",
            "Zip": "56220",
            "County": "YELLOW MEDICINE",
            "Store_Latitude": "44.7086531",
            "Store_Longitude": "-96.2770521",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "JACQUELYN MORTENSON"
        },
        {
            "Store_ID": "2166",
            "Store": "WORTHINGTON #2",
            "Address": "1007 OXFORD ST",
            "City": "WORTHINGTON",
            "State": "MINNESOTA",
            "Zip": "56187",
            "County": "NOBLES",
            "Store_Latitude": "43.6314913",
            "Store_Longitude": "-95.6025449",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "MARIA AGUIRRE"
        },
        {
            "Store_ID": "2174",
            "Store": "SIOUX FALLS #1",
            "Address": "1705 W BURNSIDE ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57104",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5538346",
            "Store_Longitude": "-96.7519573",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "JASON MUMMA"
        },
        {
            "Store_ID": "2184",
            "Store": "REDWOOD FALLS 1",
            "Address": "410 S MILL ST",
            "City": "REDWOOD FALLS",
            "State": "MINNESOTA",
            "Zip": "56283",
            "County": "REDWOOD",
            "Store_Latitude": "44.5380941",
            "Store_Longitude": "-95.119986",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "MELISSA JAHNKE"
        },
        {
            "Store_ID": "2186",
            "Store": "BRANDON",
            "Address": "101 W HOLLY BLVD",
            "City": "BRANDON",
            "State": "SOUTH DAKOTA",
            "Zip": "57005",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5940409",
            "Store_Longitude": "-96.5925335",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "SARRA AGNEW"
        },
        {
            "Store_ID": "2189",
            "Store": "CANTON #2",
            "Address": "509 W 5TH ST",
            "City": "CANTON",
            "State": "SOUTH DAKOTA",
            "Zip": "57013",
            "County": "LINCOLN",
            "Store_Latitude": "43.3006301",
            "Store_Longitude": "-96.5983432",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "NO MANAGER AT THIS TIME"
        },
        {
            "Store_ID": "2198",
            "Store": "BROOKINGS #2",
            "Address": "122 W 6TH ST",
            "City": "BROOKINGS",
            "State": "SOUTH DAKOTA",
            "Zip": "57006",
            "County": "BROOKINGS",
            "Store_Latitude": "44.3120536",
            "Store_Longitude": "-96.804652",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "KOLLIN VOLKERT"
        },
        {
            "Store_ID": "2260",
            "Store": "YANKTON #3",
            "Address": "801 BURLEIGH ST",
            "City": "YANKTON",
            "State": "SOUTH DAKOTA",
            "Zip": "57078",
            "County": "YANKTON",
            "Store_Latitude": "42.8765969",
            "Store_Longitude": "-97.3832005",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "NO MANAGER AT THIS TIME"
        },
        {
            "Store_ID": "2268",
            "Store": "YANKTON #4",
            "Address": "1000 BROADWAY AVE",
            "City": "YANKTON",
            "State": "SOUTH DAKOTA",
            "Zip": "57078",
            "County": "YANKTON",
            "Store_Latitude": "42.879192",
            "Store_Longitude": "-97.3963192",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "CHARLES BROWNE"
        },
        {
            "Store_ID": "2270",
            "Store": "AURELIA",
            "Address": "100 PEARL ST",
            "City": "AURELIA",
            "State": "IOWA",
            "Zip": "51005",
            "County": "CHEROKEE",
            "Store_Latitude": "42.7120957",
            "Store_Longitude": "-95.4373329",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "CINDY SCHWANZ"
        },
        {
            "Store_ID": "2334",
            "Store": "SIOUX FALLS #2",
            "Address": "701 S SYCAMORE AVE",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57110",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5409225",
            "Store_Longitude": "-96.6718599",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "JOHN GUERRA"
        },
        {
            "Store_ID": "2343",
            "Store": "SIOUX FALLS #3",
            "Address": "1809 E RICE ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57103",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5617498",
            "Store_Longitude": "-96.7030258",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "MARCIE BUYSMAN"
        },
        {
            "Store_ID": "2351",
            "Store": "SIOUX FALLS #4",
            "Address": "901 E BENSON RD",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57104",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5871772",
            "Store_Longitude": "-96.7158924",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "NO MANAGER AT THIS TIME"
        },
        {
            "Store_ID": "2419",
            "Store": "BROOKINGS #3",
            "Address": "534 22ND AVE S",
            "City": "BROOKINGS",
            "State": "SOUTH DAKOTA",
            "Zip": "57006",
            "County": "BROOKINGS",
            "Store_Latitude": "44.2996551",
            "Store_Longitude": "-96.7688579",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "TODD LUND"
        },
        {
            "Store_ID": "2431",
            "Store": "SIOUX CENTER",
            "Address": "8 7TH ST NE",
            "City": "SIOUX CENTER",
            "State": "IOWA",
            "Zip": "51250",
            "County": "SIOUX",
            "Store_Latitude": "43.0845808",
            "Store_Longitude": "-96.1754768",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "ASHELY SILBERSTEIN"
        },
        {
            "Store_ID": "2464",
            "Store": "SIOUX FALLS #5",
            "Address": "500 N KIWANIS AVE",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57104",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5528355",
            "Store_Longitude": "-96.7606262",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "JACQUELINE MONEN"
        },
        {
            "Store_ID": "2487",
            "Store": "SPIRIT LAKE #2",
            "Address": "1304 18TH ST",
            "City": "SPIRIT LAKE",
            "State": "IOWA",
            "Zip": "51360",
            "County": "DICKINSON",
            "Store_Latitude": "43.4230152",
            "Store_Longitude": "-95.1092142",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "CHERI BATTAZZI"
        },
        {
            "Store_ID": "2527",
            "Store": "SHELDON #3",
            "Address": "1401 PARK ST",
            "City": "SHELDON",
            "State": "IOWA",
            "Zip": "51201",
            "County": "O BRIEN",
            "Store_Latitude": "43.1863398",
            "Store_Longitude": "-95.8417872",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "NELLIE MCKEAN-HUGHES"
        },
        {
            "Store_ID": "2530",
            "Store": "ALTA",
            "Address": "303 E HWY 7",
            "City": "ALTA",
            "State": "IOWA",
            "Zip": "51002",
            "County": "BUENA VISTA",
            "Store_Latitude": "42.6755452",
            "Store_Longitude": "-95.3019658",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "BLANCA MARQUEZ"
        },
        {
            "Store_ID": "2586",
            "Store": "HARRISBURG",
            "Address": "401 WILLOW ST",
            "City": "HARRISBURG",
            "State": "SOUTH DAKOTA",
            "Zip": "57032",
            "County": "LINCOLN",
            "Store_Latitude": "43.4311289",
            "Store_Longitude": "-96.7082521",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "JAMES DAYTON"
        },
        {
            "Store_ID": "2622",
            "Store": "TRACY",
            "Address": "1205 MORGAN ST",
            "City": "TRACY",
            "State": "MINNESOTA",
            "Zip": "56175",
            "County": "LYON",
            "Store_Latitude": "44.2380903",
            "Store_Longitude": "-95.6326835",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "TABBITHA RIKKE"
        },
        {
            "Store_ID": "2657",
            "Store": "ELK POINT",
            "Address": "1000 W MAIN ST",
            "City": "ELK POINT",
            "State": "SOUTH DAKOTA",
            "Zip": "57025",
            "County": "UNION",
            "Store_Latitude": "42.6909183",
            "Store_Longitude": "-96.6958777",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "MEGAN RICE"
        },
        {
            "Store_ID": "2672",
            "Store": "SANBORN",
            "Address": "510 W 7TH ST",
            "City": "SANBORN",
            "State": "IOWA",
            "Zip": "51248",
            "County": "O BRIEN",
            "Store_Latitude": "43.1862942",
            "Store_Longitude": "-95.6621731",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "TRISHA ABBOTT"
        },
        {
            "Store_ID": "2681",
            "Store": "OKOBOJI",
            "Address": "1802 HWY 71 N",
            "City": "OKOBOJI",
            "State": "IOWA",
            "Zip": "51355",
            "County": "DICKINSON",
            "Store_Latitude": "43.3919485",
            "Store_Longitude": "-95.1263307",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "JASMINE ENNEN"
        },
        {
            "Store_ID": "2687",
            "Store": "HAWARDEN #2",
            "Address": "1001 AVE E",
            "City": "HAWARDEN",
            "State": "IOWA",
            "Zip": "51023",
            "County": "SIOUX",
            "Store_Latitude": "42.9971367",
            "Store_Longitude": "-96.4885148",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "CATHERINE MARCOTTE"
        },
        {
            "Store_ID": "2806",
            "Store": "VERMILLION #2",
            "Address": "615 JEFFERSON ST",
            "City": "VERMILLION",
            "State": "SOUTH DAKOTA",
            "Zip": "57069",
            "County": "CLAY",
            "Store_Latitude": "42.7861153",
            "Store_Longitude": "-96.914864",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "LOIS HALVERSON"
        },
        {
            "Store_ID": "2830",
            "Store": "BERESFORD #2",
            "Address": "910 W CEDAR ST",
            "City": "BERESFORD",
            "State": "SOUTH DAKOTA",
            "Zip": "57004",
            "County": "LINCOLN",
            "Store_Latitude": "43.0841007",
            "Store_Longitude": "-96.7856754",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "NANCY KRUSE"
        },
        {
            "Store_ID": "2996",
            "Store": "BUTTERFIELD",
            "Address": "101 PRAIRIE AVE",
            "City": "BUTTERFIELD",
            "State": "MINNESOTA",
            "Zip": "56120",
            "County": "WATONWAN",
            "Store_Latitude": "43.9529098",
            "Store_Longitude": "-94.7943832",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "KRISTINA REHNELT"
        },
        {
            "Store_ID": "2998",
            "Store": "PIPESTONE #2",
            "Address": "228 8TH AVE NE",
            "City": "PIPESTONE",
            "State": "MINNESOTA",
            "Zip": "56164",
            "County": "PIPESTONE",
            "Store_Latitude": "44.0020975",
            "Store_Longitude": "-96.3082757",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "NO MANAGER AT THIS TIME"
        },
        {
            "Store_ID": "2999",
            "Store": "SHERBURN",
            "Address": "502 N MAIN ST",
            "City": "SHERBURN",
            "State": "MINNESOTA",
            "Zip": "56171",
            "County": "MARTIN",
            "Store_Latitude": "43.6578936",
            "Store_Longitude": "-94.7281594",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "ANGEL BETTIN"
        },
        {
            "Store_ID": "3000",
            "Store": "ST JAMES #2",
            "Address": "620 2ND AVE S",
            "City": "ST JAMES",
            "State": "MINNESOTA",
            "Zip": "56081",
            "County": "WATONWAN",
            "Store_Latitude": "43.9811642",
            "Store_Longitude": "-94.6266982",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "CHRISTINE BASS"
        },
        {
            "Store_ID": "3002",
            "Store": "ST JAMES #3",
            "Address": "1102 HECKMAN CT",
            "City": "ST JAMES",
            "State": "MINNESOTA",
            "Zip": "56081",
            "County": "WATONWAN",
            "Store_Latitude": "43.9700639",
            "Store_Longitude": "-94.618563",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "DALE RUNGE"
        },
        {
            "Store_ID": "3003",
            "Store": "TRIMONT",
            "Address": "10 BROADWAY ST",
            "City": "TRIMONT",
            "State": "MINNESOTA",
            "Zip": "56176",
            "County": "MARTIN",
            "Store_Latitude": "43.7616817",
            "Store_Longitude": "-94.7144869",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "JENA OESTREICH"
        },
        {
            "Store_ID": "3004",
            "Store": "TRUMAN",
            "Address": "302 N 5TH AVE E",
            "City": "TRUMAN",
            "State": "MINNESOTA",
            "Zip": "56088",
            "County": "MARTIN",
            "Store_Latitude": "43.8302738",
            "Store_Longitude": "-94.4274167",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "DARLA WIEDERHOEFT"
        },
        {
            "Store_ID": "3005",
            "Store": "WELCOME",
            "Address": "112 2ND ST",
            "City": "WELCOME",
            "State": "MINNESOTA",
            "Zip": "56181",
            "County": "MARTIN",
            "Store_Latitude": "43.6683864",
            "Store_Longitude": "-94.6152438",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "MARILYN STELTER"
        },
        {
            "Store_ID": "3019",
            "Store": "SIOUX FALLS #6",
            "Address": "1901 E 10TH ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57103",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5462145",
            "Store_Longitude": "-96.7020106",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "STEPHANIE RAGLE"
        },
        {
            "Store_ID": "3034",
            "Store": "ESTHERVILLE #4",
            "Address": "1615 CENTRAL AVE",
            "City": "ESTHERVILLE",
            "State": "IOWA",
            "Zip": "51334",
            "County": "EMMET",
            "Store_Latitude": "43.4024159",
            "Store_Longitude": "-94.8220301",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "DEBORAH VARNER"
        },
        {
            "Store_ID": "3037",
            "Store": "CHEROKEE #2",
            "Address": "1200 N 2ND ST",
            "City": "CHEROKEE",
            "State": "IOWA",
            "Zip": "51012",
            "County": "CHEROKEE",
            "Store_Latitude": "42.7644819",
            "Store_Longitude": "-95.5518122",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "JULIA WAPLES"
        },
        {
            "Store_ID": "3044",
            "Store": "SHELDON #5",
            "Address": "504 2ND AVE",
            "City": "SHELDON",
            "State": "IOWA",
            "Zip": "51201",
            "County": "O BRIEN",
            "Store_Latitude": "43.1855163",
            "Store_Longitude": "-95.8548791",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "GAYLA CLARK"
        },
        {
            "Store_ID": "3049",
            "Store": "SIOUX FALLS #7",
            "Address": "3236 S SYCAMORE AVE",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57110",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.5154013",
            "Store_Longitude": "-96.6707629",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "WILLIAM MITCHELL"
        },
        {
            "Store_ID": "3069",
            "Store": "TEA",
            "Address": "800 E BRIAN ST",
            "City": "TEA",
            "State": "SOUTH DAKOTA",
            "Zip": "57064",
            "County": "LINCOLN",
            "Store_Latitude": "43.4541959",
            "Store_Longitude": "-96.8256537",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "GRACE DAVIS"
        },
        {
            "Store_ID": "3263",
            "Store": "WORTHINGTON #3",
            "Address": "2021 HWY 59 N",
            "City": "WORTHINGTON",
            "State": "MINNESOTA",
            "Zip": "56187",
            "County": "NOBLES",
            "Store_Latitude": "43.6394279",
            "Store_Longitude": "-95.5941643",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "JESSE SCHROEDER"
        },
        {
            "Store_ID": "3277",
            "Store": "REDWOOD FALLS 2",
            "Address": "707 E BRIDGE ST",
            "City": "REDWOOD FALLS",
            "State": "MINNESOTA",
            "Zip": "56283",
            "County": "REDWOOD",
            "Store_Latitude": "44.5415877",
            "Store_Longitude": "-95.1092973",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "MANDY RATHMAN"
        },
        {
            "Store_ID": "3285",
            "Store": "DELL RAPIDS",
            "Address": "309 W 4TH ST",
            "City": "DELL RAPIDS",
            "State": "SOUTH DAKOTA",
            "Zip": "57022",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.8231311",
            "Store_Longitude": "-96.7220641",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "KERRI JONES"
        },
        {
            "Store_ID": "3290",
            "Store": "MADELIA",
            "Address": "604 E MAIN ST",
            "City": "MADELIA",
            "State": "MINNESOTA",
            "Zip": "56062",
            "County": "WATONWAN",
            "Store_Latitude": "44.0503102",
            "Store_Longitude": "-94.4049264",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "ANGIE DICK",
            "Store Manager": "TINA STEVENSEN"
        },
        {
            "Store_ID": "3385",
            "Store": "ORANGE CITY #2",
            "Address": "704 8TH ST SE",
            "City": "ORANGE CITY",
            "State": "IOWA",
            "Zip": "51041",
            "County": "SIOUX",
            "Store_Latitude": "42.9970335",
            "Store_Longitude": "-96.0500567",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "HEIDI MARCYES",
            "Store Manager": "KAREN REEKERS"
        },
        {
            "Store_ID": "3442",
            "Store": "MAPLETON #2",
            "Address": "401 S 4TH ST",
            "City": "MAPLETON",
            "State": "IOWA",
            "Zip": "51034",
            "County": "MONONA",
            "Store_Latitude": "42.1617909",
            "Store_Longitude": "-95.7936617",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "SARAH DAVIS"
        },
        {
            "Store_ID": "3475",
            "Store": "MARSHALL #2",
            "Address": "1109 E COLLEGE DR",
            "City": "MARSHALL",
            "State": "MINNESOTA",
            "Zip": "56258",
            "County": "LYON",
            "Store_Latitude": "44.4508525",
            "Store_Longitude": "-95.7748902",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "KAYLA WITTROCK"
        },
        {
            "Store_ID": "3525",
            "Store": "VERMILLION #3",
            "Address": "1302 PRINCETON ST",
            "City": "VERMILLION",
            "State": "SOUTH DAKOTA",
            "Zip": "57069",
            "County": "CLAY",
            "Store_Latitude": "42.7927001",
            "Store_Longitude": "-96.9401733",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "KATIE ULVEN"
        },
        {
            "Store_ID": "3589",
            "Store": "N SIOUX CITY #2",
            "Address": "100 S DERBY LN",
            "City": "NORTH SIOUX CITY",
            "State": "SOUTH DAKOTA",
            "Zip": "57049",
            "County": "UNION",
            "Store_Latitude": "42.5244443",
            "Store_Longitude": "-96.4967827",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "TRISHA RASMUSSEN"
        },
        {
            "Store_ID": "3677",
            "Store": "SLAYTON",
            "Address": "2017 MAPLE AVE",
            "City": "SLAYTON",
            "State": "MINNESOTA",
            "Zip": "56172",
            "County": "MURRAY",
            "Store_Latitude": "43.9926172",
            "Store_Longitude": "-95.7529176",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "MICHAEL TREVILLYAN"
        },
        {
            "Store_ID": "3722",
            "Store": "SIOUX FALLS #8",
            "Address": "8400 W 26TH ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57106",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.529689",
            "Store_Longitude": "-96.830262",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "BRYCE ZAWATZKE"
        },
        {
            "Store_ID": "3730",
            "Store": "PAULLINA #2",
            "Address": "222 N CLARK ST",
            "City": "PAULLINA",
            "State": "IOWA",
            "Zip": "51046",
            "County": "O BRIEN",
            "Store_Latitude": "42.982344",
            "Store_Longitude": "-95.684428",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "STACY WELCH",
            "Store Manager": "TRACY FOX"
        },
        {
            "Store_ID": "3752",
            "Store": "MOUNTAIN LAKE 2",
            "Address": "400 COTTONWOOD RD",
            "City": "MOUNTAIN LAKE",
            "State": "MINNESOTA",
            "Zip": "56159",
            "County": "COTTONWOOD",
            "Store_Latitude": "43.932046",
            "Store_Longitude": "-94.939021",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "KELLY JACOB",
            "Store Manager": "LEAH HEFFELE"
        },
        {
            "Store_ID": "3757",
            "Store": "IDA GROVE",
            "Address": "401 E HWY 175",
            "City": "IDA GROVE",
            "State": "IOWA",
            "Zip": "51445",
            "County": "IDA",
            "Store_Latitude": "42.349607",
            "Store_Longitude": "-95.46689",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "BARBARA STANKEY"
        },
        {
            "Store_ID": "3810",
            "Store": "VOLGA #2",
            "Address": "422 E HIGHWAY 14",
            "City": "VOLGA",
            "State": "SOUTH DAKOTA",
            "Zip": "57071",
            "County": "BROOKINGS",
            "Store_Latitude": "44.326348",
            "Store_Longitude": "-96.921545",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "LEEANN HOKENSON",
            "Store Manager": "KRYSTALYN LAABS"
        },
        {
            "Store_ID": "3855",
            "Store": "YANKTON #5",
            "Address": "2300 BROADWAY AVE",
            "City": "YANKTON",
            "State": "SOUTH DAKOTA",
            "Zip": "57078",
            "County": "YANKTON",
            "Store_Latitude": "42.894911",
            "Store_Longitude": "-97.396872",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "JEFF VAN VELZEN",
            "Store Manager": "JODELLE FERGUSON"
        },
        {
            "Store_ID": "3872",
            "Store": "SIOUX FALLS #9",
            "Address": "4001 W 57TH ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57108",
            "County": "LINCOLN",
            "Store_Latitude": "43.4999923",
            "Store_Longitude": "-96.775992",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "LORI BETTELYOUN"
        },
        {
            "Store_ID": "3878",
            "Store": "SIOUX FALLS #10",
            "Address": "5810 E 26TH ST",
            "City": "SIOUX FALLS",
            "State": "SOUTH DAKOTA",
            "Zip": "57110",
            "County": "MINNEHAHA",
            "Store_Latitude": "43.529902",
            "Store_Longitude": "-96.656258",
            "Store_Type": "C",
            "Store_Status": "A",
            "Division Director": "DAVE JOHNSON",
            "Region Director": "ADAM NEEMEYER",
            "District Supervisor": "TAMI JOHNSON",
            "Store Manager": "JOSHUA NAUSS"
        }
    ]
}
