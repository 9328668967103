import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Footer from '../Footer';
import { authenticatedMenus } from './menuList';
import { getRecommendedCourseCount } from '../../store/MyCourses/actionCreator';
import { logUserActivity, redirectTo, getDomainInfo, hasSearchAccess } from '../../Helpers/basic';
import { googleTrackView } from '../../Helpers/googleAnalytics';
import { getTenant } from '../../Configs/tenantConfig';
import { colorStyles } from '../../styles/style';
import AutomatedMenuListing from './automatedMenus';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: colorStyles.leftMenuBg,
    width: '97%',
    height: '100%',
    position: 'relative'
  },
  selectedMenu: {
    backgroundColor: colorStyles.leftMenuActive,
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: colorStyles.leftMenuHover,
    }
  },
  icon: {
    color: '#2e8eec',
    fontSize: '1.7rem'
  },
  badge: {
    backgroundColor: '#2e8eec',
    marginTop: '10px',
    marginLeft: '11px'
  },
  scrollWrapper: {
    // height: 480,
    //backgroundColor: colorStyles.leftMenuBg,
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'scroll',
    },
    marginTop: '-26px',
  },
  menu: {
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: colorStyles.leftMenuHover,
    },
  },
  accordionHeader: {
    color: '#2e8eec',
    textAlign: 'initial',
    fontWeight: 500,
    fontSize: '15px'
  },
  accordionWrapper: {
    backgroundColor: colorStyles.leftMenuBg,
  }
}));

let cancelTokenSource = axios.CancelToken.source();

function LeftMenu(props) {
  const classes = useStyles();
  const tenant = getTenant()
  const [recommendedCount, setRecommendedCount] = useState(0);

  //FOR RECOMMENDATION COUNT BADGE CALLBACKS
  const successCB = (res) => {
    setRecommendedCount(res.data.count)
  }
  const failureCB = (err) => {
    setRecommendedCount(0)
  }

  const getRecommendedCourseDet = () => {
    getRecommendedCourseCount(successCB, failureCB)
  }

  useEffect(() => {
    getRecommendedCourseDet();
  }, [])

  const onMenuClick = menu => {
    let menuName = ''
    //FOR CHECKING THE MENU NAME
    if (getTenant().showAutomatedActiveMenus) {
      menuName = menu.name
    } else {
      menuName = menu.menu
    }

    if (menuName === "Author Courses") {
      const url = getDomainInfo().hostingURL
      redirectTo(url, true)
    } else {
      const menuTrackingName = menuName.toLowerCase().replace(' ', '-')
      logUserActivity(
        {
          action: menuName ? menuName : 'left-menu-click',
          url: window.location.href,
          object_name: '',
          element_id: menu.id,
          element_name: menuTrackingName,
          element_class: "sidebar-item-click"
        }
      )
      googleTrackView({
        pageTitle: menuTrackingName
      })
      //------- Cancel Request Handling -------
      cancelTokenSource.cancel('User navigated to different page')
      axios.interceptors.request.use(
        config => {
          config.cancelToken = cancelTokenSource.token
          return config;
        },
        error => Promise.reject(error)
      );
      axios.interceptors.response.use(function (response) {
        // throw new axios.Cancel('Operation canceled by the user.');
        return response;
      }, function (error) {
        if (axios.isCancel(error)) {
          // here you check if this is a cancelled request to drop it silently (without error)
          return new Promise(() => { });
        }
        return Promise.reject(error);
      });
      props.history.push(menu.path)
      cancelTokenSource = axios.CancelToken.source();
    }
  }

  return (
    <div className={classes.root}>
      {tenant.showAutomatedActiveMenus ? (
        <AutomatedMenuListing
          authenticatedMenus={authenticatedMenus}
          selectedMenu={props.selectedMenu}
          onMenuClick={onMenuClick}
          recommendedCount={recommendedCount}
        />
      ) : (
        <React.Fragment>
          {(hasSearchAccess() || getDomainInfo().client === 'caseys') &&
            <Accordion defaultExpanded className={classes.accordionWrapper}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#2e8eec' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordionHeader}>My Organization</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List id="leftMenu" style={{ marginTop: '-26px' }}>
                  {authenticatedMenus().map((data, index) => (
                    <React.Fragment key={index} >
                      {data.enableView && data.category === 'My Organization' ?
                        <React.Fragment>
                          <ListItem button onClick={() => { onMenuClick(data) }} className={props.selectedMenu === data.menu ? classes.selectedMenu : classes.menu}>
                            <ListItemIcon style={{ color: '#2e8eec', minWidth: 30 }}>
                              {data.icon}
                            </ListItemIcon>
                            <ListItemText primary={data.menu} />
                          </ListItem>
                        </React.Fragment>
                        : null}
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>}
          <Accordion defaultExpanded style={{ marginBottom: '50px', backgroundColor: colorStyles.leftMenuBg }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#2e8eec' }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.accordionHeader}>Me</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.scrollWrapper} id="leftMenu">
                {authenticatedMenus().map((data, index) => (
                  <React.Fragment key={index} >
                    {data.enableView && data.category === 'Me' ?
                      <React.Fragment>
                        <ListItem button onClick={() => { onMenuClick(data) }} className={props.selectedMenu === data.menu ? classes.selectedMenu : classes.menu}>
                          <ListItemIcon style={{ color: '#2e8eec', minWidth: 30 }}>
                            {data.icon}
                          </ListItemIcon>
                          {data.menu === "My Learning Path" ?
                            <ListItemText primary={
                              <div style={{ display: 'flex' }}>
                                <div>{data.menu}</div>
                                {recommendedCount > 0 && (
                                  <Badge max={9} badgeContent={recommendedCount} className={classes.badge} color="primary" aria-label={recommendedCount ? `new recommendations count ${recommendedCount}` : null} />
                                )}
                              </div>} />
                            : <ListItemText primary={data.menu} />}
                        </ListItem>
                      </React.Fragment>
                      : null}
                  </React.Fragment>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      )}
      <Footer />
    </div>
  )
}
const mapStateToProps = state => {
  return {
    authDetails: state.authReducer.authDetails,
  }
}
export default connect(
  mapStateToProps, {}
)(LeftMenu);
