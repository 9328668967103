export const mockSkills = [
    {
        id: 1,
        value: 'WordPress',
        title: 'WordPress'
    },
    {
        id: 2,
        value: 'C#',
        title: 'C#'
    },
    {
        id: 3,
        value: 'Android Development',
        title: 'Android Development'
    },
    {
        id: 4,
        value: 'Web Development',
        title: 'Web Development'
    },
    {
        id: 5,
        value: 'Machine Learning',
        title: 'Machine Learning'
    },
    {
        id: 6,
        value: 'Swift',
        title: 'Swift'
    },
    {
        id: 7,
        value: 'HTML',
        title: 'HTML'
    },
    {
        id: 8,
        value: 'CSS',
        title: 'CSS'
    },
    {
        id: 9,
        value: 'ReactJs',
        title: 'ReactJs'
    },
    {
        id: 10,
        value: 'Angular',
        title: 'Angular'
    },    
    {
        id: 11,
        value: 'SQL',
        title: 'SQL'
    },
    {
        id: 12,
        value: 'Node.Js',
        title: 'Node.Js'
    },
    {
        id: 13,
        value: 'Bootstrap',
        title: 'Bootstrap'
    },
    {
        id: 14,
        value: 'Data Science',
        title: 'Data Science'
    },
    {
        id: 15,
        value: 'R',
        title: 'R'
    },
    {
        id: 16,
        value: 'HTML5',
        title: 'HTML5'
    },
    {
        id: 17,
        value: 'Game Development Fundamentals',
        title: 'Game Development Fundamentals'
    },
    {
        id: 18,
        value: 'Laravel',
        title: 'Laravel'
    },
    {
        id: 19,
        value: 'MySQL',
        title: 'MySQL'
    },
    {
        id: 20,
        value: 'Product Management',
        title: 'Product Management'
    },
    {
        id: 21,
        value: 'Project Management',
        title: 'Project Management'
    },
    {
        id: 22,
        value: 'Program Management',
        title: 'Program Management'
    },
    {
        id: 23,
        value: 'Business Analyst',
        title: 'Business Analyst'
    },
    {
        id: 24,
        value: 'Data Analyst',
        title: 'Data Analyst'
    },
    {
        id: 25,
        value: 'Data Scientist',
        title: 'Data Scientist'
    },
    {
        id: 26,
        value: 'Automation Testing',
        title: 'Automation Testing'
    },
    {
        id: 27,
        value: 'Manual Testing',
        title: 'Manual Testing'
    },
    {
        id: 28,
        value: 'Agile Methodologies',
        title: 'Agile Methodologies'
    },
    {
        id: 29,
        value: 'Scrum',
        title: 'Scrum'
    },
    {
        id: 30,
        value: 'Kanban',
        title: 'Kanban'
    }, 
    {
        id: 31,
        value: 'Python',
        title: 'Python'
    },
    {
        id: 32,
        value: 'Java',
        title: 'Java'
    },
    {
        id: 33,
        value: 'JavaScript',
        title: 'JavaScript'
    },
]

export const mockInterests = [
    {
        id: 1,
        value: 'Web Development',
        title: 'Web Development'
    },
    {
        id: 2,
        value: 'Programming Languages',
        title: 'Programming Languages'
    },
    {
        id: 3,
        value: 'Mobile Apps',
        title: 'Mobile Apps'
    },
    {
        id: 4,
        value: 'Game Development',
        title: 'Game Development'
    },
    {
        id: 5,
        value: 'Data Science',
        title: 'Data Science'
    },
    {
        id: 6,
        value: 'Databases',
        title: 'Databases'
    },
    {
        id: 7,
        value: 'Development Tools',
        title: 'Development Tools'
    },
    {
        id: 8,
        value: 'Software Engineering',
        title: 'Software Engineering'
    },
    {
        id: 9,
        value: 'Software Testing',
        title: 'Software Testing'
    },
    {
        id: 10,
        value: 'E-Commerce',
        title: 'E-Commerce'
    }
]